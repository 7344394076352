import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTrendingRFQs,
  fetchNewRFQs,
  fetchSolidRFQs,
  fetchLiquidRFQs,
  fetchGasRFQs,
  fetchFCIRFQs,
  fetchFeturedRFQs,
  fetchNearByRFQs,
  deleteRFQ,
  fetchUserRFQs,
  postRFQ,
  updateRFQ,
  bookRFQ,
} from "../api/RFQs";

const initialState = {
  loading: false,
  userRFQs: [],
  nearbyRFQs: [],
  trendingRFQs: [],
  newRFQs: [],
  solidRFQs: [],
  liquidRFQs: [],
  gasRFQs: [],
  FCIRFQs: [],
  featuredRFQs: [],
};

export const RFQsSlice = createSlice({
  name: "RFQs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update RFQ
    builder
      .addCase(updateRFQ.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRFQ.fulfilled, (state, action) => {
        state.loading = false;
        const RFQ = action.payload;
        if (!RFQ) return;
        const isAvailable = state.userRFQs.find((rfq) => rfq.id === RFQ.id);
        if (isAvailable) {
          const rest = state.userRFQs.filter((rfq) => rfq.id !== RFQ.id);
          state.userRFQs = [...rest, RFQ];
        } else {
          state.userRFQs = [...state.userRFQs, RFQ];
        }
      })
      .addCase(updateRFQ.rejected, (state) => {
        state.loading = false;
      });

    // Delete RFQ
    builder
      .addCase(deleteRFQ.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRFQ.fulfilled, (state, action) => {
        const RFQId = action.payload;
        const rest = state.userRFQs.filter((rfq) => rfq.id !== RFQId);
        state.userRFQs = [...rest];
        state.loading = false;
      })
      .addCase(deleteRFQ.rejected, (state) => {
        state.loading = false;
      });

    // Post RFQ
    builder
      .addCase(postRFQ.pending, (state) => {
        state.loading = true;
      })
      .addCase(postRFQ.fulfilled, (state, action) => {
        state.userRFQs = [...state.userRFQs, action.payload];
        state.loading = false;
      })
      .addCase(postRFQ.rejected, (state) => {
        state.loading = false;
      });

    // Fetch User RFQs
    builder
      .addCase(fetchUserRFQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserRFQs.fulfilled, (state, action) => {
        state.userRFQs = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchUserRFQs.rejected, (state) => {
        state.loading = false;
      });

    // Fetch Trending RFQs
    builder
      .addCase(fetchTrendingRFQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrendingRFQs.fulfilled, (state, action) => {
        state.trendingRFQs = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchTrendingRFQs.rejected, (state) => {
        state.loading = false;
      });

    // Fetch New RFQs
    builder
      .addCase(fetchNewRFQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewRFQs.fulfilled, (state, action) => {
        state.newRFQs = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchNewRFQs.rejected, (state) => {
        state.loading = false;
      });

    // Fetch Solid RFQs
    builder
      .addCase(fetchSolidRFQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSolidRFQs.fulfilled, (state, action) => {
        state.solidRFQs = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchSolidRFQs.rejected, (state) => {
        state.loading = false;
      });

    // Fetch Liquid RFQs
    builder
      .addCase(fetchLiquidRFQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLiquidRFQs.fulfilled, (state, action) => {
        state.liquidRFQs = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchLiquidRFQs.rejected, (state) => {
        state.loading = false;
      });

    // Fetch Gas RFQs
    builder
      .addCase(fetchGasRFQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGasRFQs.fulfilled, (state, action) => {
        state.gasRFQs = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchGasRFQs.rejected, (state) => {
        state.loading = false;
      });

    // Fetch FCI RFQs
    builder
      .addCase(fetchFCIRFQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFCIRFQs.fulfilled, (state, action) => {
        state.FCIRFQs = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchFCIRFQs.rejected, (state) => {
        state.loading = false;
      });

    // Fetch Featured RFQs
    builder
      .addCase(fetchFeturedRFQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFeturedRFQs.fulfilled, (state, action) => {
        state.featuredRFQs = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchFeturedRFQs.rejected, (state) => {
        state.loading = false;
      });

    // Fetch Nearby RFQs
    builder
      .addCase(fetchNearByRFQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNearByRFQs.fulfilled, (state, action) => {
        state.nearbyRFQs = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchNearByRFQs.rejected, (state) => {
        state.loading = false;
      });

    // quotes count
    builder
      .addCase(bookRFQ.pending, (state) => {
        state.loading = true;
      })
      .addCase(bookRFQ.fulfilled, (state, action) => {
        state.loading = false;
        const { RFQId, purchased } = action.payload;
        const RFQ = state.userRFQs.find((rfq) => rfq.id === RFQId);
        if (RFQ) {
          const updatedRFQ = {
            ...RFQ,
            quotesCount: (RFQ.quotesCount || 0) + 1,
            quantity: {
              ...RFQ.quantity,
              purchased,
            },
          };
          const rest = state.userRFQs.filter((rfq) => rfq.id !== RFQId);
          state.userRFQs = [...rest, updatedRFQ];
        }
      })
      .addCase(bookRFQ.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = RFQsSlice.actions;
export default RFQsSlice.reducer;
