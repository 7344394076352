import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getDeviceLocation,
  getStoredLocation,
  getStructuredLocation,
} from "../../utils/helper";
import { db } from "../../firebase";
import { openSnackbar } from "../store/modalsSlice";

export async function getReverseGeo(latitude, longitude) {
  if (!latitude || !longitude) return {};
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAPS_KEY}`
  )
    .then((res) => res.json())
    .then(({ results }) => getStructuredLocation(results[0]))
    .catch((err) => err.message);
}

// fetch user location
export const getCurrentLocation = createAsyncThunk(
  "getCurrentLocation",
  async (p, { rejectWithValue, getState }) => {
    try {
      const { id, dAddressId } = getState()?.auth?.user || {}; // 1st priority

      if (dAddressId) {
        const docSnap = await getDoc(
          doc(db, "users", id, "addresses", dAddressId)
        );
        if (docSnap.exists()) {
          return { ...docSnap.data(), id: docSnap.id, name: "current" };
        }
      }

      const deviceLoc = await getDeviceLocation(); // 2nd priority
      if (deviceLoc) return { ...deviceLoc, name: "current" };

      const storedLoc = await getStoredLocation(); // 3nd priority
      if (storedLoc) return { ...storedLoc, name: "current" };
      return {};
    } catch (error) {
      console.log(error, "check");
      rejectWithValue(error.message);
    }
  }
);

// fetch user location
export const fetchLocations = createAsyncThunk(
  "fetchLocation",
  async (p, { rejectWithValue, getState }) => {
    try {
      const { id } = getState()?.auth?.user || {}; // 1st priority
      if (id) {
        const q = query(collection(db, "users", id, "addresses"));
        const querySnapshot = await getDocs(q);
        const addresses = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists()) addresses.push({ id: doc.id, ...doc.data() });
        });
        return addresses;
      }
      return [];
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const addOrUpdateLocation = createAsyncThunk(
  "addOrUpdateLocation",
  async (addressData, { rejectWithValue, getState }) => {
    try {
      const { id } = getState().auth?.user;
      const { addressId } = getState().modals.addressFormModal;
      let data = {};
      if (addressId) {
        data = {
          ...addressData,
          updatedAt: Timestamp.fromDate(new Date()),
        };
        await setDoc(doc(db, "users", id, "addresses", addressId), data);
        return { ...addressData, id: addressId };
      }
      data = {
        ...addressData,
        createdAt: Timestamp.fromDate(new Date()),
      };
      const docRef = await addDoc(
        collection(db, "users", id, "addresses"),
        data
      );
      return { ...data, id: docRef.id };
    } catch (error) {
      console.log(error);
      rejectWithValue(error.message);
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "deleteLocation",
  async (addressId, { rejectWithValue, getState, dispatch }) => {
    try {
      const { id } = getState().auth?.user;
      if (!id || !addressId) {
        throw new Error("User ID or Address ID is missing.");
      }
      await deleteDoc(doc(db, "users", id, "addresses", addressId));
      dispatch(openSnackbar({ message: "Address Deleted", type: "success" }));
      return addressId;
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: "error" }));
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);
