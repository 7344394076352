import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";


const Meta = () => {
  return (
    <Helmet>
      <title>FAQ - FuelCab India</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com/faq",
            name: "FuelCab",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

export default function FAQ() {
  const [box1, setBox1] = useState(false);
  const [box2, setBox2] = useState(false);
  const [box3, setBox3] = useState(false);
  const [box4, setBox4] = useState(false);
  const [box5, setBox5] = useState(false);
  const [box6, setBox6] = useState(false);
  const [box7, setBox7] = useState(false);
  const [box8, setBox8] = useState(false);

  return (
    <>
      <Meta />
      <Navbar />
      <div className="font-dmsans relative flex flex-col items-center justify-center px-12">
        <div className="mt-8 bg-[#1D523B] md:p-36 p-20 rounded-xl w-full">
          <h1 className="text-center xl:text-6xl md:text-5xl text-xl font-bold leading-10 text-white">
            Frequently Asked Questions.
          </h1>
        </div>
        <div className="p-8 my-8 rounded-xl bg-gray-50 w-full">
          <div
            className="bg-white shadow rounded p-8 cursor-pointer"
            onClick={() => setBox1(!box1)}
          >
            <div
              className="flex items-center justify-between trans dropdown-content"
              style={{ cursor: "pointer" }}
            >
              <div>
                <h2 className="text-base font-semibold leading-none text-gray-800">
                  What is FuelCab India?
                </h2>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setBox1(!box1);
                }}
                className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
              >
                {box1 ? (
                  <svg
                    role="button"
                    aria-label="close dropdown"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L5 1L9 5"
                      stroke="#4B5563"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    role="button"
                    aria-label="open dropdown"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#4B5563"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>

            {box1 && (
              <ul className={`dropdown-content ${box1 ? "show" : ""}`}>
                <li>
                  <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">
                    FuelCab India is an online platform to provide doorstep
                    delivery of Renewable & Non-Renewable Fuels and Raw
                    Materials in a safe, reliable, and efficient way. You can
                    place your order online and get Fuels & Raw Materials
                    delivered at your location in specialized Refuellers
                    according to your chosen schedule. We are making refueling
                    smarter!
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div
            className="bg-white shadow rounded p-8 mt-8 cursor-pointer"
            onClick={() => {
              setBox2(!box2);
            }}
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-base font-semibold leading-none text-gray-800">
                  Which areas are being served by FuelCab India right now?
                </h2>
              </div>
              <button
                onClick={(e) => e.stopPropagation()}
                data-menu
                className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
              >
                {box2 ? (
                  <svg
                    role="button"
                    aria-label="close dropdown"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L5 1L9 5"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    role="button"
                    aria-label="open dropdown"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            {box2 && (
              <ul>
                <li>
                  <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">
                    Our Fuelling services are available in Uttarakhand, Uttar
                    Pradesh, Delhi, Haryana, Punjab, Pune, Bangalore and
                    Maharashtra and a number of other tier 2 cites across India
                    and our Raw material services are available in PAN India at
                    present.
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div
            className="bg-white shadow rounded p-8 mt-8 cursor-pointer"
            onClick={() => {
              setBox3(!box3);
            }}
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-base font-semibold leading-none text-gray-800">
                  What are FuelCab India hours of operations?
                </h2>
              </div>
              <button
                onClick={(e) => e.stopPropagation()}
                data-menu
                className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
              >
                {box3 ? (
                  <svg
                    role="button"
                    aria-label="close dropdown"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L5 1L9 5"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    role="button"
                    aria-label="open dropdown"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            {box3 && (
              <ul>
                <li>
                  <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">
                    Our vehicles operate and provide deliveries 24x7 round the
                    clock.
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div
            className="bg-white shadow rounded p-8 mt-8 cursor-pointer"
            onClick={() => setBox4(!box4)}
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-base font-semibold leading-none text-gray-800">
                  Where does Fuelcab India source its fuel from?
                </h2>
              </div>
              <button
                onClick={(e) => e.stopPropagation()}
                data-menu
                className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
              >
                {box4 ? (
                  <svg
                    role="button"
                    aria-label="close dropdown"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L5 1L9 5"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    role="button"
                    aria-label="open dropdown"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            {box4 && (
              <ul>
                <li>
                  <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">
                    FuelCab India sources fuel from authorized and selected
                    dealers of oil companies (like BPCL, IOCL & HPCL) near your
                    location. We follow a strict quality assurance program to
                    ensure every drop of fuel is checked for quality before it
                    gets loaded into our Refuellers for delivery. We do not
                    purchase or stock fuel prior to delivery. Fuel is sourced
                    only upon receiving orders from customers.
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div
            className="bg-white shadow rounded p-8 mt-8 cursor-pointer"
            onClick={() => setBox5(!box5)}
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-base font-semibold leading-none text-gray-800">
                  Can I schedule my future requirements of fuels instead
                  ordering when I need?
                </h2>
              </div>
              <button
                onClick={(e) => e.stopPropagation()}
                data-menu
                className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
              >
                {box5 ? (
                  <svg
                    role="button"
                    aria-label="close dropdown"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L5 1L9 5"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    role="button"
                    aria-label="open dropdown"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            {box5 && (
              <ul>
                <li>
                  <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">
                    Yes. Please contact us on +91-9988909052 and we will gladly
                    help on-board you as one of our scheduled delivery
                    customers.
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div
            className="bg-white shadow rounded p-8 mt-8 cursor-pointer"
            onClick={() => setBox6(!box6)}
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-base font-semibold leading-none text-gray-800">
                  What is Super Fast Delivery?
                </h2>
              </div>
              <button
                onClick={(e) => e.stopPropagation()}
                data-menu
                className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
              >
                {box6 ? (
                  <svg
                    role="button"
                    aria-label="close dropdown"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L5 1L9 5"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    role="button"
                    aria-label="open dropdown"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            {box6 && (
              <ul>
                <li>
                  <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">
                    With Super fast Delivery, you can book the orders in the
                    current slot or next slot - depending on time of the
                    booking. The estimated delivery time for the express order
                    is minimum 2 hours to maximum 4 hours, based on the time of
                    order booking. The order will be delivered within the time
                    slot allocated at the time of order placement.
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div
            className="bg-white shadow rounded p-8 mt-8 cursor-pointer"
            onClick={() => setBox7(!box7)}
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-base font-semibold leading-none text-gray-800">
                  {" "}
                  Can I track my order through the FUELCAB INDIA App?
                </h2>
              </div>
              <button
                onClick={(e) => e.stopPropagation()}
                data-menu
                className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
              >
                {box7 ? (
                  <svg
                    role="button"
                    aria-label="close dropdown"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L5 1L9 5"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    role="button"
                    aria-label="open dropdown"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            {box7 && (
              <ul>
                <li>
                  <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">
                    Yes, absolutely! You can track your order with real-time
                    information.
                  </p>
                </li>
              </ul>
            )}
          </div>
          <div
            className="bg-white shadow rounded p-8 mt-8 cursor-pointer"
            onClick={() => setBox8(!box8)}
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-base font-semibold leading-none text-gray-800">
                  I’m interested in becoming a Fuel entrepreneur, what support
                  do you offer?
                </h2>
              </div>
              <button
                onClick={(e) => e.stopPropagation()}
                data-menu
                className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
              >
                {box8 ? (
                  <svg
                    role="button"
                    aria-label="close dropdown"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5L5 1L9 5"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    role="button"
                    aria-label="open dropdown"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#4B5563"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            {box8 && (
              <ul>
                <li>
                  <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">
                    We handhold our customers right from setting up a company,
                    fabricating bowsers, and setting up the tech platform to
                    marketing the services in the industry, partnering with
                    other dealers and Oil companies, and acquiring new
                    customers.
                  </p>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
