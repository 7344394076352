import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProductByID } from "../../utils/helper";
import Button from "../../components/UIElements/Button";
import { KeyboardArrowUp } from "@mui/icons-material";
import { openTransactionFormModal } from "../../redux/store/modalsSlice";
import { fetchTransactions } from "../../redux/api/payments";

const PAYMENT_MODES = ["UPI", "CARD"];

const Row = ({ id, totalAmount, paidAmount, transactions }) => {
  const { userOrders } = useSelector((s) => s.orders);
  const { user } = useSelector((s) => s.auth);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!transactions && show) dispatch(fetchTransactions(id));
  }, [transactions, show]);

  const order = useMemo(() => {
    return userOrders.find((o) => o.paymentId === id) || {};
  }, [userOrders, id]);

  const product = useMemo(() => {
    return getProductByID(order.pId);
  }, [order]);

  const handlePay = () => {
    dispatch(openTransactionFormModal(id));
  };

  return (
    <>
      <tr className="cursor-pointer" onClick={() => setShow(!show)}>
        <td className="whitespace-no-wrap py-4 text-left text-sm text-gray-600 sm:px-3 lg:text-left">
          {`${order?.id}`.slice(-6)}
        </td>
        <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
          {product.title}
        </td>
        <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
          {order.quantity + " Ltr"}
        </td>
        <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
          {"₹" + totalAmount}
        </td>
        <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
          {"₹" + Number(totalAmount - paidAmount)}
        </td>
        <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
          {order.buyerName}
        </td>
        <td className="whitespace-no-wrap py-4 text-right text-sm text-gray-600 sm:px-3 lg:text-left">
          {order.status ? "Completed" : "In Progress"}
        </td>
        {user?.id === order?.buyerId && (
          <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-500 sm:px-3 lg:table-cell">
            {Number(paidAmount) !== Number(totalAmount) ? (
              <Button onClick={handlePay}>Pay</Button>
            ) : (
              "Payment Completed"
            )}
          </td>
        )}
        <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-500 sm:px-3 lg:table-cell">
          <KeyboardArrowUp />
        </td>
      </tr>
      {show && (
        <>
          <tr>
            <td></td>
            <td colSpan={8} className="font-[500] pb-2">
              {transactions ? "Transactions" : "No Transactions"}
            </td>
          </tr>
          {transactions &&
            transactions.map((t) => {
              return (
                <tr key={t.id} className="h-[50px]">
                  <td></td>
                  <td>{`${t.id}`.slice(-6)}</td>
                  <td>{t.createdAt.toDate().toLocaleDateString()}</td>
                  <td>₹{t.amount}</td>
                  <td>{PAYMENT_MODES[t.modeType]}</td>
                  <td>Paid</td>
                  <td colSpan={2}></td>
                </tr>
              );
            })}
        </>
      )}
    </>
  );
};

const PAGE_ITEM_COUNT = 5;
const FILTERS = ["all", "send", "receive"];

export default function Payments() {
  const { userPayments } = useSelector((s) => s.payments);
  const { user } = useSelector((s) => s.auth);
  const [filter, setFilter] = useState(FILTERS[0]);
  const [filteredPayments, setFilteredPayments] = useState([]);

  useEffect(() => {
    const offers = userPayments.filter((payment) => {
      if (filter === "send") {
        return payment.buyerId === user.id;
      } else if (filter === "receive") {
        return payment.sellerId === user.id;
      }
      return true;
    });
    setFilteredPayments([...offers]);
  }, [filter, userPayments]);

  return (
    <div className="h-full bg-gray-50">
      <div className="flex flex-wrap items-center gap-4 bg-white py-2 px-3">
        {FILTERS.map((fName) => {
          return (
            <button
              key={fName}
              onClick={() => setFilter(fName)}
              className={`capitalize inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm transition-all duration-200 ease-in-out ${
                filter === fName
                  ? "text-[#1D523B] border-b-[#1D523B] font-semibold"
                  : "text-gray-600 hover:border-b-[#1D523B] hover:text-[#1D523B]"
              }`}
            >
              {fName}
            </button>
          );
        })}
      </div>

      <div className="py-2 px-6">
        <div className="mt-6 overflow-hidden rounded-xl bg-white px-6 shadow lg:px-4">
          <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
            <thead className="hidden border-b lg:table-header-group">
              <tr className="">
                <td className="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3">
                  Order Id
                </td>
                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                  Product
                </td>
                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                  Quantity
                </td>
                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                  Total Amount
                </td>
                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                  Pending Amount
                </td>
                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                  From
                </td>
                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                  Order Status
                </td>
                {user?.isBuyer && (
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                    Action
                  </td>
                )}
              </tr>
            </thead>
            <tbody className="bg-white lg:border-gray-300">
              {filteredPayments.map((p) => {
                return <Row key={p.id} {...p} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
