const ProductCard = ({
  onClick = () => {},
  selected = false,
  imgSrc = "",
  title,
  description,
  disabled = false,
}) => {
  return (
    <div
      onClick={disabled ? () => {} : onClick}
      className={`bg-white max-w-[500px] flex md:flex-row flex-col items-center h-full gap-6 rounded-md border ${
        selected
          ? "border-[#1D523B]"
          : "hover:scale-105  hover:shadow-gray-500/30"
      }  transition-all duration-300 ${
        disabled ? "" : "cursor-pointer"
      } p-6 hover:shadow-2xl`}
    >
      <img src={imgSrc} className="w-[150px]" alt={title + "img"} />
      <span>
        <div className="text-xl font-[500] py-2">{title}</div>
        <div className="capitalize text-gray-500">{description}</div>
      </span>
    </div>
  );
};

export default ProductCard;
