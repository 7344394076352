import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  endAt,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  startAt,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import * as geofire from "geofire-common";
import { closeRFQFormModal, openSnackbar } from "../store/modalsSlice";

// ------------- NO STATE ------------------

export const getRFQ = async (RFQId) => {
  try {
    const docSnap = await getDoc(doc(db, "rfqs", RFQId));
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id };
    }
  } catch (err) {
    console.log(err);
  }
};

export async function getRFQReviews(RFQId) {
  try {
    const q = query(collection(db, "rfqs", RFQId, "reviews"));
    const querySnapshot = await getDocs(q);
    const reviews = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists()) reviews.push({ id: doc.id, ...doc.data() });
    });
    return reviews;
  } catch (err) {
    console.log("review", err);
  }
}

export async function createRFQReview(RFQId, review, avgRating) {
  try {
    const data = {
      ...review,
      createdAt: Timestamp.fromDate(new Date()),
    };
    await addDoc(collection(db, "rfqs", RFQId, "reviews"), data);
    await updateDoc(doc(db, "rfqs", RFQId), {
      avgRating,
    });
    return data;
  } catch (err) {
    console.log("refq", err);
  }
}

export const getRelatedRFQs = async (category) => {
  try {
    let rfqs = [];
    const q = query(
      collection(db, "rfqs"),
      where("categoryId", "==", category),
      limit(10)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.id) rfqs.push({ ...doc.data(), id: doc.id });
    });
    return rfqs;
  } catch (err) {
    console.log(err);
  }
};

// ---------------- RFQs CRUDS --------------

export const fetchUserRFQs = createAsyncThunk(
  "fetchUserRFQs",
  async (p, { rejectWithValue, getState }) => {
    try {
      const user = getState().auth.user;
      const q = query(collection(db, "rfqs"), where("buyerId", "==", user.id));
      const querySnapshot = await getDocs(q);
      const RFQs = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) RFQs.push({ ...doc.data(), id: doc.id });
      });
      return RFQs;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const updateRFQ = createAsyncThunk(
  "updateRFQ",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const docRef = doc(db, "rfqs", id);
      const rfq = {
        ...data,
        updatedAt: Timestamp.fromDate(new Date()),
      };
      console.log(rfq, "check");
      await updateDoc(docRef, rfq);
      return { ...rfq, id };
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const bookRFQ = createAsyncThunk(
  "bookRFQ",
  async ({ RFQId, purchased }, { rejectWithValue, dispatch }) => {
    try {
      if (!RFQId || !purchased) return rejectWithValue("Invalid RFQ ID");
      const listingRef = doc(db, "rfqs", RFQId);
      await updateDoc(listingRef, {
        quotesCount: increment(1),
        "quantity.purchased": purchased,
        updatedAt: Timestamp.fromDate(new Date()),
      });
      return { RFQId, purchased };
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: "error" }));
      console.error("Error incrementing bookingsCount:", error);
      return rejectWithValue("Failed to increment bookingsCount");
    }
  }
);

export const deleteRFQ = createAsyncThunk(
  "deleteRFQ",
  async (RFQId, { rejectWithValue }) => {
    if (!RFQId) return rejectWithValue("Error deleting file:");
    const docRef = doc(db, "rfqs", RFQId);
    try {
      await deleteDoc(docRef);
      return RFQId;
    } catch (error) {
      console.log(error);
      rejectWithValue("Error deleting file:");
    }
  }
);

export const postRFQ = createAsyncThunk(
  "postRFQ",
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const { latitude, longitude } = data.destination?.coords || {};
      const userId = getState().auth.user?.id;
      const today = new Date();
      const oneMonthLater = new Date(today);
      oneMonthLater.setDate(today.getDate() + 30);
      if (!data || !latitude || !userId) {
        return rejectWithValue("Something went wrong!");
      }
      const rfq = {
        ...data,
        quotesCount: 0,
        avgRating: 0,
        expiry: Timestamp.fromDate(oneMonthLater),
        buyerId: userId,
        geoHash: geofire.geohashForLocation([
          Number(latitude),
          Number(longitude),
        ]),
        createdAt: Timestamp.fromDate(new Date()),
      };
      const docRef = await addDoc(collection(db, "rfqs"), rfq);
      dispatch(openSnackbar({ message: "RFQ Created!", type: "success" }));
      dispatch(closeRFQFormModal());
      return { ...rfq, id: docRef.id };
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

// ----------------------- GET DIFFERENT RFQ TYPES ------------------

export const fetchTrendingRFQs = createAsyncThunk(
  "fetchTrendingRFQs",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "rfqs"),
        orderBy("bookingsCount", "asc"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const rfqs = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) rfqs.push({ ...doc.data(), id: doc.id });
      });
      return rfqs;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchNewRFQs = createAsyncThunk(
  "fetchNewRFQs",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "rfqs"),
        orderBy("createdAt", "asc"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const rfqs = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) rfqs.push({ ...doc.data(), id: doc.id });
      });
      return rfqs;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchSolidRFQs = createAsyncThunk(
  "fetchSolidRFQs",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "rfqs"),
        where("categoryId", "==", "solid"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const rfqs = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) rfqs.push({ ...doc.data(), id: doc.id });
      });
      return rfqs;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchLiquidRFQs = createAsyncThunk(
  "fetchLiquidRFQs",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "rfqs"),
        where("categoryId", "==", "liquid"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const rfqs = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) rfqs.push({ ...doc.data(), id: doc.id });
      });
      return rfqs;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchGasRFQs = createAsyncThunk(
  "fetchGasRFQs",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "rfqs"),
        where("categoryId", "==", "gas"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const rfqs = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) rfqs.push({ ...doc.data(), id: doc.id });
      });
      return rfqs;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchFCIRFQs = createAsyncThunk(
  "fetchFCIRFQs",
  async (arg, { rejectWithValue }) => {
    try {
      const adminQ = query(
        collection(db, "users"),
        where("isAdmin", "==", true)
      );
      const adminsSnap = await getDocs(adminQ);
      const admins = [];
      adminsSnap.forEach((doc) => {
        if (doc.id) admins.push(doc.id);
      });

      const q = query(
        collection(db, "rfqs"),
        where("buyerId", "in", admins),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const rfqs = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) rfqs.push({ ...doc.data(), id: doc.id });
      });
      return rfqs;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchFeturedRFQs = createAsyncThunk(
  "fetchFeturedRFQs",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "rfqs"),
        where("isFeatured", "==", true),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const rfqs = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) rfqs.push({ ...doc.data(), id: doc.id });
      });
      return rfqs;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchNearByRFQs = createAsyncThunk(
  "fetchNearByRFQs",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { coords } = getState().location.current;
      if (!coords?.latitude) return rejectWithValue("No coords");

      const center = [coords.latitude, coords.longitude];
      const radiusInM = 500000 * 1000;

      const bounds = geofire.geohashQueryBounds(center, radiusInM);
      const promises = bounds.map((b) =>
        getDocs(
          query(
            collection(db, "rfqs"),
            orderBy("geoHash"),
            startAt(b[0]),
            endAt(b[1])
          )
        )
      );

      const snapshots = await Promise.all(promises);
      const matchingDocs = snapshots.flatMap((snap) =>
        snap.docs.filter((doc) => {
          const lat = doc.get("lat");
          const lng = doc.get("lng");
          const distanceInM =
            geofire.distanceBetween([lat, lng], center) * 1000;
          return distanceInM <= radiusInM;
        })
      );
      return matchingDocs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAllRFQs = (dispatch) => {
  dispatch(fetchTrendingRFQs());
  dispatch(fetchNewRFQs());
  dispatch(fetchSolidRFQs());
  dispatch(fetchLiquidRFQs());
  dispatch(fetchGasRFQs());
  dispatch(fetchFCIRFQs());
  dispatch(fetchFeturedRFQs());
};
