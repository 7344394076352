import { createSlice } from "@reduxjs/toolkit";

import {
  acceptQuote,
  fetchRFQQuotes,
  fetchUserQuotes,
  postQuote,
  rejectQuote,
} from "../api/quotes";

const initialState = {
  loading: false,
  userQuotes: [],
  RFQQuotes: {},
};

export const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserQuotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserQuotes.fulfilled, (state, action) => {
        state.userQuotes = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserQuotes.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(postQuote.pending, (state) => {
        state.loading = true;
      })
      .addCase(postQuote.fulfilled, (state, action) => {
        state.userQuotes = [...state.userQuotes, action.payload];
        state.loading = false;
      })
      .addCase(postQuote.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchRFQQuotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRFQQuotes.fulfilled, (state, action) => {
        const rfqId = action.payload[0]?.rfqId;
        if (rfqId) state.RFQQuotes[rfqId] = action.payload;
        state.loading = false;
      })
      .addCase(fetchRFQQuotes.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(rejectQuote.pending, (state) => {
        state.loading = true;
      })
      .addCase(rejectQuote.fulfilled, (state, action) => {
        const quoteId = action.payload;
        const allQuotes = Object.values(state.RFQQuotes).reduce(
          (p, a) => [...p, ...a],
          []
        );
        const quote = allQuotes.find((o) => o.id === quoteId);
        const rfqId = quote.rfqId;

        const updatedOffers = state.RFQQuotes[rfqId].map((o) =>
          o.id === quoteId ? { ...o, status: -1 } : o
        );
        state.RFQQuotes[rfqId] = updatedOffers;
        state.loading = false;
      })
      .addCase(rejectQuote.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(acceptQuote.pending, (state) => {
        state.loading = true;
      })
      .addCase(acceptQuote.fulfilled, (state, action) => {
        const quoteId = action.payload;
        const allQuotes = Object.values(state.RFQQuotes).reduce(
          (p, a) => [...p, ...a],
          []
        );
        const quote = allQuotes.find((o) => o.id === quoteId);
        const rfqId = quote.rfqId;

        const updatedOffers = state.RFQQuotes[rfqId].map((o) =>
          o.id === quoteId ? { ...o, status: 1 } : o
        );
        state.RFQQuotes[rfqId] = updatedOffers;
        state.loading = false;
      })
      .addCase(acceptQuote.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = quotesSlice.actions;
export default quotesSlice.reducer;
