import React from "react";

export default function Input({
  label,
  value,
  onChange,
  placeholder,
  disabled,
  inputProps,
  error,
  containerProps,
  required,
  inputRef,
  className,
  ext,
  ...rest
}) {
  const { className: containerClassName, ...restContainerProps } =
    containerProps || {};
  return (
    <div
      className={`${containerClassName} font-dmsans flex h-fit flex-col w-full gap-1`}
      {...restContainerProps}
    >
      {!!label && (
        <label
          htmlFor={`${label}-input`}
          className={`text-sm leading-[18px] font-[500] ${
            disabled
              ? "text-gray-600"
              : error
              ? "text-[#d32f2f]"
              : "text-[#0D2620]"
          }`}
        >
          {label}
        </label>
      )}
      {ext}
      <input
        ref={inputRef}
        required={required}
        id={`${label}-input`}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`text-${
          disabled ? "gray-400 cursor-not-allowed" : "black"
        }  ${className} py-4 h-full text-sm w-full border rounded-xl px-8 border outline-none`}
        {...rest}
      />
      {error && (
        <p className={`${error ? "text-[#d32f2f]" : "text-[#151515]"} text-xs`}>
          {"*" + error}
        </p>
      )}
    </div>
  );
}
