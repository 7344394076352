import React, { useCallback, useMemo } from "react";
import { Modal } from "@mui/material";

import Button from "../../UIElements/Button";
import { useSelector } from "react-redux";

function Pair({ title, value, ext, className }) {
  return (
    <div className={`${className || ""} flex justify-between items-start`}>
      <h3 className="text-sm text-gray-400">{title}</h3>
      <p className="text-md truncate max-w-[200px]">
        {value} {ext ? " " + ext : ""}
      </p>
    </div>
  );
}

export default function PayModal({
  isOpen,
  onClose,
  list = [],
  description = "",
  order_id,
  onComplete = () => {},
}) {
  const { user } = useSelector((s) => s.auth);
  const { current: address } = useSelector((s) => s.location);

  const handlePayment = useCallback(() => {
    const total = list[list.length - 1]?.amount;
    
    const options = {
      key: process.env.REACT_APP_RAZORPAY_LIVE_KEY,
      amount: total * 100,
      currency: "INR",
      name: user.name,
      order_id,
      description,
      image: user.profileURL,
      handler: (res) => {
        onComplete(res);
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phoneNumber,
      },
      notes: {
        address: address.addressLine,
      },
      theme: {
        color: "#1D523B",
      },
    };

    const rzpay = new window.Razorpay(options);
    rzpay.open();
  }, [list]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 1,
      }}
    >
      <div className="font-dmsans min-w-[30vw] bg-white py-6 md:px-10 px-8 rounded-lg">
        <h1 className="text-primary-100 md:text-[2.4rem] text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
          Pay Amount
        </h1>
        <p className="text-sm leading-[1.5rem] text-gray-400">
          Pay to complete the order.
        </p>
        <div className="mt-4 flex flex-wrap"></div>
        {list.map(({ title, amount }) => (
          <Pair title={title} value={`₹${amount}`} />
        ))}
        <div className="mt-2 flex gap-4">
          <Button mode="text" onClick={onClose}>
            Close
          </Button>
          <Button onClick={handlePayment}>Pay</Button>
        </div>
      </div>
    </Modal>
  );
}
