import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDownward, ArrowUpward, CheckCircle } from "@mui/icons-material";

import Button from "../../components/UIElements/Button";
import { PAYMENT_TERMS } from "../../constants/product";
import { getProductByID } from "../../utils/helper";
import { openDispatchFormModal } from "../../redux/store/modalsSlice";
import { completedOrder, fetchOrderDispatches } from "../../redux/api/orders";

function Pair({ title, value, ext }) {
  return (
    <div className="flex flex-col items-start gap-2">
      <h3 className="text-sm text-gray-400">{title}</h3>
      <p className="text-md truncate max-w-[150px] flex items-center gap-1">
        {value} {ext ? " " + ext : ""}
      </p>
    </div>
  );
}

function Card({
  id,
  pId,
  edd,
  dispatchedQty,
  quantity,
  destination,
  tacType,
  status,
  buyerName,
  paymentId,
  dispatches,
  sellerId,
}) {
  const { userPayments } = useSelector((s) => s.payments);
  const { loading } = useSelector((s) => s.orders);
  const { user } = useSelector((s) => s.auth);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const product = getProductByID(pId);

  const payment = useMemo(() => {
    return userPayments.find((p) => p.id === paymentId) || {};
  }, [userPayments]);

  useEffect(() => {
    if (!dispatches && show) dispatch(fetchOrderDispatches(id));
  }, [dispatches, show]);

  const handleCreate = () => {
    dispatch(openDispatchFormModal(id));
  };

  const handleComplete = () => {
    dispatch(completedOrder(id));
  };

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <button
        type="button"
        onClick={() => setShow(!show)}
        className={`bg-white flex items-center gap-8 w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 ${
          show ? "rounded-t-xl" : "rounded-xl"
        } hover:shadow gap-3`}
        data-accordion-target="#accordion-collapse-body-1"
        aria-expanded={show}
        aria-controls="accordion-collapse-body-1"
      >
        <div className="h-full w-full flex justify-between items-center">
          <Pair title="Order Id" value={`$${id}`.slice(-6)} />
          <Pair title="Product" value={product.title} />
          <Pair title="Quantity" value={`${quantity} ${product.unit}`} />
          <Pair
            title="Dispatched Qty"
            value={`${dispatchedQty} ${product.unit}`}
          />
          <Pair title="Destination" value={destination.addressLine} />
          <Pair
            title="Expected Delivery Date"
            value={edd.toDate().toLocaleDateString()}
          />
          <Pair
            title="Order Status"
            value={status ? "Completed" : "In Progress"}
          />
        </div>
        {show ? (
          <ArrowDownward className="ml-auto" />
        ) : (
          <ArrowUpward className="ml-auto" />
        )}
      </button>
      {show && (
        <div
          id="accordion-collapse-body-1"
          aria-labelledby="accordion-collapse-heading-1"
          className="bg-white border border-t-0 rounded-b-xl p-5 grid grid-cols-5 gap-4"
        >
          <Pair
            title="Buyer Name"
            value={
              <>
                {buyerName}
                <CheckCircle sx={{ fontSize: 18, color: "darkgreen" }} />
              </>
            }
          />
          <Pair title="Destination Address" value={destination.addressLine} />
          <Pair
            title="Pending Amount"
            value={Number(payment?.totalAmount - payment?.paidAmount)}
          />
          <Pair title="Total Amount" value={Number(payment?.totalAmount)} />
          <Pair title="Terms & Conditions" value={PAYMENT_TERMS[tacType]} />

          <div className="mt-2 col-span-5 flex items-center justify-between w-full">
            <p className="text-xl font-[500]">Dispatches</p>
            {user?.id === sellerId && (
              <span>
                {Number(quantity) === dispatchedQty ? (
                  status ? (
                    "Order Completed"
                  ) : (
                    <Button
                      onClick={handleComplete}
                      loading={loading}
                      disabled={loading}
                    >
                      Complete Order
                    </Button>
                  )
                ) : (
                  <Button onClick={handleCreate}>Create Dispatch</Button>
                )}
              </span>
            )}
          </div>

          {dispatches &&
            dispatches.map((d) => {
              return (
                <div className="col-span-5 flex items-center justify-between">
                  <Pair title="ID" value={`${d.id}`.slice(-6)} />
                  <Pair title="Eway Bill" value={d.ewayBN} />
                  <Pair
                    title="Quantity"
                    value={d.quantity + " " + product.unit}
                  />
                  <Pair
                    title="Expected Reaching Date"
                    value={
                      d.erd instanceof Date
                        ? d.erd?.toLocaleDateString()
                        : d.erd?.toDate()?.toLocaleDateString()
                    }
                  />
                  <Pair title="Note" value={d.note} />
                  <Pair
                    title="Bills"
                    value={
                      <>
                        <a href={d.ewayBURL} target="_blank">
                          Eway
                        </a>
                        |
                        <a href={d.invoiceURL} target="_blank">
                          Invoice
                        </a>
                      </>
                    }
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

const FILTERS = ["all", "pending", "completed"];

export default function Payments() {
  const { userOrders } = useSelector((s) => s.orders);
  const [filter, setFilter] = useState(FILTERS[0]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    const orders = userOrders.filter((order) => {
      if (filter === "pending") {
        return order.status === 0;
      } else if (filter === "completed") {
        return order.status === 1;
      }
      return true;
    });
    setFilteredOrders([...orders]);
  }, [filter, userOrders]);

  return (
    <div className="h-full bg-gray-50">
      <div className="flex flex-wrap items-center gap-4 bg-white py-2 px-3">
        {FILTERS.map((fName) => {
          return (
            <button
              key={fName}
              onClick={() => setFilter(fName)}
              className={`capitalize inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm transition-all duration-200 ease-in-out ${
                filter === fName
                  ? "text-[#1D523B] border-b-[#1D523B] font-semibold"
                  : "text-gray-600 hover:border-b-[#1D523B] hover:text-[#1D523B]"
              }`}
            >
              {fName}
            </button>
          );
        })}
      </div>

      <div className="p-8 flex flex-col gap-4">
        {filteredOrders.map((o) => {
          return <Card key={o.id} {...o} />;
        })}
      </div>
    </div>
  );
}
