import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { PAYMENT_TERMS, FREQUENCIES, PRODUCTS } from "../../constants/product";
import RadioButton from "../../components/UIElements/RadioButton/RadioButton";
import Dropdown from "../../components/UIElements/Dropdown/Dropdown";
import { postRFQ } from "../../redux/api/RFQs";
import Input from "../../components/UIElements/Input";
import AddressInput from "../../components/AddressInput";
import Button from "../../components/UIElements/Button";
import { Carousel } from "react-responsive-carousel";
import {
  getCategoryFromPID,
  getProductByID,
  getProductIdByTitle,
} from "../../utils/helper";

const formInintialData = {
  pId: "",
  categoryId: "",
  quantity: {
    forMonths: "",
    frequencyType: 0,
    value: "",
  },
  tacType: 0,
  qRemark: "",
  destination: {},
};

export default function RFQ() {
  const [formData, setFormData] = useState(formInintialData);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const products = useMemo(() => {
    return [
      ...PRODUCTS.solid.map((item) => item.title),
      ...PRODUCTS.liquid.map((item) => item.title),
      ...PRODUCTS.gas.map((item) => item.title),
    ];
  }, []);

  function handleReset() {
    setFormData({ ...formInintialData });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      dispatch(postRFQ(formData)).unwrap();
      handleReset();
      setLoading(true);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <section className="mt-10 rounded-lg bg-gray-50 border flex">
      <div className="w-1/2 hidden md:block">
        <div className="w-full rounded-l-lg">
          <img
            src={`/banner/rfq.webp`}
            className="h-full object-contain rounded-l-lg"
            alt="rfq-form"
          />
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="md:w-1/2 bg-white p-6 md:px-10 px-8"
      >
        <div className="flex flex-wrap justify-between">
          <h1 className="text-primary-100 md:text-[2.4rem] text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
            RFQ
          </h1>
          <span className="flex justify-center items-center gap-4">
            <Button type="submit" loading={loading}>
              Submit
            </Button>
            <Button
              className="hidden md:block"
              mode="text"
              onClick={handleReset}
            >
              Reset
            </Button>
          </span>
        </div>
        <p className="text-sm leading-[1.5rem] text-gray-400">
          List your RFQ under 1 minute.
        </p>
        <div className="mt-4 flex flex-col gap-4">
          <Dropdown
            label="Product Type"
            units={products}
            unit={getProductByID(formData.pId).title}
            setUnit={(value) => {
              const pId = getProductIdByTitle(value);
              setFormData((p) => ({
                ...p,
                categoryId: getCategoryFromPID(pId),
                pId,
              }));
            }}
          />
          <AddressInput
            label="Destination Address"
            name="destination"
            onChange={(destination) =>
              setFormData((p) => ({ ...p, destination }))
            }
          />
          <Dropdown
            label="Payment Terms"
            units={PAYMENT_TERMS}
            unit={PAYMENT_TERMS[formData.tacType]}
            setUnit={(value) => {
              setFormData((p) => ({
                ...p,
                tacType: PAYMENT_TERMS.indexOf(value),
              }));
            }}
          />
          <div className="flex gap-4">
            <Input
              label="Quality Remark"
              name="qRemark"
              value={formData.qRemark}
              placeholder="Enter Quality Remark"
              required
              onChange={(e) =>
                setFormData((p) => ({
                  ...p,
                  qRemark: e.target.value,
                }))
              }
            />
            {formData.quantity.frequencyType === 1 && (
              <Input
                value={formData.quantity.forMonths}
                onChange={(e) => {
                  setFormData((p) => ({
                    ...p,
                    quantity: {
                      ...p.quantity,
                      forMonths: e.target.value,
                    },
                  }));
                }}
                required
                type="number"
                placeholder="Enter Months"
                label="Enter Months"
              />
            )}
          </div>
          <Input
            label="Quantity Needed"
            value={formData.quantity.value}
            onChange={(e) => {
              setFormData((p) => ({
                ...p,
                quantity: {
                  ...p.quantity,
                  value: e.target.value,
                },
              }));
            }}
            required
            type="number"
            placeholder="Enter Quantity"
            ext={
              <RadioButton
                name="availableFrequencyType"
                size="small"
                values={FREQUENCIES}
                value={FREQUENCIES[formData.quantity.frequencyType]}
                onChange={(e) => {
                  setFormData((p) => ({
                    ...p,
                    quantity: {
                      ...p.quantity,
                      frequencyType: FREQUENCIES.indexOf(e.target.value),
                    },
                  }));
                }}
              />
            }
          />
        </div>
      </form>
    </section>
  );
}
