import { createSlice } from "@reduxjs/toolkit";
import {
  fetchListingOffers,
  fetchUserOffers,
  postOffer,
  rejectOffer,
  acceptOffer,
} from "../api/offers";

const initialState = {
  loading: false,
  userOffers: [],
  listingOffers: {},
};

export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserOffers.fulfilled, (state, action) => {
        state.userOffers = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserOffers.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(postOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(postOffer.fulfilled, (state, action) => {
        state.userOffers = [...state.userOffers, action.payload];
        state.loading = false;
      })
      .addCase(postOffer.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchListingOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchListingOffers.fulfilled, (state, action) => {
        const listingId = action.payload[0]?.listingId;
        if (listingId) state.listingOffers[listingId] = action.payload;
        state.loading = false;
      })
      .addCase(fetchListingOffers.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(rejectOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(rejectOffer.fulfilled, (state, action) => {
        const offerId = action.payload;
        const allOffers = Object.values(state.listingOffers).reduce(
          (p, a) => [...p, ...a],
          []
        );
        const offer = allOffers.find((o) => o.id === offerId);
        const listingId = offer.listingId;

        const updatedOffers = state.listingOffers[listingId].map((o) =>
          o.id === offerId ? { ...o, status: -1 } : o
        );
        state.listingOffers[listingId] = updatedOffers;
        state.loading = false;
      })
      .addCase(rejectOffer.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(acceptOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(acceptOffer.fulfilled, (state, action) => {
        const offerId = action.payload;
        const allOffers = Object.values(state.listingOffers).reduce(
          (p, a) => [...p, ...a],
          []
        );
        const offer = allOffers.find((o) => o.id === offerId);
        const listingId = offer.listingId;

        const updatedOffers = state.listingOffers[listingId].map((o) =>
          o.id === offerId ? { ...o, status: 1 } : o
        );
        state.listingOffers[listingId] = updatedOffers;
        state.loading = false;
      })
      .addCase(acceptOffer.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = offersSlice.actions;
export default offersSlice.reducer;
