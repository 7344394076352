import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";

import { closeTransactionFormModal } from "../../../redux/store/modalsSlice";
import Input from "../../UIElements/Input";
import Button from "../../UIElements/Button";
import { createTransaction } from "../../../redux/api/payments";
import PayModal from "../PayModal";

function Pair({ title, value, ext, className }) {
  return (
    <div className={`${className} ${"flex flex-col items-start gap-1"}`}>
      <h3 className="text-sm text-gray-400">{title}</h3>
      <p className="text-md font-semibold truncate max-w-[150px] flex items-center gap-1">
        {value} {ext ? " " + ext : ""}
      </p>
    </div>
  );
}

export default function TransactionFormModal() {
  const { isOpen, paymentId } = useSelector(
    (s) => s.modals.transactionFormModal
  );
  const { userPayments, loading } = useSelector((s) => s.payments);
  const { userOrders } = useSelector((s) => s.orders);
  const [review, setReview] = useState(false);
  const [amount, setAmount] = useState("");
  const [pay, setPay] = useState([]);
  const dispatch = useDispatch();

  const payment = useMemo(() => {
    return userPayments.find((p) => p.id === paymentId) || {};
  }, [paymentId]);

  const order = useMemo(() => {
    return userOrders.find((o) => o.paymentId === paymentId) || {};
  }, [userOrders, paymentId]);

  const finalPendingAmount = useMemo(() => {
    return Number(payment.totalAmount - payment.paidAmount - amount);
  }, [payment, amount]);

  function handleClose() {
    setAmount("");
    setReview(false);
    dispatch(closeTransactionFormModal());
  }

  async function handleComplete() {
    try {
      dispatch(createTransaction(amount));
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const list = [{ title: "Total:", amount: finalPendingAmount }];
    setPay(list);
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          zIndex: 1,
        }}
      >
        <form
          onSubmit={handleSubmit}
          className="bg-white p-6 md:px-10 px-8 rounded-md min-w-[40vw]"
        >
          <h1 className="text-primary-100 text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
            Make Payment
          </h1>
          <p className="text-sm leading-[1.5rem] text-gray-400 mb-4">
            Pending quantity: ₹
            {Number(payment.totalAmount - payment.paidAmount)}
            .00 | {`${payment.id}`.slice(-6)}
          </p>
          {review ? (
            <>
              <div className="flex items-center justify-between">
                <Pair title="Amount" value={"₹" + amount} />
                <Pair title="To" value={order.buyerName} />
              </div>
              <div className="border-b-1 my-2" />
              <h3 className="font-semibold text-xl mb-2">Payment Details</h3>
              <Pair
                className="!flex-row justify-between"
                title="Total Bill"
                value={"₹" + payment.totalAmount}
              />
              <Pair
                className="!flex-row justify-between"
                title="Previous Payments"
                value={"-₹" + payment.paidAmount}
              />
              <Pair
                className="!flex-row justify-between"
                title="Amount Pending"
                value={"₹" + Number(payment.totalAmount - payment.paidAmount)}
              />
              <Pair
                className="!flex-row justify-between"
                title="Current Payment"
                value={"-₹" + amount}
              />
              <div className="border-b-1 my-2" />
              <Pair
                className="!flex-row justify-between"
                title="Final Pending Amount"
                value={"₹" + finalPendingAmount}
              />
              <Button
                className="w-full mt-8"
                type="submit"
                loading={loading}
                disabled={loading}
              >
                Make Payment
              </Button>
            </>
          ) : (
            <>
              <Input
                value={amount}
                label="Amount"
                type="number"
                placeholder="Enter amount to be paid"
                required
                onChange={(e) => setAmount(e.target.value)}
              />
              <Button
                className="w-full mt-8"
                type="submit"
                onClick={() => setReview(true)}
              >
                Review Details
              </Button>
            </>
          )}
        </form>
      </Modal>
      <PayModal
        isOpen={!!pay.length}
        description="Payment for Seller"
        list={pay}
        onClose={() => setPay([])}
        onComplete={handleComplete}
      />
    </>
  );
}
