import React from "react";

export default function Hero() {
  return (
    <section className="relative bg-no-repeat md:mx-12 mx-6 md-6 mt-8 p-10 flex justify-center items-center md:h-[75vh] h-[50vh]">
      <img
        src="/banner/about-us.png"
        className="absolute object-cover left-0 rounded-xl w-full h-full"
        alt="about-us-banner"
      />
      <h1 className="text-6xl text-primary-100 text-center font-bold absolute top-[40%] mx-auto">
        FuelCab, Revolutionizing Fuel Solutions.
      </h1>
    </section>
  );
}
