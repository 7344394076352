import { configureStore, combineReducers } from "@reduxjs/toolkit";
import ordersSlice from "./store/ordersSlice";
import walletSlice from "./store/walletSlice";
import modalsSlice from "./store/modalsSlice";
import authSlice from "./store/authSlice";
import locationSlice from "./store/locationSlice";
import listingsSlice from "./store/listingsSlice";
import RFQsSlice from "./store/RFQsSlice";
import bookingsSlice from "./store/bookingsSlice";
import offersSlice from "./store/offersSlice";
import quotesSlice from "./store/quotesSlice";
import paymentsSlice from "./store/paymentsSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  modals: modalsSlice,
  wallet: walletSlice,
  orders: ordersSlice,
  location: locationSlice,
  listings: listingsSlice,
  RFQs: RFQsSlice,
  bookings: bookingsSlice,
  offers: offersSlice,
  payments: paymentsSlice,
  quotes: quotesSlice,
});

export const store = configureStore({
  reducer: rootReducer,
});
