import { createSlice } from "@reduxjs/toolkit";
import {
  createPayment,
  fetchUserPayments,
  createTransaction,
  fetchTransactions,
} from "../api/payments";

const initialState = {
  userPayments: [],
  ordersPayment: [],
  loading: false,
  error: "",
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPayments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.userPayments = action.payload;
      })
      .addCase(fetchUserPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        const { paymentId, transactions } = action.payload;
        const paymentIndex = state.userPayments.findIndex(
          (p) => p.id === paymentId
        );
        if (paymentIndex !== -1) {
          state.userPayments[paymentIndex] = {
            ...state.userPayments[paymentIndex],
            transactions,
          };
        }
        state.loading = false;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(createPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        const payment = action.payload;
        if (payment) state.userPayments = [...state.userPayments, payment];
        state.loading = false;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(createTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTransaction.fulfilled, (state, action) => {
        const { paymentId, transaction } = action.payload;
        const payment = state.userPayments.find((p) => p.id === paymentId);
        if (payment) {
          const rest = state.userPayments.filter((p) => p.id !== paymentId);
          if (payment.transactions) {
            payment.transactions = [...payment.transactions, transaction];
          } else payment.transactions = [transaction];
          payment.paidAmount =
            Number(payment.paidAmount) + Number(transaction.amount);
          state.userPayments = [...rest, payment];
        }
        state.loading = false;
      })
      .addCase(createTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = paymentsSlice.actions;

export default paymentsSlice.reducer;
