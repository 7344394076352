import React from "react";

const TestimonialCard = ({
  content,
  author,
  role,
  imageSrc,
  companyUrl,
  companyName,
}) => {
  return (
    <div className="relative mx-auto mt-8 rounded-md max-w-sm p-10 border text-gray-700 leading-snug flex flex-col justify-between">
      <div className="-ml-4">
        <svg
          className="w-8 opacity-25 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          imageRendering="optimizeQuality"
          fillRule="evenodd"
          clipRule="evenodd"
          viewBox="0 0 640 640"
          fill="currentColor"
        >
          <path d="M557.133 561.704H442.128c-44.256 0-80.458-36.19-80.458-80.446 0-165.58-42.32-347.485 160.656-399.418 91.95-23.516 115.915 77.753 18.119 84.745-59.647 4.276-71.293 42.804-73.147 101.068h92.269c44.256 0 80.458 36.201 80.458 80.458v130.702c0 45.591-37.3 82.89-82.891 82.89zm-358.032 0H84.096c-44.256 0-80.446-36.19-80.446-80.446 0-165.58-42.331-347.485 160.644-399.418 91.95-23.516 115.915 77.753 18.118 84.745-59.646 4.276-71.292 42.804-73.146 101.068h92.269c44.256 0 80.457 36.201 80.457 80.458v130.702c0 45.591-37.3 82.89-82.89 82.89z" />
        </svg>
      </div>
      <div className="mt-2">{content}</div>
      <div>
        <div className="mx-auto w-full border-t border-gray-300 my-8"></div>
        <div className="flex items-center">
          <img
            className="!w-[80px] !h-[80px] rounded-full border-gray-400 object-cover"
            src={imageSrc}
            alt={author}
          />
          <div className="ml-4">
            <div className="font-bold">{author}</div>
            <div className="text-sm text-gray-600 mt-1">
              {role}, <p>{companyName}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Testimonial = () => {
  return (
    <div className="relative mt-10 text-gray-900 sm:rounded-md">
      <div className="relative">
        <div className="text-center mb-4">
          <h2 className="font-bold text-[25px] lg:text-[35px] leading-none mb-2">
            Testimonials
          </h2>
          <p className="text-gray-400">
            Here are what some of our amazing customers are saying...
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:mx-12">
          <TestimonialCard
            content="FuelCab has made it so easy for us to find and purchase biofuels. The platform has a wide range of options, and their customer service has been very supportive throughout our buying process."
            author="Amit Sharma"
            role="Procurement Manager"
            imageSrc="/profile/amit-sharma.webp"
            companyUrl="https://www.fuelcab.com"
            companyName="Indian Oil Corporation"
          />

          <TestimonialCard
            content="As a supplier of biodiesel, FuelCab has given us access to more buyers than we ever had before. Their platform is reliable, and we’ve increased our sales by 20% in the last quarter alone."
            author="Priya Reddy"
            role="Managing Director"
            imageSrc="/profile/priya-reddy.webp"
            companyUrl="https://www.fuelcab.com"
            companyName="BioGreen Fuels Pvt. Ltd."
          />

          <TestimonialCard
            content="FuelCab is the most convenient platform we've used for fuel procurement. It offers excellent variety, and the seamless process has reduced our sourcing time significantly."
            author="Rajesh Kumar"
            role="Logistics Head"
            imageSrc="/profile/rajesh-kumar.webp"
            companyUrl="https://www.fuelcab.com"
            companyName="Bharat Petroleum Corporation Limited"
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
