import {
  CreditScore,
  Folder,
  FormatQuote,
  List,
  Logout,
  Newspaper,
  Notifications,
  Payment,
  People,
  Person,
  Phone,
  Place,
  SettingsSharp,
} from "@mui/icons-material";
import { useEffect, useMemo } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { fetchUserRFQs } from "../../redux/api/RFQs";
import { logout } from "../../redux/api/auth";
import { fetchUserListings } from "../../redux/api/listings";
import { Avatar } from "@mui/material";
import { fetchUserOffers } from "../../redux/api/offers";
import { fetchUserOrders } from "../../redux/api/orders";
import { fetchUserPayments } from "../../redux/api/payments";
import { fetchUserQuotes } from "../../redux/api/quotes";

const navLinks = [
  {
    id: "rfqs",
    name: "RFQs",
    href: "rfqs",
    Icon: Newspaper,
    type: "home",
    userType: "buyer",
  },
  {
    id: "listings",
    name: "Listings",
    href: "listings",
    Icon: Folder,
    type: "home",
    userType: "seller",
  },
  {
    id: "orders",
    name: "Orders",
    href: "orders",
    Icon: List,
    type: "home",
    userType: "all",
  },
  {
    id: "myQuotes",
    name: "My Quotes",
    href: "myQuotes",
    Icon: FormatQuote,
    type: "home",
    userType: "seller",
  },
  {
    id: "myOffers",
    name: "My Offers",
    href: "myOffers",
    Icon: People,
    type: "home",
    userType: "buyer",
  },
  {
    id: "payments",
    name: "Payments",
    href: "payments",
    Icon: Payment,
    type: "home",
    userType: "all",
  },
  {
    id: "profile",
    name: "Profile",
    href: "profile",
    Icon: Person,
    type: "settings",
    userType: "all",
  },
  {
    id: "billing",
    name: "Billing",
    href: "billing",
    Icon: CreditScore,
    type: "settings",
    userType: "all",
  },
  {
    id: "addresses",
    name: "Addresses",
    href: "addresses",
    Icon: Place,
    type: "settings",
    userType: "all",
  },
];

export default function Dashboard() {
  const { user } = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate("/login", { replace: true });
  }, []);

  useEffect(() => {
    if (!user) return;
    if (pathname === "/dashboard") {
      return navigate(user?.isBuyer ? "rfqs" : "listings", { replace: true });
    }
    if (!user?.isSeller) {
      `${pathname}`.includes("/listings") &&
        navigate("rfqs", { replace: true });
      `${pathname}`.includes("/myQuotes") &&
        navigate("myOffers", { replace: true });
      return;
    }
    if (!user?.isBuyer) {
      `${pathname}`.includes("/rfqs") &&
        navigate("listings", { replace: true });
      `${pathname}`.includes("/myOffers") &&
        navigate("myQuotes", { replace: true });
      return;
    }
  }, [user, pathname]);

  useEffect(() => {
    if (user?.isBuyer) dispatch(fetchUserRFQs());
    if (user?.isSeller) dispatch(fetchUserListings());
    if (user?.isBuyer) dispatch(fetchUserOffers());
    if (user?.isSeller) dispatch(fetchUserQuotes());
    dispatch(fetchUserOrders());
    dispatch(fetchUserPayments());
  }, [user]);

  const activePath = useMemo(() => {
    const slugs = pathname.split("/");
    return slugs[slugs.length - 1];
  }, [pathname]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/", { replace: true });
  };

  return (
    <div className="font-dmsans flex h-screen">
      <aside>
        <div className="flex h-screen w-64 flex-grow flex-col overflow-y-auto bg-white py-6 border-r">
          <div className="flex items-center ml-3">
            {user?.profileURL ? (
              <img
                className="h-12 w-12 max-w-full align-middle rounded-[50%]"
                src={user?.profileURL}
                alt={user?.name + "img"}
              />
            ) : (
              <Avatar title={user?.name} />
            )}
            <div className="flex ml-3 flex-col">
              <h3 className="font-medium max-w-[150px] truncate">
                {user?.name}
              </h3>
              <p className="text-xs text-gray-500">{user?.phoneNumber}</p>
            </div>
          </div>

          <span className="ml-3 mt-10 block text-xs font-semibold text-gray-500">
            Home
          </span>

          <div className="flex mt-3 flex-1 flex-col">
            <div className="">
              <nav className="flex-1">
                {navLinks
                  .filter((l) => l.type === "home")
                  .map(({ Icon, href, name, id, userType }) => {
                    if (!user?.isSeller && userType === "seller") return;
                    if (!user?.isBuyer && userType === "buyer") return;
                    return (
                      <Link
                        key={id}
                        replace
                        to={href}
                        className={`gap-2 flex cursor-pointer items-center border-l-[#1D523B] py-2 px-4 text-sm font-medium text-gray-600 outline-none transition-all duration-100 ease-in-out hover:border-l-4 hover:border-l-[#1D523B] hover:text-[#1D523B] ${
                          activePath === href ? "border-l-4" : ""
                        }`}
                      >
                        <Icon />
                        {name}
                      </Link>
                    );
                  })}
              </nav>

              <span className="ml-3 mt-10 mb-2 block text-xs font-semibold text-gray-500">
                Settings
              </span>

              <nav className="flex-1">
                {navLinks
                  .filter((l) => l.type === "settings")
                  .map(({ Icon, href, name, id }) => {
                    return (
                      <Link
                        key={id}
                        to={href}
                        className={`gap-2 flex cursor-pointer items-center border-l-[#1D523B] py-2 px-4 text-sm font-medium text-gray-600 outline-none transition-all duration-100 ease-in-out hover:border-l-4 hover:border-l-[#1D523B] hover:text-[#1D523B] ${
                          activePath === href ? "border-l-4" : ""
                        }`}
                      >
                        <Icon />
                        {name}
                      </Link>
                    );
                  })}
                <button
                  onClick={handleLogout}
                  className={`gap-2 flex cursor-pointer items-center border-l-red-600 py-2 px-4 text-sm font-medium text-gray-600 outline-none transition-all duration-100 ease-in-out hover:border-l-4 hover:border-l-red-600 hover:text-red-600`}
                >
                  <Logout />
                  Logout
                </button>
              </nav>
            </div>
          </div>
        </div>
      </aside>

      <div className="flex h-full w-full flex-col">
        <header className="relative flex flex-col items-center bg-white px-4 py-4 border-b sm:flex-row md:h-20">
          <div className="flex w-full flex-col justify-between overflow-hidden transition-all sm:max-h-full sm:flex-row sm:items-center">
            <h1 className="text-2xl font-semibold">
              {navLinks.find((l) => l.href === activePath)?.name}
            </h1>
            <ul className="mx-auto mt-4 flex space-x-6 sm:mx-5 sm:mt-0">
              <li>
                <Link
                  to="/dashboard/profile"
                  className="flex h-8 w-8 items-center justify-center rounded-xl border text-gray-600 hover:text-black hover:shadow"
                >
                  <SettingsSharp />
                </Link>
              </li>
            </ul>
          </div>
        </header>

        <div className="h-full overflow-hidden">
          <div
            id="dashboard-main"
            className="h-[calc(100vh-5rem)] overflow-auto"
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
