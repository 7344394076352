import LazyLoad from "react-lazyload";
import { Star, ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";

import {
  getImgURLById,
  getProductByID,
  roundToDecimal,
} from "../../utils/helper";

export default function Item({
  item: {
    pId,
    bookingsCount,
    quotesCount,
    avgRating,
    unitPrice,
    imagesURL,
    quantity,
    origin,
    id,
  },
  isCategory,
  showPrice,
}) {
  return (
    <Link
      to={`/${origin ? "listing" : "rfq"}/${id}`}
      className="opacity-0.5 pl-2 w-[240px] min-w-[240px] block"
    >
      <div
        className={`relative bg-white h-[230px] w-full overflow-hidden rounded-md border hover:border-gray-400`}
      >
        <LazyLoad>
          <img
            src={imagesURL ? imagesURL[0] : getImgURLById(pId)}
            alt="product"
            className={`absolute top-0 left-0 w-full h-full object-contain transition-transform duration-300 transform hover:scale-105`}
            loading="lazy"
          />
        </LazyLoad>
      </div>

      <span className={`flex flex-col ${isCategory ? "flex-col" : ""}  mt-4`}>
        <p className="font-[500] text-[1rem] leading-[1.5rem] capitalize">
          {getProductByID(pId).title}
        </p>
        {showPrice && (
          <span className="flex gap-1 items-center">
            {typeof avgRating === "number" && (
              <Star style={{ fontSize: "15px", color: "gray" }} />
            )}
            <p className="font-[400] text-[0.8rem] leading-[1.5rem]">
              {roundToDecimal(avgRating, 1)} (
              {typeof bookingsCount === "number" ? bookingsCount : quotesCount}){" "}
              {typeof bookingsCount === "number" ? "Bookings" : "Quotes"}
            </p>
          </span>
        )}
        {showPrice && (
          <p className="font-[600] text-[0.8rem] leading-[1.5rem]">
            {isCategory && (
              <ArrowForward fontSize="small" sx={{ color: "black", mr: 1 }} />
            )}
            {unitPrice
              ? `₹ ${unitPrice?.value} ${getProductByID(pId).unit}`
              : `${quantity?.value} ${getProductByID(pId).unit}`}
          </p>
        )}
      </span>
    </Link>
  );
}
