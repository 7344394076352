import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { createOrder } from "./orders";
import { getQuoteById } from "../../utils/helper";
import { createPayment } from "./payments";
import { bookRFQ } from "./RFQs";
import { closeQuoteFormModal, openSnackbar } from "../store/modalsSlice";

export const postQuote = createAsyncThunk(
  "postQuote",
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const user = getState().auth.user;
      if (!data || !user) {
        return rejectWithValue("Something went wrong!");
      }
      const quote = {
        ...data,
        status: 0,
        sellerId: user.id,
        sellerName: user.name,
        createdAt: Timestamp.fromDate(new Date()),
      };
      const docRef = await addDoc(collection(db, "quotes"), quote);
      dispatch(openSnackbar({ message: "Quote Created", type: "success" }));
      dispatch(closeQuoteFormModal());
      return { ...quote, id: docRef.id };
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchRFQQuotes = createAsyncThunk(
  "fetchRFQQuotes",
  async (RFQId, { getState, rejectWithValue }) => {
    try {
      const q = query(collection(db, "quotes"), where("rfqId", "==", RFQId));
      const querySnapshot = await getDocs(q);
      const quotes = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) quotes.push({ ...doc.data(), id: doc.id });
      });
      return quotes;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const acceptQuote = createAsyncThunk(
  "acceptQuote",
  async (quoteId, { getState, rejectWithValue, dispatch }) => {
    try {
      const quote = getQuoteById(quoteId);
      const { user } = getState().auth;
      const { userRFQs } = getState().RFQs;
      const RFQ = userRFQs.find((r) => r.id === quote.rfqId);

      const payment = await dispatch(
        createPayment({
          totalAmount: Number(quote.quantity * quote.unitPrice),
          sellerId: quote.sellerId,
          buyerId: RFQ.buyerId,
        })
      ).unwrap();

      await dispatch(
        createOrder({
          pId: RFQ.pId,
          quantity: quote.quantity,
          destination: RFQ.destination,
          origin: quote.origin,
          buyerName: user.name,
          unitPrice: quote.unitPrice,
          tacType: quote.tacType,
          edd: quote.edd,
          paymentId: payment.id,
          sellerId: quote.sellerId,
          sellerName: quote.sellerName,
          buyerId: RFQ.buyerId,
        })
      ).unwrap();

      await dispatch(
        bookRFQ({ RFQId: RFQ.id, purchased: quote.quantity })
      ).unwrap();

      const docRef = doc(db, "quotes", quote.id);
      const data = {
        status: 1,
        updatedAt: Timestamp.fromDate(new Date()),
      };
      await updateDoc(docRef, data);
      dispatch(openSnackbar({ message: "Quote Accepted!", type: "success" }));
      return quoteId;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const rejectQuote = createAsyncThunk(
  "rejectQuote",
  async (quoteId, { getState, rejectWithValue }) => {
    try {
      const docRef = doc(db, "quotes", quoteId);
      const data = {
        status: -1,
        updatedAt: Timestamp.fromDate(new Date()),
      };
      await updateDoc(docRef, data);
      return quoteId;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchUserQuotes = createAsyncThunk(
  "fetchUserQuotes",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const user = getState().auth.user;
      const q = query(
        collection(db, "quotes"),
        where("sellerId", "==", user.id)
      );
      const querySnapshot = await getDocs(q);
      const quotes = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) quotes.push({ ...doc.data(), id: doc.id });
      });
      return quotes;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);
