import { createSlice } from "@reduxjs/toolkit";
import {
  deleteBooking,
  fetchUserBookings,
  postBooking,
  postPendingBooking,
} from "../api/bookings";

const initialState = {
  loading: false,
  userBookings: [],
  pendingBooking: null,
};

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    addPendingBooking: (state, action) => {
      if (action.payload) {
        state.pendingBooking = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBooking.fulfilled, (state, action) => {
        const bookingId = action.payload;
        const rest = state.userBookings.filter(
          (booking) => booking.id !== bookingId
        );
        state.userBookings = [...rest];
        state.loading = false;
      })
      .addCase(deleteBooking.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(postPendingBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(postPendingBooking.fulfilled, (state, action) => {
        state.userBookings = [...state.userBookings, action.payload];
        state.pendingBooking = null;
        state.loading = false;
      })
      .addCase(postPendingBooking.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(postBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(postBooking.fulfilled, (state, action) => {
        state.userBookings = [...state.userBookings, action.payload];
        state.loading = false;
      })
      .addCase(postBooking.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchUserBookings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserBookings.fulfilled, (state, action) => {
        state.userBookings = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchUserBookings.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { addPendingBooking } = bookingsSlice.actions;
export default bookingsSlice.reducer;
