import { useEffect, useMemo, useState } from "react";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { getProductByID } from "../../utils/helper";
import Pagination from "../../components/UIElements/Pagination";
import { PAYMENT_TERMS } from "../../constants/product";
import { getListing } from "../../redux/api/listings";

function Pair({ title, value, ext }) {
  return (
    <div className="flex flex-col items-start gap-2">
      <h3 className="text-sm text-gray-400">{title}</h3>
      <p className="text-md">
        {value} {ext ? " " + ext : ""}
      </p>
    </div>
  );
}

function Card({
  id,
  listingId,
  createdAt,
  quantity,
  edd,
  status,
  tacType,
  unitPrice,
}) {
  const [listing, setListing] = useState({});

  useEffect(() => {
    if (!listingId) return;
    getListing(listingId).then(setListing);
  }, [listingId]);

  const product = useMemo(() => {
    if (!listing?.pId) return {};
    return getProductByID(listing.pId);
  }, [listing]);

  return (
    <div
      className={`bg-white rounded-xl grid grid-cols-2 gap-4 w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 hover:shadow gap-3`}
    >
      <Pair title="Product" value={product.title} />
      <Pair title="Origin" value={listing?.origin?.addressLine} />
      <div className="border-t col-span-2" />
      <Pair title="Quantity" value={quantity} />
      <Pair title="Unit Price" value={unitPrice} />
      <Pair
        title="Expected Delivery Date"
        value={edd.toDate().toLocaleDateString()}
      />
      <Pair title="Status" value={status ? "Accepted" : "Pending"} />
      <Pair title="Payment Terms" value={PAYMENT_TERMS[tacType]} />
      <Pair
        title="Created At"
        value={createdAt.toDate().toLocaleDateString()}
      />
    </div>
  );
}

const PAGE_ITEM_COUNT = 5;
const FILTERS = ["all", "pending", "accepted"];

export default function Offers() {
  const { userOffers } = useSelector((s) => s.offers);
  const [filter, setFilter] = useState(FILTERS[0]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const offers = userOffers.filter((offer) => {
      if (filter === "pending") {
        return offer.status === 0;
      } else if (filter === "accepted") {
        return offer.status === 1;
      }
      return true;
    });
    setFilteredOffers([...offers]);
  }, [filter, userOffers]);

  return (
    <div className="h-full bg-gray-50">
      <div className="flex flex-wrap items-center gap-4 bg-white py-2 px-3">
        {FILTERS.map((fName) => {
          return (
            <button
              key={fName}
              onClick={() => setFilter(fName)}
              className={`capitalize inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm transition-all duration-200 ease-in-out ${
                filter === fName
                  ? "text-[#1D523B] border-b-[#1D523B] font-semibold"
                  : "text-gray-600 hover:border-b-[#1D523B] hover:text-[#1D523B]"
              }`}
            >
              {fName}
            </button>
          );
        })}
      </div>
      <div className="p-8 grid grid-cols-2 gap-4">
        {filteredOffers.length ? (
          filteredOffers
            .slice(
              (currentPage - 1) * PAGE_ITEM_COUNT,
              currentPage * PAGE_ITEM_COUNT
            )
            .map((offer) => {
              return <Card key={offer.id} {...offer} />;
            })
        ) : (
          <h4 className="text-xl">No Offers Found.</h4>
        )}
      </div>
      {!!filteredOffers.length && filteredOffers.length > PAGE_ITEM_COUNT && (
        <div className="p-8">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={filteredOffers.length / PAGE_ITEM_COUNT}
          />
        </div>
      )}
    </div>
  );
}
