import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  or,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { closeDispatchFormModal, openSnackbar } from "../store/modalsSlice";

export const createOrder = createAsyncThunk(
  "createOrder",
  async (orderData, { rejectWithValue }) => {
    try {
      if (!orderData) return rejectWithValue("No Phone Number");
      const data = {
        ...orderData,
        status: 0,
        dispatchedQty: 0,
        createdAt: Timestamp.fromDate(new Date()),
      };
      const docRef = await addDoc(collection(db, "orders"), data);
      return { id: docRef.id, ...data };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const completedOrder = createAsyncThunk(
  "completedOrder",
  async (orderId, { rejectWithValue, dispatch }) => {
    try {
      if (!orderId) return rejectWithValue("Order Id required!");
      const data = {
        status: 1,
        updatedAt: Timestamp.fromDate(new Date()),
      };
      await updateDoc(doc(db, "orders", orderId), data);
      dispatch(openSnackbar({ message: "Order Completed", type: "success" }));
      return orderId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUserOrders = createAsyncThunk(
  "fetchUserOrders",
  async (orderData, { getState, rejectWithValue }) => {
    try {
      const user = getState().auth.user;
      const q = query(
        collection(db, "orders"),
        or(where("buyerId", "==", user.id), where("sellerId", "==", user.id))
      );
      const querySnapshot = await getDocs(q);
      const orders = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) orders.push({ ...doc.data(), id: doc.id });
      });
      return orders;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchOrderDispatches = createAsyncThunk(
  "fetchOrderDispatches",
  async (orderId, { rejectWithValue }) => {
    try {
      const q = query(collection(db, "orders", orderId, "dispatches"));
      const querySnapshot = await getDocs(q);
      const dispatches = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) dispatches.push({ ...doc.data(), id: doc.id });
      });
      return { orderId, dispatches };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadEwayBill = async (file, dispatchId) => {
  const storage = getStorage();
  const storageRef = ref(storage, `dispatches/${dispatchId}/ewayBill`);
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
};

export const uploadInvoice = async (file, dispatchId) => {
  const storage = getStorage();
  const storageRef = ref(storage, `dispatches/${dispatchId}/invoice`);
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
};

export const createDispatch = createAsyncThunk(
  "createDispatch",
  async ({ id, dispatchData }, { getState, rejectWithValue, dispatch }) => {
    try {
      if (!dispatchData) return rejectWithValue("No data");
      const { orderId } = getState().modals.dispatchFormModal;
      const data = {
        ...dispatchData,
        status: 0,
        createdAt: Timestamp.fromDate(new Date()),
      };
      await setDoc(doc(db, "orders", orderId, "dispatches", id), data);
      await updateDoc(doc(db, "orders", orderId), {
        dispatchedQty: increment(Number(dispatchData.quantity)),
      });
      dispatch(closeDispatchFormModal());
      dispatch(openSnackbar({ message: "Dispatch Created", type: "Success" }));
      return { orderId, dispatch: { id, ...data } };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
