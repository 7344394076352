import React, { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import Input from "../../components/UIElements/Input";
import Button from "../../components/UIElements/Button";

function Pair({ title, value, href }) {
  return (
    <span className="text-[0.9rem] text-black flex gap-2 mt-4">
      <p className="font-semibold">{title}:</p>
      <a href={href} className="text-opacity-60">
        {value}
      </a>
    </span>
  );
}

const INITIAL_FORM = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  address: "",
  message: "",
};

export default function ContactForm() {
  const [form, setForm] = useState(INITIAL_FORM);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleInputChange({ target }) {
    const { name, value } = target;
    setForm((p) => ({ ...p, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const { error } = {};
    if (error) setError(error);
    else {
      setError("Query Send");
      setForm({ ...INITIAL_FORM });
    }
    setLoading(false);
  }

  return (
    <section className="w-full md:px-24 px-4 my-16">
      <div className="flex md:justify-between justify-center items-center border-b-[1px] border-black w-full py-4 px-2">
        <span className="w-[40%] md:block hidden">
          <h2 className="text-black font-semibold text-[0.9rem] leading-[1.3rem]">
            Join
          </h2>
          <p className="text-black text-[0.9rem] leading-[1.3rem] text-opacity-50">
            FuelCab India
          </p>
        </span>
        <h1 className="w-[60%] text-[#0d2620] md:mb-0 mb-4 font-[500] text-[3rem] leading-[1.3rem]">
          Contact Us
        </h1>
      </div>
      <div className="flex w-full mt-10">
        <div className="w-[40%] md:block hidden">
          <span className="text-black text-[2rem] w-auto">Address.</span>
          <p className="text-[0.9rem] mt-8 text-black font-semibold">
            Head Office
          </p>
          <Pair title="Address Line" value="Groud floor 272, Green park" />
          <Pair title="City" value="Rampur, Urban Roorkee, Haridwar" />
          <Pair title="State" value="Uttarakhand - 247667" />
          <Pair
            title="Telephone"
            value="+91 9988909052"
            href="tel:+91 9988909052"
          />
          <p className="text-[0.9rem] mt-8 text-black font-semibold">
            Branch Office
          </p>
          <Pair
            title="Address Line"
            value="GNEC - IIT Roorkee, Knowledge Park 2"
          />
          <Pair title="City" value="Greater Noida" />
          <Pair title="State" value="Uttar Pradesh - 201310" />
          <Pair
            title="Telephone"
            value="+91 9988909052"
            href="tel:+91 9988909052"
          />
        </div>
        <div className="md:w-[57%] w-auto">
          <h2 className="text-[#0D2620] md:text-[2.5rem] text-[2rem] leading-[2.4rem] tracking-[1.5px] font-[500]">
            Resolve with problem.
          </h2>
          <p className="mt-6 text-black text-[1rem] leading-[1.5rem]">
            After contact, support team with connect you to listen your quiries
            and resolve or may escalate according to the talk.
          </p>
          <form className="grid grid-cols-2 gap-4 mt-8" onSubmit={handleSubmit}>
            <Input
              label="First Name"
              required
              type="text"
              name="firstName"
              value={form.firstName}
              onChange={handleInputChange}
              placeholder="Enter your first name"
            />
            <Input
              label="Last Name"
              required
              type="text"
              name="lastName"
              value={form.lastName}
              onChange={handleInputChange}
              placeholder="Enter your last name"
            />
            <Input
              label="Email"
              required
              type="email"
              name="email"
              value={form.email}
              onChange={handleInputChange}
              placeholder="Enter your email"
            />
            <Input
              label="Phone Number"
              requiredm
              type="text"
              name="phoneNumber"
              value={form.phoneNumber}
              onChange={handleInputChange}
              placeholder="Enter your phone number"
            />
            <span className="mt-2 col-span-2">
              <label
                htmlFor="partnership-message"
                className={`text-sm leading-[18px] font-[500] text-[#0D2620] tracking-[.05em]`}
              >
                Message
              </label>
              <textarea
                required
                id="partnership-message"
                name="message"
                placeholder="Write your message..."
                rows="4"
                cols="50"
                className="py-4 text-black h-ful text-sm w-full px-[21px] border rounded-xl outline-none"
              />
            </span>
            <Button loading={loading}>Send</Button>
          </form>
        </div>
      </div>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={2000}
        onClose={() => {
          setError(null);
        }}
      >
        <Alert
          severity={error === "Query Send" ? "success" : "error"}
          onClose={() => setError(null)}
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </section>
  );
}
