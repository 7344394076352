import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import "./BlogDetail.css";

const Meta = ({ title, endpoint }) => {
  return (
    <Helmet>
      <title>{title} - FuelCab India</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com/blog/${endpoint}",
            name: "FuelCab India",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

export default function BlogDetail() {
  const location = useLocation();
  const blog = location.state;
  const lastHeadingRef = useRef(null);

  const handleSubheadingClick = (e) => {
    const source = e.target.id;
    const target = source + "x";
    const xyz = document.getElementById(target);
    if (xyz) {
      xyz.scrollIntoView({ behavior: "smooth" });
    }
    setTimeout(() => {
      window.scrollBy(0, -100);
    }, 750);
  };

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const extractHeadings = (description) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = description;
    const headingElements = tempElement.querySelectorAll(
      "h1, h2, h3, h4, h5, h6"
    );
    return Array.from(headingElements).map((heading, index) => ({
      id: `heading${index + 1}`,
      text: heading.textContent,
    }));
  };

  const headings = extractHeadings(blog.description);

  return (
    <>
      <Meta title={blog.title} endpoint={location.pathname.split("/")[2]} />
      <Navbar />
      <div className="font-dmsans px-12">
        <div className="relative">
          <img
            src={blog.imageUrl}
            alt={blog.title}
            className="rounded-xl w-full h-[75vh] object-cover"
          />
          <h1 className="text-6xl absolute top-[40%] w-full text-center text-white">
            {blog.title}
          </h1>
        </div>
        <div className="blog-detail-page">
          <div className="blog-container">
            <div className="sidebar" style={{ textAlign: "justify" }}>
              <div className="sidebarinside">
                <h2>Headings</h2>
                {headings.map((heading, index) => (
                  <a
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubheadingClick(e);
                    }}
                  >
                    <h3
                      id={heading.id}
                      className="text-md !font-[400] text-gray-500 hover:underline cursor-pointer"
                    >
                      {heading.text}
                    </h3>
                  </a>
                ))}
              </div>
            </div>
            <div className="blog-description-container">
              <div className="blog-description">
                {parse(blog.description)}
                <div ref={lastHeadingRef}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
