import { createSlice } from "@reduxjs/toolkit";
import {
  deleteListing,
  fetchFCIListings,
  fetchFeturedListings,
  fetchGasListings,
  fetchLiquidListings,
  fetchNearByListings,
  fetchNewListings,
  fetchSolidListings,
  fetchTrendingListings,
  fetchUserListings,
  postListing,
  removeListingImage,
  updateListing,
  bookListing,
} from "../api/listings";

const initialState = {
  loading: false,
  userListings: [],
  nearbyListings: [],
  trendingListings: [],
  newListings: [],
  solidListings: [],
  liquidListings: [],
  gasListings: [],
  FCIListings: [],
  featuredListings: [],
  all: [],
};

export const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Existing cases
    builder
      .addCase(fetchFCIListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFCIListings.fulfilled, (state, action) => {
        state.FCIListings = action.payload;
        state.loading = false;
      })
      .addCase(fetchFCIListings.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchFeturedListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFeturedListings.fulfilled, (state, action) => {
        state.featuredListings = action.payload;
        state.loading = false;
      })
      .addCase(fetchFeturedListings.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchGasListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGasListings.fulfilled, (state, action) => {
        state.gasListings = action.payload;
        state.loading = false;
      })
      .addCase(fetchGasListings.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchLiquidListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLiquidListings.fulfilled, (state, action) => {
        state.liquidListings = action.payload;
        state.loading = false;
      })
      .addCase(fetchLiquidListings.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchSolidListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSolidListings.fulfilled, (state, action) => {
        state.solidListings = action.payload;
        state.loading = false;
      })
      .addCase(fetchSolidListings.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchNearByListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNearByListings.fulfilled, (state, action) => {
        state.nearbyListings = action.payload;
        state.loading = false;
      })
      .addCase(fetchNearByListings.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchTrendingListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrendingListings.fulfilled, (state, action) => {
        state.trendingListings = action.payload;
        state.loading = false;
      })
      .addCase(fetchTrendingListings.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchNewListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewListings.fulfilled, (state, action) => {
        state.newListings = action.payload;
        state.loading = false;
      })
      .addCase(fetchNewListings.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(postListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(postListing.fulfilled, (state, action) => {
        state.userListings = [...state.userListings, action.payload];
        state.loading = false;
      })
      .addCase(postListing.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(fetchUserListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserListings.fulfilled, (state, action) => {
        state.userListings = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserListings.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(deleteListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteListing.fulfilled, (state, action) => {
        const listingId = action.payload;
        const rest = state.userListings.filter((l) => l.id !== listingId);
        state.userListings = [...rest];
        state.loading = false;
      })
      .addCase(deleteListing.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(removeListingImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeListingImage.fulfilled, (state, action) => {
        const { listingId, imageIdx } = action.payload;
        const listing = state.userListings.find((l) => l.id === listingId);
        const rest = state.userListings.filter((l) => l.id !== listingId);
        const updatedListing = {
          ...listing,
          imagesURL: listing.imagesURL.filter((url) => {
            return !`${url.split("%2F")[2]}`.includes(imageIdx);
          }),
        };
        state.userListings = [...rest, updatedListing];
        state.loading = false;
      })
      .addCase(removeListingImage.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(updateListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateListing.fulfilled, (state, action) => {
        state.loading = false;
        const listing = action.payload;
        if (!listing) return;
        const isAvailable = state.userListings.find((l) => l.id === listing.id);
        if (isAvailable) {
          const rest = state.userListings.filter((l) => l.id !== listing.id);
          state.userListings = [...rest, listing];
        } else {
          state.userListings = [...state.userListings, listing];
        }
      })
      .addCase(updateListing.rejected, (state) => {
        state.loading = false;
      });

    //  bookListing cases
    builder
      .addCase(bookListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(bookListing.fulfilled, (state, action) => {
        state.loading = false;
        const { listingId, purchased } = action.payload;
        const listing = state.userListings.find((l) => l.id === listingId);
        if (listing) {
          const updatedListing = {
            ...listing,
            bookingsCount: (listing.bookingsCount || 0) + 1,
            quantity: {
              ...listing.quantity,
              purchased,
            },
          };
          const rest = state.userListings.filter((l) => l.id !== listingId);
          state.userListings = [...rest, updatedListing];
        }
      })
      .addCase(bookListing.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = listingsSlice.actions;
export default listingsSlice.reducer;
