import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  logout,
  fetchUserMetaDeta,
  register,
  refreshUser,
  updateUser,
  changePassword,
  updateUserProfilePicture,
} from "../api/auth";
import { getUserFormPercentage } from "../../utils/helper";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    isLoaded: false, // initial auth load
    profilePercentage: 0, // percentage of profile data completed
    error: false,
  },
  reducers: {
    clearAuthError: (state) => {
      state.error = false;
      state.loading = false;
    },
    updateWalletAmount: (state, { payload: { newAmount } }) => {
      state.user.walletAmount = newAmount;
    },
    setUserType: (state, { payload }) => {
      state.user.userType = payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMetaDeta.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserMetaDeta.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.user = action.payload;
        state.profilePercentage = getUserFormPercentage(action.payload);
        state.loading = false;
      })
      .addCase(fetchUserMetaDeta.rejected, (state, action) => {
        state.isLoaded = true;
        state.error = action.payload;
        state.loading = false;
      });
    builder
      .addCase(refreshUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = { ...state.user, ...action.payload };
        state.profilePercentage = getUserFormPercentage({
          ...state.user,
          ...action.payload,
        });
      })
      .addCase(refreshUser.rejected, (state, action) => {
        state.loading = false;
      });
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.profilePercentage = getUserFormPercentage(action.payload);
        state.isLoaded = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isLoaded = true;
      });
    builder
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.profilePercentage = 0;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.profilePercentage = getUserFormPercentage(action.payload);
        state.isLoaded = true;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isLoaded = true;
      });
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        if (action.payload) state.user = { ...state.user, ...action.payload };
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
    builder
      .addCase(updateUserProfilePicture.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserProfilePicture.fulfilled, (state, action) => {
        state.loading = false;
        state.user.profileURL = action.payload;
      })
      .addCase(updateUserProfilePicture.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearAuthError, updateWalletAmount, setUserType, setUser } =
  authSlice.actions;
export default authSlice.reducer;
