import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Meta = () => {
  return (
    <Helmet>
      <title>Privacy Policy - FuelCab India</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com/privacy",
            name: "FuelCab",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

function Privacy() {
  return (
    <>
      <Meta />
      <Navbar />
      <section className="font-dmsans w-full bg-[#F7F8FA] pb-7">
        <div className="w-full text-center md:px-[32px] px-6 py-12 border border-y-neutral-300">
          <h1 className="text-[1.75rem] font-semibold leading-[36px] text-[#424242]">
            PRIVACY POLICES
          </h1>
        </div>
        <div className="md:mx-[10rem] mx-6 my-7 bg-white border border-neutral-300">
          <div className="py-14">
            <h3 className="text-center">Our Privacy Policies</h3>
            <h4 className="text-center font-semibold pt-4">
              Last updated: 05<sup>th</sup> March 2024
            </h4>
          </div>
          <section className="flex justify-start items-start">
            <div className="md:px-16 px-4">
              <div className="w-full flex  flex-col items-start">
                <div className="my-2 w-full h-full flex flex-col">
                  <div className="w-full h-full gap-16 items-center flex">
                    <div className="flex flex-col  items-start">
                      <p>
                        FuelCab recognizes the importance of maintaining the
                        privacy of the information belonging to you. This Policy
                        sets out details of how we treat user information we
                        collect on fuelcab.com the “Website”. The Website is
                        owned and operated by Triple S fuelcab Business
                        Solutions LLP, a limited liability partnership firm
                        registered under the Limited Liability Partnership Act,
                        2008 having its registered office at Ground Floor 272,
                        Green Park Rampur Chungi, Rampur Urban Roorkee, Haridwar
                        Uttarakhand-247687.
                      </p>
                      <p className=" my-2">
                        By visiting and/or using the Website, you agree to the
                        terms of this Privacy Policy and it shall be deemed that
                        you have read in full, accepted, and agreed to the terms
                        herein. FuelCab reserves the right to modify the terms
                        contained in this Privacy Policy at any time by posting
                        such modifications on the Website. The modified Privacy
                        Policy would be effective from the date of modification
                        that is posted by FuelCab on the Website. If you do not
                        agree to any term that is contained in this Privacy
                        Policy, please immediately cease your use of the
                        Website.
                      </p>
                      <p className=" my-2">
                        This Privacy Policy is issued pursuant to the provisions
                        that are contained in (a) Section 43A of the Information
                        Technology Act, 2000; (b) Regulation 4 of the
                        Information Technology ((Reasonable Security Practices
                        and Procedures and Sensitive Personal Data or
                        Information) Rules, 2011; and (c) Regulation 3(1) of the
                        Information Technology (Intermediaries Guidelines)
                        Rules, 2011.
                      </p>
                      <p className=" my-2">
                        Information that is collected by FuelCab on the Website
                        shall be used by FuelCab only for the purpose of
                        providing the Services, and such information shall not
                        be used, sold, transferred, or rented in a manner that
                        is not set out in this Privacy Policy.
                      </p>
                    </div>
                  </div>

                  <div className="flex  gap-16 items-center">
                    <div className=" w-full">
                      <h2 className=" font-[700]">
                        Information that is collected by FuelCab:
                      </h2>
                      <ul className="my-2">1. Name</ul>
                      <ul className="my-2">2. Email address</ul>
                      <ul className="my-2">3. Mobile/Phone number/s</ul>
                      <ul className="my-2">
                        4. Address (resenditial/commercial)
                      </ul>
                      <ul className="my-2">5. Location details </ul>
                      <ul className="my-2">6. IP address</ul>
                      <ul className="my-2">7. Browser details</ul>

                      <p className=" my-2">
                        In providing the various services that are available on
                        the Website (the “Services”), FuelCab may have to
                        collect certain personal information from you. This
                        information would, inter alia, include the following:
                      </p>
                    </div>
                  </div>

                  <div>
                    <p className=" my-2">
                      Contact information: FuelCab may collect your name, email,
                      mobile number, postal address, IP address, etc.
                    </p>

                    <p className=" my-2">
                      Payment and billing information. FuelCab may collect your
                      billing name, billing address, and payment method when you
                      pay the Charges (as defined in the Terms of Use available
                      here https://fuelcab.com/terms-and-conditions ) on the
                      Website. Please note that FuelCab’s online payment partner
                      would collect your credit/debit card number or
                      credit/debit card expiry date or other details pertaining
                      to your credit/debit card.
                    </p>

                    <p>
                      Other information. While you use the Website, FuelCab may
                      collect information about your IP address and the browser
                      you are using. You acknowledge and agree that any and all
                      information that is provided by you on the Website is
                      provided by you voluntarily and at your own risk. While
                      FuelCab would make all efforts to ensure the safety and
                      security of the information provided for you, it shall not
                      be responsible for any loss or theft of data. Please note
                      that any information that is provided by you may be
                      retained by FuelCab for (i) providing the Services, and
                      (ii) for meeting its obligations under applicable law.
                      Even if you delete your User account on the Website,
                      FuelCab may have to retain the information provided by you
                      for a short period of time until the same can be removed
                      from its servers, and for compliance with its obligations
                      under the law (if any). You shall be responsible for the
                      accuracy of the information provided to FuelCab for the
                      use of the Website and the Services. If there is any
                      mistake in or change to the personal information provided
                      by you for the use of the Website, you shall immediately
                      notify FuelCab at info@fuelcab.com. You shall be solely
                      liable for the consequences of providing incomplete,
                      inaccurate, or incorrect information on the Website. Where
                      FuelCab has reasons to believe that the information
                      provided by you on the Website is incorrect or incomplete,
                      FuelCab may restrict you from using the Website by
                      disabling your User Account. If you want to discontinue
                      the use of the Website, please notify FuelCab at
                      info@fuelcab.com. Once you discontinue the use of the
                      Website, FuelCab shall retain the personal information
                      provided by you on the Website for such a time period as
                      may be mandated by applicable law.
                    </p>
                  </div>

                  <div className="flex  gap-16  items-center">
                    <div className=" w-full">
                      <h2 className=" mt-4  font-[700]">
                        Information may be collected in several ways:
                      </h2>
                      <p className=" my-2">
                        Active collection: This refers to the information that
                        is actively provided by you while registering on the
                        Website while placing an order for the procurement of
                        the Product and so on etc. Passive Collection: This
                        refers to the information that is gathered by FuelCab
                        using tracking tools like Google Analytics, Google
                        Webmaster, browser cookies, etc. for collecting
                        information about your usage of our website. This
                        information may be used by FuelCab for internal research
                        to understand User demographics, interests, and behavior
                        with a view to improving the functionality of the
                        Website. This information may include the URL of the
                        website that you visited just before visiting the
                        Website or which one you go to after visiting the
                        Website, your computer browser information, and your IP
                        address. While FuelCab does not actively collect any
                        information from persons who visit the Website but do
                        not register themselves on it or avail of the Services,
                        certain portions of this Privacy Policy would be
                        applicable to such visitors as well.
                      </p>
                    </div>
                  </div>

                  <div className="flex   gap-16  items-center">
                    <div className=" w-full ">
                      <h2 className=" font-[700] my-4">
                        Use of your personal information by FuelCab:
                      </h2>
                      <p className=" my-2">
                        TO CONTACT YOU: The contact information you provide
                        would be used by FuelCab to contact you for the
                        confirmation of an order or for any other Service. To
                        improve the quality of the Services: FuelCab might use
                        your information to improve the functionality of the
                        Services and to ensure that its quality is
                        maintained/bettered. For security purposes: FuelCab
                        might use the information provided by you on the Website
                        to protect our company, our customers, or our websites.
                        For marketing purposes: FuelCab might send you
                        information about special promotions or offers. FuelCab
                        might also notify you of new or improved features on the
                        Website. Transactional communications: FuelCab may also
                        contact you by way of emails or SMSs to confirm an
                        order, confirm the payment of Charges, etc. FuelCab
                        shall use your information only in a manner that is
                        permitted by applicable law.
                      </p>
                    </div>
                  </div>

                  <div className="flex  gap-16  items-center">
                    <div className=" w-full">
                      <h2 className=" font-[700] my-4">
                        Sharing of information by FuelCab with third parties:
                      </h2>
                      <p className=" my-2">
                        Service Providers: FuelCab shall share your information
                        with persons who FuelCab has contracted for assistance
                        with performing the Services such as payment processors
                        or transactional message processors. Compliance with
                        law: FuelCab might share your information to respond to
                        a court order or subpoena. FuelCab may share your
                        information if a government agency or investigatory body
                        requests FuelCab to do so. Mergers and Acquisitions: If
                        FuelCab sells its business or hives of a portion of the
                        same to a new entity, your personal information that is
                        available on the Website may be transferred to the new
                        entity.
                      </p>
                    </div>
                  </div>

                  <div className="flex  gap-16  items-center">
                    <div className=" w-full ">
                      <h2 className=" my-4 font-[700]">
                        SECURITY OF YOUR INFORMATION:
                      </h2>
                      <p className=" my-2">
                        Please note that neither FuelCab nor any of its
                        employees would have access to the password that relates
                        to your User Account on the Website. You shall be solely
                        responsible for protecting your login credentials to the
                        Website, and FuelCab shall not be liable in any manner
                        for unauthorized use of your login credentials. You
                        shall be liable to FuelCab for any loss suffered by
                        FuelCab as a result of unauthorized use of your login
                        credentials. In the event of any misuse or suspected
                        misuse of the same, please notify FuelCab at
                        info@fuelcab.com. While FuelCab shall seek to implement
                        the best market practices and security policies for the
                        protection of your personal information, it shall not be
                        liable for theft or loss of data due to unauthorized
                        access to the User’s electronic devices. Cookies: A
                        cookie is a small file that asks permission to be placed
                        on your computer’s hard drive. Once you grant the
                        permission, the file is added and the cookie helps
                        analyze web traffic or lets you know when you visit a
                        particular site. FuelCab uses traffic log cookies to
                        identify which pages are being used. This helps FuelCab
                        analyze data about web page traffic and improve the
                        functioning of the Website. This information is used by
                        FuelCab only for statistical analysis and then the data
                        is removed from the system. Please note that FuelCab
                        does not have any control over the cookies that are
                        placed by third-party links on the Website. FuelCab does
                        not represent to you that such third-party websites have
                        their own Privacy Policies or that the information that
                        is provided by you to them would be secure.
                      </p>
                    </div>
                  </div>

                  <div className="flex   gap-16  items-center">
                    <div className=" w-full">
                      <h2 className=" mb-4  font-[700] scroll-m-[15vw]">
                        THIRD-PARTY SITES
                      </h2>
                      <p className=" my-2">
                        If you click on one of the links to third-party websites
                        that may be available on the Website, you may be taken
                        to websites that FuelCab does not control. This Privacy
                        Policy does not apply to the privacy practices of those
                        websites. FuelCab shall not be liable for the misuse of
                        your personal information by third-party websites.
                      </p>
                    </div>
                  </div>

                  <div className="flex  gap-16  items-center">
                    <div className=" w-full ">
                      <h2 className=" font-[700] my-4">
                        MISCELLANEOUS PROVISIONS
                      </h2>
                      <p className=" my-2">
                        Force Majeure: FuelCab shall not responsible for any
                        cessation, interruption, or delay in the performance of
                        its obligations hereunder due to earthquake, flood,
                        fire, storm, natural disaster, the act of God, war,
                        terrorism, armed conflict, labor strike, lockout, or
                        boycott. FuelCab may at any time, in its sole discretion
                        and without advance notice to you, cease operation of
                        the Website and the provision of the Services. No
                        Waiver: A delay or failure by FuelCab to exercise or
                        enforce any right or provision of this Privacy Policy
                        will not constitute a waiver of such right or provision.
                        Notices: All notices given to a User by FuelCab or by a
                        User to FuelCab or another User shall be in writing and
                        in the English language. Such notice shall be sent by
                        e-mail or mailed by a prepaid internationally-recognized
                        courier service to the intended recipient at the address
                        set out below, or any changed address that is notified
                        by either Party: Notice to FuelCab: Triple S fuelcab
                        Business Solutions LLP, Ground Floor 272, Green Park
                        Rampur Chungi, Rampur Urban Roorkee, Haridwar
                        Uttarakhand-247687. Email: info@fuelcab.com Notice to
                        User: At the email address provided by you at the time
                        of registration on the Website.
                      </p>
                    </div>
                  </div>

                  <div className="mt-12 text-xl">
                    <p className=" my-2">
                      This Website originates from the State of Uttarakhand in
                      India. This Privacy Policy will be governed by the laws
                      that are applicable in the State of Uttarakhand. By using
                      this Website, you consent to the jurisdiction and venue of
                      the courts located in Uttarakhand, India in connection
                      with any action, suit, proceeding, or claim arising under
                      or by reason of this Privacy Policy.
                    </p>
                  </div>
                  <div className="flex  gap-16  items-center">
                    <div className=" w-full">
                      <h1 className=" my-6 font-[700]">
                        Governing Law Jurisdiction: This Privacy Policy is
                        governed by the provisions of Indian law, including but
                        not limited to the following:
                      </h1>
                      <ul className="">1. the Indian Contract Act, 1872;</ul>
                      <ul className="">
                        2. the (Indian) Information Technology Act, 2000;
                      </ul>
                      <ul className="">
                        3. the (Indian) Information Technology (Reasonable
                        Security Practices and Procedures and Sensitive Personal
                        Data or Information) Rules, 2011; and
                      </ul>
                      <ul className="">
                        4. the (Indian) Information Technology (Intermediaries
                        Guidelines) Rules, 2011.
                      </ul>
                    </div>
                  </div>
                  <div className="flex  gap-16  items-center">
                    <div className=" w-full ">
                      <h2 className=" my-4 font-[700]">
                        REFUND AND CANCELLATION POLICY
                      </h2>
                      <p className="">
                        Once we receive your refund request for cancellation and
                        refund, we will scrutinize and evaluate the transaction,
                        after approval refund will initiate. The refund amount
                        will be calculated by deducting Internet payment charges
                        from your original payment.
                      </p>
                      <p className=" my-2">
                        You will receive the credit within a certain amount of
                        days, depending on your card issuer's policies. Refunds
                        will be issued to the same payment method which was used
                        at the time of purchase.
                      </p>
                      <p className=" my-2">
                        Debit card/ Credit Card – 5 to 7 working days Net
                        Banking – 3 to 5 working days If the standard time-frame
                        as mentioned above has passed and you have still not
                        received the refund, please contact your credit or debit
                        card issuer or your bank for more information.
                      </p>
                      <p className=" my-2">
                        If you have any questions about our Returns and Refunds
                        Policy, please contact us by email at info@fuelcab.com
                        or call us on +91-9988909052.
                      </p>
                    </div>
                  </div>

                  <div className="flex  gap-16 items-center pb-12">
                    <div className=" w-full">
                      <h2 className=" my-4 font-[700]">Cancellation Policy</h2>
                      <p className=" my-2">
                        In case the Order is canceled before dispatch, no
                        cancellation charges shall be leviable but in case the
                        same is canceled thereafter then a cancellation fee
                        shall be payable by the end customer to the Dealer. In
                        case post confirmation of the order, the dealer fails to
                        show up and deliver the product, then FuelCab shall not
                        be responsible to either party for cancellation of the
                        order or its supply in any eventuality. If during the
                        process of delivery, the dealer, its agent or
                        representative fails to comply with or adhere to any of
                        the applicable rules, etc then the end customer shall be
                        within its right to cancel the order immediately without
                        any further charges, except to pay for the product
                        already received by the end customer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Privacy;
