export const PAYMENT_TERMS = [
  "100% before dispatch of material again proforma invoice",
  "50% before dispatch of materials against proforma invoice and balance 50% at time of dispatch against Invoice & Eway bill",
  "50% before dispatch of materials against proforma invoice and balance 50% on delivery of the material",
  "50% before dispatch of material against Invoice and balance 50% on delivery of the material",
  "100% on delivery of the material",
];

export const FREQUENCIES = ["Once", "Monthly"];
export const CHARGE_TYPES = ["Included", "Extra", "Not applicable"];

export const PRODUCT_UNIT = [
  "Bottle/Bottles",
  "Container/Containers",
  "Bale",
  "Billions Of Unit",
  "Buckle",
  "Bunch",
  "Bundle",
  "Dozen",
  "Great Gross",
  "Gross",
  "Gross Yard",
  "Kiloliter",
  "Metric Ton",
  "Number",
  "Quintal",
  "Meter",
  "Tablet",
  "Ten Gross",
  "Thousand",
  "Tube",
  "US Gallon",
  "Yard",
  "Kit/Kits",
  "Vial/Vials",
  "Strip/Strips",
  "Foot/Feet",
  "Meter/Meters",
  "Millimeter/Millimeters",
  "Pole/Poles",
  "Twenty-Foot Container",
  "Short Ton/Short Tons",
  "Cubic Foot/Cubic Feet",
  "Cubic Meter/Cubic Meters",
  "Cubic Yard/Cubic Yards",
  "Dram/Drams",
  "Gill/Gills",
  "Milliliter/Milliliters",
  "Bushel/Bushels",
  "Pound/Pounds",
  "Square Yard/Square Yards",
  "Acre/Acres",
  "Ampere/Amperes",
  "Kiloampere/Kiloamperes",
  "Kiloohm/Kiloohms",
  "Kilovolt/Kilovolts",
  "Kilowatt/Kilowatts",
  "Milliampere/Milliamperes",
  "Milliohm/Milliohm,",
  "Millivolt/Millivolts",
  "Milliwatt/Milliwatts",
  "Ohm/Ohms",
  "Kilohertz",
  "Megahertz",
  "Quart/Quarts",
  "Barrel/Barrels",
  "Fluid Ounce/Fluid Ounces",
  "Gallon/Gallons",
  "Liter/Liters",
  "Gram/Grams",
  "Kilograms/Kilograms",
  "Long Ton/Long Tons",
  "Metric Ton/Metric Tons",
  "Milligram/Milligrams",
  "Stone/Stones",
  "Ton/Tons",
  "Tonne/Tonnes",
  "Unit/Units",
  "Volt/Volts",
  "Watt/Watts",
  "Wp",
  "Hertz",
  "Milihertz",
  "Centimeter/Centimeters",
  "Chain/Chains",
  "Forty-Foot Container",
  "Furlong/Furlongs",
  "Inch/Inches",
  "Yard/Yards",
  "Carat/Carats",
  "Ounce/Ounces",
  "Bag/Bags",
  "Box/Boxes",
  "Carton/Cartons",
  "Case/Cases",
  "Pack/Packs",
  "Pair/Pairs",
  "Pallet/Pallets",
  "Parcel/Parcels",
  "Perch/Perches",
  "Piece/Pieces",
  "Paint/Paints",
  "Plant/Plants",
  "Rod/Rods",
  "Roll/Rolls",
  "Set/Sets",
  "Sheet/Sheets",
  "Strand/Strands",
  "Hectare/Hectares",
  "Square Centimeters",
  "Square Foot/Square Foots",
  "Square Inch/Square Inches",
  "Square Meter/Square Meters",
  "Square Mile/Square Miles",
  "Gross",
  "Dozen/Dozens",
  "Tray/Trays",
  "Grain/Grains",
  "Quarter/Quarters",
  "Degrees Celsius",
  "Degrees Fahrenheit",
  "Kilometer/Kilometers",
  "Mile/Miles",
  "Nautical Mile/Nautical Miles",
  "Cubic Centimeters",
];

export const PRODUCTS = {
  solid: [
    {
      title: "Animal Tallow",
      desc: "A rendered form of animal fat used in the production of various products, including soap, candles, cosmetics, and lubricants",
      id: "animal_tallow",
      unit: "ton",
    },
    {
      title: "Chicken Tallow",
      desc: "A rendered form of chicken fat that is commonly used in cooking, as well as in the production of various products, including soap, candles, and cosmetics",
      id: "chicken_tallow",
      unit: "ton",
    },
    {
      title: "Palm Stearin",
      desc: "A solid fraction of palm oil commonly used in the food industry for its high melting point and semi-solid texture",
      id: "palm_stearin",
      unit: "ton",
    },
    {
      title: "PP/HDPE Waste",
      desc: "Highly recyclable discarded materials made of Polypropylene (PP) and High-Density Polyethylene (HDPE) plastics",
      id: "pp_hdpe_waste",
      unit: "ton",
    },
    {
      title: "LDP/MHW",
      desc: "Solid Fuel",
      id: "ldp_mhw",
      unit: "ton",
    },
    {
      title: "Tyre Waste",
      desc: "Can be recycled or repurposed into various products, including construction materials, fuel, and rubber products such as playground surfaces and asphalt",
      id: "tyre_waste",
      unit: "ton",
    },
    {
      title: "Saw Dust",
      desc: "Fine wood particles used as a fuel source, animal bedding, and raw material in particleboard, paper, and biofuels",
      id: "saw_dust",
      unit: "ton",
    },
    {
      title: "Wood Chips",
      desc: "Used as a fuel source, animal bedding, composting, landscaping, and raw material in paper and engineered wood products",
      id: "wood_chips",
      unit: "ton",
    },
    {
      title: "Rice Husk",
      desc: "The protective outer layer of rice grains repurposed for fuel, bedding, insulation, and building materials",
      id: "rice_husk",
      unit: "ton",
    },
    {
      title: "Coffee Husk",
      desc: "A byproduct of the coffee industry used as a raw material for various products",
      id: "coffee_husk",
      unit: "ton",
    },
    {
      title: "Ground Nut Cell",
      desc: "A renewable resource used as feedstock, food, filler in fertilizer, and in bio filter carriers",
      id: "ground_nut_cell",
      unit: "ton",
    },
    {
      title: "Soya Husk",
      desc: "Repurposed for animal feed, fertilizer, and raw material in paper and particleboard",
      id: "soya_husk",
      unit: "ton",
    },
    {
      title: "Carbon Black",
      desc: "A fine powder made from carbon widely used in industrial applications as a reinforcing agent in rubber products",
      id: "carbon_black",
      unit: "ton",
    },
    {
      title: "Bio-Mass Pallets",
      desc: "Biomass pellets used as a fuel source in heating systems such as pellet stoves, furnaces, and boilers",
      id: "bio_mass_pallets",
      unit: "ton",
    },
    {
      title: "Starch Based Raw Materials",
      desc: "Used in the production of biodegradable products such as packaging materials, disposable cutlery, and food containers",
      id: "starch_based_raw_materials",
      unit: "ton",
    },
    {
      title: "RDF (Refuse Derived Fuel)",
      desc: "A type of fuel produced by sorting and processing municipal solid waste (MSW) and other types of non-hazardous waste materials",
      id: "rdf_refuse_derived_fuel",
      unit: "ton",
    },
    {
      title: "Biomass Briquettes/Bio Coal",
      desc: "Compressed blocks of organic materials, such as sawdust, agricultural waste, and other biomass materials",
      id: "biomass_briquettes_bio_coal",
      unit: "ton",
    },
    {
      title: "Other Bio Mass",
      desc: "Organic materials derived from agricultural waste, forest residue, algae, and other forms of biomass",
      id: "other_bio_mass",
      unit: "ton",
    },
  ],
  liquid: [
    {
      title: "High Speed Diesel",
      desc: "A refined petroleum product with a higher cetane number and low sulfur content, designed for use in high-speed diesel engines",
      id: "high_speed_diesel",
      unit: "liter",
    },
    {
      title: "Bio Diesel (B-100)",
      desc: "A renewable, clean-burning diesel alternative made from natural, biodegradable sources",
      id: "bio_diesel_b_100",
      unit: "liter",
    },
    {
      title: "LDO",
      desc: "A low-grade fuel oil commonly used for boilers, furnaces, and other equipment in industrial and agricultural applications",
      id: "ldo",
      unit: "liter",
    },
    {
      title: "Bio-LDO",
      desc: "A renewable alternative to traditional LDO made from natural, biodegradable sources",
      id: "bio_ldo",
      unit: "liter",
    },
    {
      title: "Furnace Oil",
      desc: "A dark, viscous residual fuel oil commonly used for high-heat applications",
      id: "furnace_oil_",
      unit: "liter",
    },
    {
      title: "Base Oil",
      desc: "Refined petroleum product used as a base component for manufacturing lubricating oils and greases",
      id: "base_oil",
      unit: "liter",
    },
    {
      title: "Bitumen",
      desc: "A sticky, black, highly viscous liquid for road construction, roofing, and waterproofing",
      id: "bitumen",
      unit: "ton",
    },
    {
      title: "UCO",
      desc: "Used cooking oil (UCO) is a feedstock for biodiesel production",
      id: "uco",
      unit: "liter",
    },
    {
      title: "MTO",
      desc: "Methanol-to-Olefins used for plastics production",
      id: "mto",
      unit: "liter",
    },
    {
      title: "MTO Cut",
      desc: "A product obtained by the catalytic conversion of methanol for plastics production",
      id: "mto_cut",
      unit: "liter",
    },
    {
      title: "MHO",
      desc: "Vegetable oil extracted from the seeds of the Mahua tree",
      id: "mho",
      unit: "liter",
    },
    {
      title: "Bio-Ethanol",
      desc: "A renewable fuel made from plant materials, through a process of fermentation and distillation",
      id: "bio_ethanol",
      unit: "liter",
    },
    {
      title: "Bio-Furnace Oil",
      desc: "A type of fuel produced from renewable sources, a substitute for traditional furnace oil",
      id: "bio_furnace_oil",
      unit: "liter",
    },
    {
      title: "Bio-Fuel Additives",
      desc: "Substances added to biofuels to improve their performance, reduce emissions, and enhance storage stability",
      id: "bio_fuel_additives",
      unit: "liter",
    },
    {
      title: "Acid Oil",
      desc: "A byproduct of vegetable oil refining that is rich in free fatty acids and has various industrial applications",
      id: "acid_oil",
      unit: "liter",
    },
    {
      title: "Other Vegetable Oil",
      desc: "Oil extracted from plants or seeds used for cooking, baking, frying, and various industrial applications",
      id: "other_vegetable_oil",
      unit: "liter",
    },
    {
      title: "Bio-Lubricants",
      desc: "Environmentally friendly lubricants made from renewable resources",
      id: "bio_lubricants",
      unit: "liter",
    },
    {
      title: "Lubricants",
      desc: "Substances used to reduce friction between surfaces",
      id: "lubricants",
      unit: "liter",
    },
    {
      title: "New Bitumen",
      desc: "An alternative source of fatty acids used for the production of various products",
      id: "new_bitumen",
      unit: "ton",
    },
  ],
  gas: [
    {
      title: "Bio-CNG/CBG",
      desc: "A renewable, eco-friendly fuel produced by the anaerobic digestion of organic waste",
      id: "bio_cng_cbg",
      unit: "kg",
    },
    {
      title: "CNG",
      desc: "Compressed natural gas (CNG) is a cleaner alternative to gasoline and diesel",
      id: "cng",
      unit: "kg",
    },
    {
      title: "LNG",
      desc: "Liquefied natural gas (LNG) is natural gas that has been cooled to a liquid state for storage or transport",
      id: "lng",
      unit: "kg",
    },
    {
      title: "Green Hydrogen",
      desc: " a sustainable energy source for various applications, including transportation, heating, and electricity generation.",
      id: "green_hydrogen",
      unit: "kg",
    },
  ],
};
