import { useState } from "react";
import { ArrowRightAlt } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { postBooking } from "../../redux/api/bookings";
import AddressInput from "../../components/AddressInput";
import DatePicker from "../../components/UIElements/DatePicker";
import Button from "../../components/UIElements/Button";
import Input from "../../components/UIElements/Input";
import PayModal from "../../components/Modals/PayModal";
import { getFuelPrice } from "../../redux/api/price";
import { fetchDeliveryCharges } from "../../redux/api/configs";
import { calculateDeliveryCharges } from "../../utils/helper";

const initialForm = {
  destination: {},
  edd: new Date(),
  quantity: "",
};

export default function Hero() {
  const [form, setForm] = useState(initialForm);
  const [priceLoading, setPriceLoading] = useState(false);
  const [pay, setPay] = useState([]);
  const { user } = useSelector((s) => s.auth);
  const { loading } = useSelector((s) => s.bookings);
  const { current: currAddress } = useSelector((s) => s.location);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePayment = async () => {
    if (!form.destination?.coords?.latitude) return;
    try {
      setPriceLoading(true);
      const { latitude, longitude } = form.destination?.coords;
      const { diesel } = await getFuelPrice(latitude, longitude);
      const deliveryCharges = await fetchDeliveryCharges();
      if (!diesel || !deliveryCharges) return setPriceLoading(false);

      const dc = calculateDeliveryCharges(
        parseInt(form.quantity),
        deliveryCharges
      );
      const gst = (dc * 18) / 100;
      const list = [
        { title: "Quantity", amount: form.quantity },
        { title: "Unit Price", amount: diesel },
        {
          title: "Delivery Charges",
          amount: dc,
        },
        { title: "GST", amount: gst },
        {
          title: "Total",
          amount: Math.ceil(parseInt(form.quantity) * diesel + dc + gst),
        },
      ];

      setPriceLoading(false);
      setPay(list);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      handlePayment();
    } else {
      navigate("/signup");
    }
  };

  return (
    <div className="font-dmsans bg-[url('https://picsum.photos/1080/720')] bg-no-repeat bg-cover rounded-xl md:mx-16 mx-6 mt-8 p-12 flex flex-col items-center gap-8">
      <h1 className="text-white text-center font-[500] lg:text-[53px] md:text-[53px] text-[35px] md:leading-[58px] leading-[48px] tracking-[-0.03em]">
        Diesel (HSD)
        <br /> Get your Fuel Today
      </h1>
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl p-4 flex md:flex-row flex-col items-end gap-4 w-full"
      >
        <AddressInput
          label="Destination"
          name="destination"
          defaultValue={currAddress}
          onChange={(destination) => setForm((p) => ({ ...p, destination }))}
          className="w-full"
        />
        <DatePicker
          label="Expected Delivery Date"
          date={form.edd}
          setDate={(edd) => setForm((p) => ({ ...p, edd }))}
          className="w-full"
        />
        <Input
          value={form.quantity}
          onChange={(e) => {
            setForm((p) => ({
              ...p,
              quantity: e.target.value,
            }));
          }}
          required
          type="number"
          placeholder="Enter quanity"
          label="Quantity"
        />
        <Button type="submit" loading={loading}>
          {priceLoading ? "Loading..." : <ArrowRightAlt fontSize="large" />}
        </Button>
      </form>
      <PayModal
        isOpen={!!pay.length}
        description="payment for diesel"
        list={pay}
        onClose={() => setPay([])}
        onComplete={() => dispatch(postBooking(form))}
      />
    </div>
  );
}
