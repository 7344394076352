import { AddPhotoAlternate } from "@mui/icons-material";

export default function ImgInput({
  onChange = () => {},
  className,
  label,
  value = "",
  loading,
}) {
  function handleChange(e) {
    e.preventDefault();
    const files = e.target.files;
    onChange([...files]);
  }

  return (
    <div className={`${className}`}>
      <input
        style={{ display: "none" }}
        type="file"
        id="file"
        onChange={handleChange}
        name="file"
      />
      <div className="flex flex-col h-full">
        <label className="text-sm leading-[18px] font-[500] text-[#0D2620]">
          {label}
        </label>
        <div className="mt-2 flex gap-8 items-center border bg-white rounded-xl p-6">
          {value && (
            <img
              src={value}
              height={150}
              width={150}
              className="w-32 h-32 rounded-full"
              
              alt="pic"
            />
          )}
          <label htmlFor="file" className="cursor-pointer">
            {loading ? "Uploading..." : <AddPhotoAlternate color="gray" />}
            <div>
              <span className="text-primary-100">
                Click here to {value ? "change" : "upload"}
              </span>
              <p className="text-sm text-gray-400">
                PNG or JPG (max. 400x400px)
              </p>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
}
