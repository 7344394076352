import { ArrowBack, ArrowForward } from "@mui/icons-material";
import React from "react";

const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const jumpToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="mt-auto py-4 items-center rounded-md text-white bg-[#1D523B] flex flex-col border mx-auto">
      <ul className="text-sm font-bold flex mx-auto gap-8 items-center">
        <li>
          <button onClick={prevPage} disabled={currentPage === 1}>
            <ArrowBack />
          </button>
        </li>

        <li className="flex items-center justify-center text-center w-8 h-8 rounded-full bg-white text-black">
          <button>{currentPage}</button>
        </li>

        {currentPage < totalPages - 1 && (
          <li className="hover:*:text-cyan">
            <button onClick={() => jumpToPage(currentPage + 1)}>
              {currentPage + 1}
            </button>
          </li>
        )}

        {currentPage < totalPages - 2 && (
          <li className="hover:*:text-cyan">
            <button onClick={() => jumpToPage(currentPage + 2)}>...</button>
          </li>
        )}

        {currentPage < totalPages && (
          <li className="hover:*:text-cyan">
            <button onClick={() => jumpToPage(totalPages)}>{totalPages}</button>
          </li>
        )}

        <li>
          <button onClick={nextPage} disabled={currentPage === totalPages}>
            <ArrowForward />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
