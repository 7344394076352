import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { closeBankFormModal } from "../../../redux/store/modalsSlice";
import Input from "../../UIElements/Input";
import Button from "../../UIElements/Button";
import { updateUser } from "../../../redux/api/auth";
import { ACCOUNT_TYPES } from "../../../constants/user";
import Dropdown from "../../UIElements/Dropdown/Dropdown";

const INITIAL_FORM = {
  acNumber: "",
  ifsc: "",
  hName: "",
  type: "",
};

export default function BankFormModal() {
  const { user, loading } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState(INITIAL_FORM);
  const { isOpen } = useSelector((state) => state.modals.bankFormModal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.bankDetails?.acNumber) return;
    setFormData({ ...user.bankDetails });
  }, [user, isOpen]);

  async function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateUser({ bankDetails: formData }));
    setTimeout(() => {
      handleClose();
    }, 500);
  }

  function handleInputChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((p) => ({ ...p, [name]: value }));
  }

  function handleClose(e) {
    e && e.preventDefault();
    dispatch(closeBankFormModal());
  }

  return (
    <Modal
      open={isOpen}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClose={handleClose}
    >
      <section
        className="font-dmsans rounded-xl flex bg-white md:w-1/2 w-full m-10"
        style={{ maxHeight: "80vh" }}
      >
        <div className="w-full flex flex-col justify-between px-12 pb-16 pt-6">
          <form className="gap-4 flex flex-col" onSubmit={handleSubmit}>
            <h1 className="text-4xl font-bold mt-2">Bank Account</h1>
            <p className="font-medium">
              Add your bank account for payment processings.
            </p>
            <div className="mt-4 grid grid-cols-2 gap-4 items-center">
              <Input
                label="Account Number"
                type="text"
                name="acNumber"
                value={formData.acNumber}
                required
                onChange={handleInputChange}
                placeholder="Enter your account number"
              />
              <Input
                label="Account IFSC Code"
                required
                type="text"
                name="ifsc"
                value={formData.ifsc}
                onChange={handleInputChange}
                placeholder="Enter your account ifsc code"
              />
              <Input
                label="Account Holder Name"
                required
                type="text"
                name="hName"
                value={formData.hName}
                onChange={handleInputChange}
                placeholder="Enter holder name"
              />
              <Dropdown
                label="Account Type"
                units={ACCOUNT_TYPES}
                required
                unit={ACCOUNT_TYPES[formData.type]}
                setUnit={(value) => {
                  setFormData((p) => ({
                    ...p,
                    type: ACCOUNT_TYPES.indexOf(value),
                  }));
                }}
              />
              <div className="mt-4 col-span-2 flex items-center gap-2 justfiy-center">
                <Button onClick={handleClose} mode="text">
                  Close
                </Button>
                <Button type="submit" loading={loading}>
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Modal>
  );
}
