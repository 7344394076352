import { createSlice } from "@reduxjs/toolkit";
import {
  addOrUpdateLocation,
  fetchLocations,
  getCurrentLocation,
  deleteLocation,
} from "../api/location";

const LOCATION_SCHEMA = {
  addressLine: "",
  city: "",
  state: "",
  postalCode: "",
  coords: {
    latitude: "",
    longitude: "",
  },
};

const initialState = {
  loading: false,
  current: { ...LOCATION_SCHEMA },
  all: [],
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setCurrentLocation: (state, action) => {
      if (!action.payload || !action.payload.addressLine) return;
      state.current = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handling getCurrentLocation
    builder
      .addCase(getCurrentLocation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.current = action.payload;
      })
      .addCase(getCurrentLocation.rejected, (state) => {
        state.loading = false;
      });

    // Handling fetchLocations
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.all = action.payload;
      })
      .addCase(fetchLocations.rejected, (state) => {
        state.loading = false;
      });

    // Handling addOrUpdateLocation
    builder
      .addCase(addOrUpdateLocation.pending, (state) => {
        state.loading = true;
      })
      .addCase(addOrUpdateLocation.fulfilled, (state, action) => {
        state.loading = false;
        const address = action.payload;
        if (!address) return;
        const isAvailable = state.all.find((a) => a.id === address.id);
        if (isAvailable) {
          const rest = state.all.filter((a) => a.id !== address.id);
          state.all = [...rest, address];
        } else {
          state.all = [...state.all, address];
        }
      })
      .addCase(addOrUpdateLocation.rejected, (state) => {
        state.loading = false;
      });

    // Handling deleteLocation
    builder
      .addCase(deleteLocation.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        state.loading = false;
        const addressId = action.payload;
        state.all = state.all.filter((address) => address.id !== addressId);
      })
      .addCase(deleteLocation.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentLocation } = locationSlice.actions;
export default locationSlice.reducer;
