import React, { useEffect, useState, useMemo } from "react";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Dropdown from "../UIElements/Dropdown/Dropdown";
import { Add } from "@mui/icons-material";
import { openAddressFormModal } from "../../redux/store/modalsSlice";

export default function AddressInput({
  onChange = () => {},
  label,
  defaultValue,
  ...rest
}) {
  const { all: addresses, current: currentAddress } = useSelector(
    (s) => s.location
  );
  const { user } = useSelector((s) => s.auth);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const dispatch = useDispatch();

  const units = useMemo(() => {
    if (currentAddress.addressLine) {
      return [...addresses, currentAddress];
    }
    return addresses;
  }, [addresses, currentAddress]);

  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
      return setSelectedAddress(defaultValue);
    }
    if (!user?.dAddressId) return;
    const address = addresses.find((a) => a.id === user.dAddressId);
    if (address) {
      setSelectedAddress(address);
      onChange(address);
    }
  }, [user, addresses, defaultValue]);

  const handleAdd = () => {
    dispatch(openAddressFormModal());
  };

  return (
    <div {...rest}>
      <div className="font-dmsans flex gap-2 relative w-fit">
        <label className="text-sm leading-[18px] font-[500] mb-1">
          {label}
        </label>
        <IconButton
          sx={{ position: "absolute", right: -30, top: -5 }}
          size="small"
          onClick={handleAdd}
        >
          <Add fontSize="small" />
        </IconButton>
      </div>
      <Dropdown
        className="cursor-pointer"
        units={units}
        propertyShown={["name", "addressLine"]}
        unit={selectedAddress}
        setUnit={(value) => {
          setSelectedAddress(value);
          onChange(value);
        }}
      />
    </div>
  );
}
