import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { closeDispatchFormModal } from "../../../redux/store/modalsSlice";
import Input from "../../UIElements/Input";
import Button from "../../UIElements/Button";
import DatePicker from "../../UIElements/DatePicker";
import {
  createDispatch,
  uploadEwayBill,
  uploadInvoice,
} from "../../../redux/api/orders";

function FileInput({ label, btn, onChange }) {
  const [file, setFile] = useState({});

  const handleClick = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    onChange(e.target.files[0]);
  };

  return (
    <label className="font-dmsans flex flex-col gap-1">
      <span className="text-sm leading-[18px] font-[500]">{label}</span>
      <span className="h-full w-fit p-4 border rounded-xl cursor-pointer border-[#1D523B]">
        {file.name || btn}
      </span>
      <input
        required
        onChange={handleClick}
        className="hidden"
        type="file"
        accept=".pdf,.jpg,.png"
        multiple={false}
      />
    </label>
  );
}

const formInintialData = {
  ewayBN: "",
  quantity: "",
  ewayBURL: "",
  invoiceURL: "",
  erd: new Date(),
  note: "",
  status: 0,
};

export default function DispatchFormModal() {
  const { isOpen, orderId } = useSelector((s) => s.modals.dispatchFormModal);
  const { userOrders } = useSelector((s) => s.orders);
  const [formData, setFormData] = useState(formInintialData);
  const [loading, setLoading] = useState(false);
  const [ewayBFile, setEwayBFile] = useState(null);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const dispatch = useDispatch();

  const order = useMemo(() => {
    return userOrders.find((o) => o.id === orderId);
  }, [userOrders, orderId]);

  const isFesible = useMemo(() => {
    if (!order || !formData.quantity) return true;
    const pending = Number(order?.quantity) - Number(order?.dispatchedQty);
    return pending >= formData.quantity;
  }, [formData.quantity]);

  function handleClose() {
    setFormData({ ...formInintialData });
    dispatch(closeDispatchFormModal());
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!isFesible) return;
    try {
      setLoading(true);
      const id = uuidv4();
      const ewayBURL = await uploadEwayBill(ewayBFile, id);
      const invoiceURL = await uploadInvoice(invoiceFile, id);
      const dispatchData = { ...formData };
      dispatchData.ewayBURL = ewayBURL;
      dispatchData.invoiceURL = invoiceURL;
      await dispatch(createDispatch({ id, dispatchData })).unwrap();
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 1,
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 md:px-10 px-8 md:h-[85vh] h-[95vh] w-[95vw] md:w-[75vw] rounded-md"
      >
        <div className="flex justify-between">
          <h1 className="text-primary-100 md:text-[2.4rem] text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
            Create Dispatch
          </h1>
          <span className="flex flex-col md:flex-row justify-center items-center gap-4">
            <Button type="submit" loading={loading} disabled={loading}>
              Create
            </Button>
            <Button mode="text" onClick={handleClose} disabled={loading}>
              Close
            </Button>
          </span>
        </div>
        <p className="text-sm leading-[1.5rem] text-gray-400">
          Pending quantity: {Number(order?.quantity - order?.dispatchedQty)} Ltr
        </p>
        <div className="mt-4 grid grid-cols-2 gap-4">
          <Input
            value={formData.ewayBN}
            label="Eway Bill Number"
            type="number"
            placeholder="Enter Eway Bill Number"
            required
            onChange={(e) =>
              setFormData((p) => ({
                ...p,
                ewayBN: e.target.value,
              }))
            }
          />
          <Input
            label="Quantity"
            name="quantity"
            type="number"
            value={formData.quantity}
            placeholder="Enter dispatch quantity"
            required
            onChange={(e) =>
              setFormData((p) => ({
                ...p,
                quantity: e.target.value,
              }))
            }
            error={!isFesible ? "Quantity Exceeded." : ""}
          />
          <DatePicker
            label="Expected Reaching Date"
            date={formData.erd}
            setDate={(erd) => setFormData((p) => ({ ...p, erd }))}
          />
          <Input
            label="Additional Note"
            name="note"
            type="text"
            value={formData.note}
            placeholder="Enter a note"
            required
            onChange={(e) =>
              setFormData((p) => ({
                ...p,
                note: e.target.value,
              }))
            }
          />
          <FileInput
            label="Eway Bill"
            btn="Upload Eway Bill"
            onChange={setEwayBFile}
          />
          <FileInput
            label="Invoice"
            btn="Upload Invoice"
            onChange={setInvoiceFile}
          />
        </div>
      </form>
    </Modal>
  );
}
