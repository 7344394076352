import React from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import Item from "./Item";

export default function ItemList({
  items = [],
  title,
  subtitle,
  category,
  showPrice,
  className,
  onExplore,
}) {
  const isCategory = !!category;
  const sliderId = `slider-${Math.random().toString(36).substring(7)}`; // Generate unique id for each slider

  const slideRight = () => {
    let slider = document.getElementById(sliderId);
    slider.scrollLeft += 500;
  };
  const slideLeft = () => {
    let slider = document.getElementById(sliderId);
    slider.scrollLeft -= 500;
  };

  if (!items.length) {
    return null;
  }

  return (
    <section className={`mt-10 w-full text-black ${className}`}>
      <div className="flex md:flex-row flex-col justify-between items-center">
        <span>
          <h3 className="md:text-4xl text-2xl font-[500] leading-[2.6rem] tracking-[1.5px]">
            {title}
          </h3>
          {subtitle && (
            <p className="mt-1 font-[400] text-gray-700">{subtitle}</p>
          )}
        </span>
        <button
          onClick={onExplore}
          className="md:mt-0 mt-2 rounded-md px-4 py-3 border md:text-[1rem] text-sm leading-[1.5rem] hover:bg-gray-100"
        >
          Explore All
        </button>
      </div>
      <div className={`mt-2`}>
        <div className="relative">
          <div
            id={sliderId}
            className={`${
              isCategory ? "ml-4" : ""
            } flex flex-row w-full gap-4 overflow-x-auto overflow-y-hidden scroll-smooth md:mt-0 mt-10 py-6 relative`}
            style={{
              scrollbarWidth: "none",
              "-ms-overflow-style": "none",
            }}
          >
            {items.map((item, index) => (
              <Item
                item={item}
                key={index}
                isCategory={isCategory}
                showPrice={showPrice}
                index={index}
              />
            ))}
          </div>

          <button
            onClick={slideLeft}
            className="absolute left-[-15px] top-[150px] transform -translate-y-1/2 z-4 w-[50px] h-[50px] border-[1px] rounded-full flex items-center justify-center border-gray-400 bg-white hover:bg-[#f8f8f8]"
          >
            <MdOutlineArrowBackIos fontSize="large" />
          </button>
          <button
            onClick={slideRight}
            className="absolute right-[-15px] top-[150px] transform -translate-y-1/2 z-4 w-[50px] h-[50px] border-[1px] rounded-full flex items-center justify-center border-gray-400 bg-white hover:bg-[#f8f8f8]"
          >
            <MdOutlineArrowForwardIos fontSize="large" />
          </button>
        </div>
      </div>
    </section>
  );
}
