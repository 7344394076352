import React from "react";
import DirectionsCar from "@mui/icons-material/DirectionsCar";
import Build from "@mui/icons-material/Build";
import EvStation from "@mui/icons-material/EvStation";
import LocationOn from "@mui/icons-material/LocationOn";
import Anchor from "@mui/icons-material/Anchor";
import FlashOn from "@mui/icons-material/FlashOn";
import LocalFireDepartment from "@mui/icons-material/LocalFireDepartment";
import Factory from "@mui/icons-material/Factory";

export const DIESEL_SECTORS = [
  {
    title: "Transportation",
    desc: "Includes trucks, buses, and trains that use diesel engines for transporting goods and passengers.",
    icon: DirectionsCar,
  },
  {
    title: "Construction",
    desc: "Diesel-powered machinery such as excavators, bulldozers, and cranes are essential for construction projects.",
    icon: Build,
  },
  {
    title: "Agriculture",
    desc: "Diesel engines are used in tractors, harvesters, and other agricultural equipment.",
    icon: EvStation,
  },
  {
    title: "Mining",
    desc: "Mining operations use diesel-powered equipment for excavation, transportation, and processing of minerals.",
    icon: LocationOn,
  },
  {
    title: "Marine",
    desc: "Diesel engines power ships, boats, and other maritime vessels for transportation and shipping.",
    icon: Anchor,
  },
  {
    title: "Energy Generation",
    desc: "Diesel generators are used for backup power supply and in off-grid power generation.",
    icon: FlashOn,
  },
  {
    title: "Emergency Services",
    desc: "Diesel engines power emergency vehicles like fire trucks and ambulances.",
    icon: LocalFireDepartment,
  },
  {
    title: "Industrial",
    desc: "Diesel engines are used in various industrial applications, including pumps and compressors.",
    icon: Factory,
  },
];

const Service = () => {
  return (
    <section className="font-dmsans px-12 py-12">
      <div className="mx-auto mb-12 max-w-[510px] text-center">
        <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark sm:text-4xl md:text-[40px]">
          Our Sector
        </h2>
        <p className="text-base text-body-color ">
          There are multiple sectors where we serve our services.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-x-2 gap-y-6">
        {DIESEL_SECTORS.map(({ title, desc, icon }) => (
          <ServiceCard key={title} title={title} details={desc} Icon={icon} />
        ))}
      </div>
    </section>
  );
};

export default Service;

function ServiceCard({ details, title, Icon }) {
  return (
    <div
      className={`py-6 px-4 pl-6 bg-white relative mx-2 rounded-md border hover:border-gray-400`}
    >
      <div className="flex gap-2 items-center">
        {Icon && <Icon fontSize="medium" sx={{ color: "gray" }} />}
        <span>
          <h1 className="font-[500] text-[1.2rem] trailing-[0.5px]">{title}</h1>
        </span>
      </div>
      <p className="text-[#1a1a1a] text-[1rem] mt-2 font-[300]">{details}</p>
    </div>
  );
}
