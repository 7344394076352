import { Helmet } from "react-helmet";

import Navbar from "../../components/Navbar";
import Hero from "./Hero";
import Features from "./Features";
import Footer from "../../components/Footer";

const Meta = () => {
  return (
    <Helmet>
      <title>Partnership - FuelCab India</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com/partnership",
            name: "FuelCab",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

export default function Partnership() {
  return (
    <div className="font-dmsans text-white">
      <Meta />
      <Navbar />
      <Hero />
      <Features />
      <Footer />
    </div>
  );
}
