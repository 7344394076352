import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { createOrder } from "./orders";
import { getOfferById } from "../../utils/helper";
import { createPayment } from "./payments";
import { closeOfferFormModal, openSnackbar } from "../store/modalsSlice";
import { bookListing } from "./listings";

export const postOffer = createAsyncThunk(
  "postOffer",
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const user = getState().auth.user;
      if (!data || !user) {
        return rejectWithValue("Something went wrong!");
      }
      const offer = {
        ...data,
        status: 0,
        buyerId: user.id,
        buyerName: user.name,
        createdAt: Timestamp.fromDate(new Date()),
      };
      const docRef = await addDoc(collection(db, "offers"), offer);
      dispatch(closeOfferFormModal());
      return { ...offer, id: docRef.id };
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchListingOffers = createAsyncThunk(
  "fetchListingOffers",
  async (listingId, { getState, rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "offers"),
        where("listingId", "==", listingId)
      );
      const querySnapshot = await getDocs(q);
      const offers = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) offers.push({ ...doc.data(), id: doc.id });
      });
      return offers;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const acceptOffer = createAsyncThunk(
  "acceptOffer",
  async (offerId, { getState, rejectWithValue, dispatch }) => {
    try {
      const offer = getOfferById(offerId);
      const { user } = getState().auth;
      const location = getState().location;
      const { userListings } = getState().listings;
      const listing = userListings.find((l) => l.id === offer.listingId);

      const payment = await dispatch(
        createPayment({
          totalAmount: Number(offer.quantity * offer.unitPrice),
          sellerId: listing.sellerId,
          buyerId: offer.buyerId,
        })
      ).unwrap();

      await dispatch(
        createOrder({
          pId: listing.pId,
          quantity: offer.quantity,
          destination: offer.destination,
          origin: location.all[0] || location.current,
          buyerName: offer.buyerName,
          unitPrice: offer.unitPrice,
          tacType: offer.tacType,
          edd: offer.edd,
          paymentId: payment.id,
          sellerId: listing.sellerId,
          sellerName: user.name,
          buyerId: offer.buyerId,
        })
      ).unwrap();

      await dispatch(
        bookListing({
          listingId: listing.id,
          purchased: offer.quantity,
        })
      ).unwrap();

      const docRef = doc(db, "offers", offer.id);
      const data = {
        status: 1,
        updatedAt: Timestamp.fromDate(new Date()),
      };
      await updateDoc(docRef, data);
      dispatch(openSnackbar({ message: "Offer Accepted!", type: "success" }));
      return offerId;
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: "error" }));
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const rejectOffer = createAsyncThunk(
  "rejectOffer",
  async (offerId, { getState, rejectWithValue }) => {
    try {
      const docRef = doc(db, "offers", offerId);
      const data = {
        status: -1,
        updatedAt: Timestamp.fromDate(new Date()),
      };
      await updateDoc(docRef, data);
      return offerId;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchUserOffers = createAsyncThunk(
  "fetchUserOffers",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const user = getState().auth.user;
      const q = query(
        collection(db, "offers"),
        where("buyerId", "==", user.id)
      );
      const querySnapshot = await getDocs(q);
      const offers = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) offers.push({ ...doc.data(), id: doc.id });
      });
      return offers;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);
