import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import * as geofire from "geofire-common";

// fetch user bookings
export const fetchUserBookings = createAsyncThunk(
  "fetchUserBookings",
  async (p, { rejectWithValue, getState }) => {
    try {
      const user = getState()?.auth?.user;
      if (user) {
        const q = query(
          collection(db, "bookings"),
          where("buyerId", "==", user.id)
        );
        const querySnapshot = await getDocs(q);
        const bookings = [];
        querySnapshot.forEach((doc) => {
          if (doc.id) bookings.push({ ...doc.data(), id: doc.id });
        });
        return bookings;
      }
      return [];
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const deleteBooking = createAsyncThunk(
  "deleteBooking",
  async (bookingId, { rejectWithValue }) => {
    if (!bookingId) return rejectWithValue("Error deleting booking:");
    const docRef = doc(db, "bookings", bookingId);
    try {
      await deleteDoc(docRef);
      return bookingId;
    } catch (error) {
      console.log(error);
      rejectWithValue("Error deleting booking:");
    }
  }
);

export const postPendingBooking = createAsyncThunk(
  "postPendingBooking",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const data = getState()?.bookings.pendingBooking;
      if (!data || !userId) {
        return;
      }
      const booking = {
        ...data,
        buyerId: userId,
        status: 0,
        createdAt: Timestamp.fromDate(new Date()),
      };
      const docRef = await addDoc(collection(db, "bookings"), booking);
      return { ...booking, id: docRef.id };
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const postBooking = createAsyncThunk(
  "postBooking",
  async (data, { getState, rejectWithValue }) => {
    try {
      const userId = getState()?.auth?.user?.id;
      if (!data || !userId) {
        return rejectWithValue("Something went wrong!");
      }
      const booking = {
        ...data,
        buyerId: userId,
        status: 0,
        createdAt: Timestamp.fromDate(new Date()),
      };
      const docRef = await addDoc(collection(db, "bookings"), booking);
      return { ...booking, id: docRef.id };
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);
