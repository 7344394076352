import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, useMediaQuery } from "@mui/material";

import { CHARGE_TYPES, PAYMENT_TERMS } from "../../../constants/product";
import Button from "../../UIElements/Button";
import { closeListModal } from "../../../redux/store/modalsSlice";
import {
  acceptOffer,
  fetchListingOffers,
  rejectOffer,
} from "../../../redux/api/offers";
import { CheckCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  acceptQuote,
  fetchRFQQuotes,
  rejectQuote,
} from "../../../redux/api/quotes";
import { getProductByID } from "../../../utils/helper";

function Pair({ title, value, ext, className }) {
  return (
    <div className={`${className || ""} flex flex-col items-start`}>
      <h3 className="text-sm text-gray-400">{title}</h3>
      <p className="text-md w-fit">
        {value} {ext ? " " + ext : ""}
      </p>
    </div>
  );
}

function Card({
  id,
  createdAt,
  quantity,
  edd,
  buyerName,
  sellerName,
  tacType,
  unitPrice,
  destination,
  gstCharges,
  tCharges,
  qRemark,
  type,
  unit,
  status,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function handleAccept() {
    setLoading(true);
    if (type === "offers") {
      await dispatch(acceptOffer(id)).unwrap();
    } else {
      await dispatch(acceptQuote(id)).unwrap();
    }
    setLoading(false);
  }

  async function handleReject() {
    setLoading(true);
    if (type === "offers") {
      await dispatch(rejectOffer(id)).unwrap();
    } else {
      await dispatch(rejectQuote(id)).unwrap();
    }
    setLoading(false);
  }

  function handleOrder() {
    dispatch(closeListModal());
    navigate("/dashboard/orders");
  }

  return (
    <div className="bg-white rounded-xl p-4 border">
      <h3 className="col-span-2 flex items-center gap-1 text-xl font-bold text-black">
        {type === "offers" ? buyerName : sellerName}
        <CheckCircle sx={{ fontSize: 18, color: "darkgreen" }} />
      </h3>
      <span>{`${quantity} ${unit}`}</span>
      <div className="mt-2 grid grid-cols-2">
        {type === "offers" && (
          <>
            <Pair
              title="Expected Delivery Date:"
              value={edd.toDate().toLocaleDateString()}
            />
            <Pair
              title="Destination:"
              value={`${destination.city}, ${destination.state}`}
            />
          </>
        )}
        {type === "quotes" && (
          <>
            <Pair
              title="GST Charges"
              value={gstCharges.value}
              ext={`(${CHARGE_TYPES[gstCharges.type]})`}
            />
            <Pair
              title="Transportation Charges"
              value={tCharges.value}
              ext={`(${CHARGE_TYPES[tCharges.type]})`}
            />
            <Pair title="Quality Remark" value={qRemark} />
          </>
        )}
        <Pair
          title={`Created Date:`}
          value={createdAt.toDate().toLocaleDateString()}
        />
        <Pair title="Price:" value={`${unitPrice} / ${unit}`} />
        <Pair title="Total:" value={quantity * unitPrice} />
        <Pair
          className="col-span-2"
          title="Payment Terms:"
          value={PAYMENT_TERMS[tacType]}
        />
      </div>

      <div className="mt-2 border-t pt-2 col-span-3 flex gap-4 justify-center items-center">
        {status === 0 ? (
          <>
            <Button
              loading={loading}
              disabled={loading}
              className="w-1/2"
              onClick={handleAccept}
            >
              Accept
            </Button>
            <Button
              disabled={loading}
              className="w-1/2 bg-red-400"
              onClick={handleReject}
            >
              Reject
            </Button>
          </>
        ) : status === -1 ? (
          <p className="text-red-400">REJECTED</p>
        ) : (
          <Button onClick={handleOrder}>Go to order</Button>
        )}
      </div>
    </div>
  );
}

export default function ListModal() {
  const { isOpen, type, listingId, RFQId } = useSelector(
    (s) => s.modals.listModal
  );
  const { listingOffers } = useSelector((s) => s.offers);
  const { RFQQuotes } = useSelector((s) => s.quotes);
  const listing = useSelector((s) =>
    s.listings.userListings.find((l) => l.id === listingId)
  );
  const RFQ = useSelector((s) => s.RFQs.userRFQs.find((r) => r.id === RFQId));
  const [list, setList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (listingId && !listingOffers[listingId]) {
      dispatch(fetchListingOffers(listingId));
    }

    if (RFQId && !RFQQuotes[RFQId]) {
      dispatch(fetchRFQQuotes(RFQId));
    }
  }, [listingId, RFQId]);

  useEffect(() => {
    if (type === "offers") {
      if (listingOffers[listingId]) setList(listingOffers[listingId]);
    } else {
      if (RFQQuotes[RFQId]) setList(RFQQuotes[RFQId]);
    }
  }, [listingId, listingOffers, type, RFQId, RFQQuotes]);

  const unit = useMemo(() => {
    return type === "offers"
      ? getProductByID(listing?.pId)?.unit
      : getProductByID(RFQ?.pId)?.unit;
  }, [listing, RFQ, type]);

  function handleClose() {
    setList([]);
    dispatch(closeListModal());
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 1,
      }}
    >
      <div className="font-dmsans bg-white p-6 md:px-10 px-8 md:max-h-[85vh] max-h-[95vh] w-[95vw] md:w-[65vw] rounded-md  overflow-y-scroll">
        <div className="flex justify-between">
          <h1 className="text-primary-100 md:text-[2.4rem] text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
            All {type}
          </h1>
          <span className="flex flex-col md:flex-row justify-center items-center gap-4">
            <Button mode="text" onClick={handleClose}>
              Close
            </Button>
          </span>
        </div>
        <p className="text-sm leading-[1.5rem] text-gray-400">
          List of all the {type} you got.
        </p>
        <div className="mt-4 grid grid-cols-2 gap-4">
          {list.map((l) => {
            return <Card key={l.id} {...l} type={type} unit={unit} />;
          })}
          {!list.length && <p>No {type} found</p>}
        </div>
      </div>
    </Modal>
  );
}
