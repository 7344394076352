import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";

import Dropdown from "../../UIElements/Dropdown/Dropdown";
import { CHARGE_TYPES, PAYMENT_TERMS } from "../../../constants/product";
import { closeQuoteFormModal } from "../../../redux/store/modalsSlice";
import RadioButton from "../../UIElements/RadioButton/RadioButton";
import Button from "../../UIElements/Button";
import DatePicker from "../../UIElements/DatePicker";
import AddressInput from "../../AddressInput";
import Input from "../../UIElements/Input";
import { postQuote } from "../../../redux/api/quotes";

const formInintialData = {
  edd: new Date(),
  origin: {},
  rfqId: "",
  unitPrice: "",
  quantity: "",
  tacType: 0,
  qRemark: "",
  gstCharges: {
    type: 0,
    value: "",
  },
  tCharges: {
    type: 0,
    value: "",
  },
};

export default function QuoteFormModal() {
  const { isOpen, selectedRFQ } = useSelector((s) => s.modals.quoteFormModal);
  const { loading } = useSelector((s) => s.quotes);
  const [formData, setFormData] = useState(formInintialData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedRFQ) return;
    const { quantity, tacType, id } = selectedRFQ;
    setFormData((p) => ({
      ...p,
      rfqId: id,
      quantity: quantity?.value,
      tacType,
    }));
  }, [selectedRFQ, isOpen]);

  function handleClose() {
    setFormData({ ...formInintialData });
    dispatch(closeQuoteFormModal());
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      dispatch(postQuote(formData));
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 10,
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 md:px-10 px-8 md:max-h-[85vh] max-h-[95vh] w-[95vw] md:w-[65vw] rounded-md"
      >
        <div className="flex justify-between">
          <h1 className="text-primary-100 md:text-[2.4rem] text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
            Create Quote
          </h1>
          <span className="flex flex-col md:flex-row justify-center items-center gap-4">
            <Button type="submit" disabled={loading} loading={loading}>
              Create
            </Button>
            <Button mode="text" disabled={loading} onClick={handleClose}>
              Close
            </Button>
          </span>
        </div>
        <p className="text-sm leading-[1.5rem] text-gray-400">
          Create a quote for a RFQ.
        </p>
        <div className="mt-4 grid grid-cols-2 gap-4">
          <Input
            value={formData.unitPrice}
            onChange={(e) => {
              setFormData((p) => ({
                ...p,
                unitPrice: e.target.value,
              }));
            }}
            required
            type="number"
            placeholder="Enter Price"
            label="Unit Price"
          />
          <Input
            value={formData.quantity}
            onChange={(e) => {
              setFormData((p) => ({
                ...p,
                quantity: e.target.value,
              }));
            }}
            required
            type="number"
            placeholder="Enter Quantity"
            label="Quantity"
            error={
              selectedRFQ?.quantity?.value < formData.quantity
                ? "Quantity Exceeding."
                : ""
            }
          />
          <Input
            label="Quality Remark"
            placeholder="Enter Remark"
            value={formData.qRemark}
            name="qRemark"
            required
            type="text"
            onChange={(e) =>
              setFormData((p) => ({
                ...p,
                qRemark: e.target.value,
              }))
            }
          />
          <Dropdown
            label="Payment Terms"
            units={PAYMENT_TERMS}
            unit={PAYMENT_TERMS[formData.tacType]}
            setUnit={(value) => {
              setFormData((p) => ({
                ...p,
                tacType: PAYMENT_TERMS.indexOf(value),
              }));
            }}
          />
          <DatePicker
            label="Expected Delivery Date"
            date={formData.edd}
            setDate={(edd) => setFormData((p) => ({ ...p, edd }))}
          />
          <AddressInput
            label="Origin Address"
            name="origin"
            defaultValue={formData.origin}
            onChange={(origin) => setFormData((p) => ({ ...p, origin }))}
          />
          <Input
            label="Transportation Charges"
            placeholder="Enter Transport Charges"
            value={formData.tCharges.value}
            containerProps={{ className: "mt-0" }}
            required
            type="number"
            onChange={(e) =>
              setFormData((p) => ({
                ...p,
                tCharges: {
                  ...p.tCharges,
                  value: e.target.value,
                },
              }))
            }
            ext={
              <RadioButton
                size="small"
                values={CHARGE_TYPES}
                value={CHARGE_TYPES[formData.tCharges.type]}
                onChange={(e) => {
                  setFormData((p) => ({
                    ...p,
                    tCharges: {
                      ...p.tCharges,
                      type: CHARGE_TYPES.indexOf(e.target.value),
                    },
                  }));
                }}
              />
            }
          />
          <Input
            label="GST Charges"
            placeholder="Enter GST charges"
            value={formData.gstCharges.value}
            containerProps={{ className: "mt-0" }}
            required
            type="number"
            onChange={(e) =>
              setFormData((p) => ({
                ...p,
                gstCharges: {
                  ...p.gstCharges,
                  value: e.target.value,
                },
              }))
            }
            ext={
              <RadioButton
                size="small"
                values={CHARGE_TYPES}
                value={CHARGE_TYPES[formData.gstCharges.type]}
                onChange={(e) => {
                  setFormData((p) => ({
                    ...p,
                    gstCharges: {
                      ...p.gstCharges,
                      type: CHARGE_TYPES.indexOf(e.target.value),
                    },
                  }));
                }}
              />
            }
          />
        </div>
      </form>
    </Modal>
  );
}
