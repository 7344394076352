import { useEffect, useState } from "react";
import { getUser } from "../../redux/api/auth";
import { Business, FireTruck, LockClock, Money, Nature, People } from "@mui/icons-material";

import { LSOFS, NOES, ATURNOVERS } from "../../constants/user";

export default function Company({ ownerId }) {
  const [owner, setOwner] = useState(null);

  useEffect(() => {
    if (!ownerId) return;
    getUser(ownerId).then(setOwner);
  }, [ownerId]);

  if (!owner) return null;

  return (
    <div className="mt-8 p-8 border rounded-md">
      <h2 className="text-2xl">Company Details ({owner?.gst})</h2>
      <div class="border-t w-full my-4"></div>
      <div className="mt-2 w-full flex justify-between gap-12">
        <span className="text-md text-gray-400">{owner.description}</span>
      </div>
      <div className="grid grid-cols-3 gap-8 mt-8">
        <div className="flex items-center gap-4">
          <span className="bg-[#1D523B] p-4 rounded-[50%]">
            <FireTruck fontSize="medium" sx={{ color: "white" }} />
          </span>
          <span className="flex flex-col">
            <h4 className="font-medium text-2xl text-gray-900">
              FuelCab Member Since
            </h4>
            <p className="text-gray-600">
              {owner?.createdAt?.toDate()?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
              })}
            </p>
          </span>
        </div>
        <div className="flex items-center gap-4">
          <span className="bg-[#1D523B] p-4 rounded-[50%]">
            <LockClock fontSize="medium" sx={{ color: "white" }} />
          </span>
          <span className="flex flex-col">
            <h4 className="font-medium text-2xl text-gray-900">
              Establishement Year
            </h4>
            <p className="text-gray-600">{owner?.yoe}</p>
          </span>
        </div>
        <div className="flex items-center gap-4">
          <span className="bg-[#1D523B] p-4 rounded-[50%]">
            <Business fontSize="medium" sx={{ color: "white" }} />
          </span>
          <span className="flex flex-col">
            <h4 className="font-medium text-2xl text-gray-900">
              Legal Status of Firm
            </h4>
            <p className="text-gray-600">{LSOFS[owner?.lsof]}</p>
          </span>
        </div>
        <div className="flex items-center gap-4">
          <span className="bg-[#1D523B] p-4 rounded-[50%]">
            <People fontSize="medium" sx={{ color: "white" }} />
          </span>
          <span className="flex flex-col">
            <h4 className="font-medium text-2xl text-gray-900">
              Number of Employee
            </h4>
            <p className="text-gray-600">{NOES[owner?.noe]}</p>
          </span>
        </div>
        <div className="flex items-center gap-4">
          <span className="bg-[#1D523B] p-4 rounded-[50%]">
            <Money fontSize="medium" sx={{ color: "white" }} />
          </span>
          <span className="flex flex-col">
            <h4 className="font-medium text-2xl text-gray-900">
              Anual Turnover
            </h4>
            <p className="text-gray-600">{ATURNOVERS[owner?.aTurnover]}</p>
          </span>
        </div>
        <div className="flex items-center gap-4">
          <span className="bg-[#1D523B] p-4 rounded-[50%]">
            <Nature fontSize="medium" sx={{ color: "white" }} />
          </span>
          <span className="flex flex-col">
            <h4 className="font-medium text-2xl text-gray-900">
              Nature of Business
            </h4>
            <p className="text-gray-600">{owner?.nob}</p>
          </span>
        </div>
      </div>
    </div>
  );
}
