import React from "react";

export default function Hero() {
  return (
    <section className="relative bg-no-repeat md:mx-12 mx-6 mt-8 p-10 flex justify-start items-center md:h-[75vh] h-[50vh]">
      <img
        src="https://picsum.photos/1080/720"
        className="absolute object-cover left-0 rounded-xl w-full h-full"
        alt="about-us-banner"
      />
      <article
        className="z-[2] absolute top-1/2 transform -translate-y-1/2 w-[35%] left-5 font-inter font-semibold lg:text-[53px] md:text-[53px] text-[35px] md:leading-[58px] leading-[48px] tracking-[-0.03em]"
        aria-live="polite"
      >
        <h1>FuelCab, Driving towards a Sustainable Future</h1>
      </article>
    </section>
  );
}
