export const getFuelPrice = async (lat, long) => {
  if (!lat || !long) return;
  try {
    const res = await fetch(
      `https://civil-sprite-393009.df.r.appspot.com/apiv1/price?lat=${lat}&long=${long}`,
      {
        method: "POST",
        headers: {
          "FC-API-Key": "FCI1-API-MART",
        },
      }
    );
    return await res.json();
  } catch (error) {
    alert("Something went wrong, try after some time.")
  }
};
