import {
  doc,
  setDoc,
  getDoc,
  where,
  query,
  collection,
  addDoc,
  getDocs,
  Timestamp,
  serverTimestamp,
  updateDoc,
  or,
  and,
  deleteDoc,
  orderBy,
  startAt,
  endAt,
  limit,
  increment,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import * as geofire from "geofire-common";
import { app, db } from "../../firebase";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeListingFormModal, openSnackbar } from "../store/modalsSlice";

export async function getListingReviews(listingId) {
  try {
    const q = query(collection(db, "listings", listingId, "reviews"));
    const querySnapshot = await getDocs(q);
    const reviews = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists()) reviews.push({ id: doc.id, ...doc.data() });
    });
    return reviews;
  } catch (err) {
    console.log("review", err);
  }
}

export async function createListingReview(listingId, review, avgRating) {
  try {
    const data = {
      ...review,
      createdAt: Timestamp.fromDate(new Date()),
    };
    await addDoc(collection(db, "listings", listingId, "reviews"), data);
    await updateDoc(doc(db, "listings", listingId), {
      avgRating,
    });
    return data;
  } catch (err) {
    console.log("product", err);
  }
}

export const uploadListingImages = async (id, images, startIdx) => {
  const storage = getStorage(app);
  const imagesPromise = images.map(async (file, index) => {
    const storageRef = ref(storage, `listings/${id}/${startIdx + index}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  });
  const imagesURL = await Promise.all(imagesPromise);
  return imagesURL;
};

export const getListing = async (listingId) => {
  try {
    const docSnap = await getDoc(doc(db, "listings", listingId));
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id };
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchNearByListings = createAsyncThunk(
  "fetchNearByListings",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { coords } = getState().location.current;
      if (!coords?.latitude) return rejectWithValue("No coords");

      const center = [coords.latitude, coords.longitude];
      const radiusInM = 500000 * 1000;

      const bounds = geofire.geohashQueryBounds(center, radiusInM);
      const promises = [];
      for (const b of bounds) {
        const q = query(
          collection(db, "listings"),
          orderBy("geoHash"),
          startAt(b[0]),
          endAt(b[1])
        );
        promises.push(getDocs(q));
      }
      const snapshots = await Promise.all(promises);
      const matchingDocs = [];

      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.get("lat");
          const lng = doc.get("lng");
          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = geofire.distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            matchingDocs.push(doc);
          }
        }
      }
      return matchingDocs;
    } catch (error) {
      console.log(error);
    }
  }
);

// update user data
export const removeListingImage = createAsyncThunk(
  "removeListingImage",
  async ({ listingId, imageIdx }, { rejectWithValue }) => {
    const storage = getStorage(app);
    if (!listingId || !imageIdx) return rejectWithValue("Error deleting file:");
    const fileRef = ref(storage, `listings/${listingId}/${imageIdx}`);
    try {
      await deleteObject(fileRef);
      return { listingId, imageIdx };
    } catch (error) {
      rejectWithValue("Error deleting file:");
    }
  }
);

export const bookListing = createAsyncThunk(
  "bookListing",
  async ({ listingId, purchased }, { rejectWithValue, dispatch }) => {
    try {
      if (!listingId || !purchased)
        return rejectWithValue("Invalid listing ID");
      const listingRef = doc(db, "listings", listingId);
      await updateDoc(listingRef, {
        bookingsCount: increment(1),
        "quantity.purchased": purchased,
        updatedAt: Timestamp.fromDate(new Date()),
      });
      return { listingId, purchased };
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: "error" }));
      console.error("Error incrementing bookingsCount:", error);
      return rejectWithValue("Failed to increment bookingsCount");
    }
  }
);

// update user data
export const deleteListing = createAsyncThunk(
  "deleteListing",
  async (listingId, { rejectWithValue }) => {
    const storage = getStorage(app);
    if (!listingId) return rejectWithValue("Error deleting file:");
    const folderRef = ref(storage, `listings/${listingId}`);
    const docRef = doc(db, "listings", listingId);
    try {
      const folderContents = await listAll(folderRef);
      const deletePromises = folderContents.items.map((itemRef) =>
        deleteObject(itemRef)
      );
      await Promise.all(deletePromises);
      await deleteDoc(docRef);
      return listingId;
    } catch (error) {
      console.log(error);
      rejectWithValue("Error deleting file:");
    }
  }
);

export const postListing = createAsyncThunk(
  "postListing",
  async ({ id, data }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { latitude, longitude } = data.origin?.coords || {};
      const userId = getState().auth.user?.id;
      const today = new Date();
      const oneMonthLater = new Date(today);
      oneMonthLater.setDate(today.getDate() + 30);
      if (!data || !id || !latitude || !userId) {
        return rejectWithValue("Something went wrong!");
      }
      const listing = {
        ...data,
        avgRating: 0,
        bookingsCount: 0,
        expiry: Timestamp.fromDate(oneMonthLater),
        sellerId: userId,
        geoHash: geofire.geohashForLocation([
          Number(latitude),
          Number(longitude),
        ]),
        createdAt: Timestamp.fromDate(new Date()),
      };
      await setDoc(doc(db, "listings", id), listing);
      dispatch(openSnackbar({ message: "Listing Added!", type: "success" }));
      return { ...listing, id };
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

// update user data
export const updateListing = createAsyncThunk(
  "updateListing",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const docRef = doc(db, "listings", id);
      const listing = {
        ...data,
        updatedAt: Timestamp.fromDate(new Date()),
      };
      await updateDoc(docRef, listing);
      return { ...listing, id };
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const fetchUserListings = createAsyncThunk(
  "fetchUserListings",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const user = getState().auth.user;
      const q = query(
        collection(db, "listings"),
        where("sellerId", "==", user.id)
      );
      const querySnapshot = await getDocs(q);
      const listings = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) listings.push({ ...doc.data(), id: doc.id });
      });
      return listings;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchTrendingListings = createAsyncThunk(
  "fetchTrendingListings",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "listings"),
        orderBy("bookingsCount", "asc"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const listings = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) listings.push({ ...doc.data(), id: doc.id });
      });
      return listings;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchNewListings = createAsyncThunk(
  "fetchNewListings",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "listings"),
        orderBy("createdAt", "asc"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const listings = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) listings.push({ ...doc.data(), id: doc.id });
      });
      return listings;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchSolidListings = createAsyncThunk(
  "fetchSolidListings",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "listings"),
        where("categoryId", "==", "solid"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const listings = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) listings.push({ ...doc.data(), id: doc.id });
      });
      return listings;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchLiquidListings = createAsyncThunk(
  "fetchLiquidListings",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "listings"),
        where("categoryId", "==", "liquid"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const listings = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) listings.push({ ...doc.data(), id: doc.id });
      });
      return listings;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchGasListings = createAsyncThunk(
  "fetchGasListings",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "listings"),
        where("categoryId", "==", "gas"),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const listings = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) listings.push({ ...doc.data(), id: doc.id });
      });
      return listings;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchFCIListings = createAsyncThunk(
  "fetchFCIListings",
  async (arg, { rejectWithValue }) => {
    try {
      const adminQ = query(
        collection(db, "users"),
        where("isAdmin", "==", true)
      );
      const adminsSnap = await getDocs(adminQ);
      const admins = [];
      adminsSnap.forEach((doc) => {
        if (doc.id) admins.push(doc.id);
      });

      const q = query(
        collection(db, "listings"),
        where("sellerId", "in", admins),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const listings = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) listings.push({ ...doc.data(), id: doc.id });
      });
      return listings;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const fetchFeturedListings = createAsyncThunk(
  "fetchFeturedListings",
  async (arg, { rejectWithValue }) => {
    try {
      const q = query(
        collection(db, "listings"),
        where("isFeatured", "==", true),
        limit(6)
      );
      const querySnapshot = await getDocs(q);
      const listings = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) listings.push({ ...doc.data(), id: doc.id });
      });
      return listings;
    } catch (error) {
      console.log(error);
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const getRelatedListings = async (category) => {
  try {
    let products = [];
    const q = query(
      collection(db, "listings"),
      where("categoryId", "==", category),
      limit(10)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.id) products.push({ ...doc.data(), id: doc.id });
    });
    return products;
  } catch (err) {
    console.log(err);
  }
};

// get products using filters
export const getFilteredItems = async (type = "listings", filters) => {
  let sQueries = undefined;
  let cQueries = undefined;
  let scQueries = undefined;
  let rQueries = undefined;
  let allQueries = [];

  if (filters?.states.length > 0) {
    sQueries = or(
      ...filters?.states.map((c) => {
        return where(
          type === "listings" ? "origin.state" : "destination.state",
          "==",
          c
        );
      })
    );
    allQueries.push(sQueries);
  }

  if (filters?.ratings.length > 0) {
    rQueries = or(
      ...filters?.ratings.map((r) => {
        return where("avgRating", "==", r);
      })
    );
    allQueries.push(rQueries);
  }

  if (filters?.categories.length > 0) {
    cQueries = or(
      ...filters?.categories.map((c) => {
        return where("categoryId", "==", c);
      })
    );
    allQueries.push(cQueries);
  }

  if (filters?.productsId.length > 0) {
    scQueries = or(
      ...filters?.productsId.map((c) => {
        return where("pId", "==", c);
      })
    );
    allQueries.push(scQueries);
  }

  if (allQueries.length > 1) {
    allQueries = [and(...allQueries)];
  }

  const productQuery = query(collection(db, type), ...allQueries);

  try {
    const { docs } = await getDocs(productQuery);
    const products = docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return products;
  } catch (err) {
    console.log(err, "products");
  }
};

export const fetchAllListings = (dispatch) => {
  dispatch(fetchTrendingListings());
  dispatch(fetchNewListings());
  dispatch(fetchFCIListings());
  dispatch(fetchFeturedListings());
  dispatch(fetchGasListings());
  dispatch(fetchLiquidListings());
  dispatch(fetchSolidListings());
};
