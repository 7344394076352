import Button from "../../components/UIElements/Button";
import { useDispatch, useSelector } from "react-redux";
import { openAddressFormModal } from "../../redux/store/modalsSlice";
import { updateUser } from "../../redux/api/auth";
import { deleteLocation } from "../../redux/api/location";
import { useState } from "react";

function Card({ id, addressLine, city, state, postalCode, createdAt, name }) {
  const { user, loading } = useSelector((s) => s.auth);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    setDeleteLoading(true);
    await dispatch(deleteLocation(id)).unwrap();
    setDeleteLoading(false);
  };

  const handleEditClick = () => {
    dispatch(openAddressFormModal({ addressId: id }));
  };

  const handleTypeChange = (key) => {
    dispatch(updateUser({ [key]: id }));
  };

  return (
    <div class="rounded-lg border min-w-[250px] bg-white p-4 hover:text-gray-700 hover:shadow">
      <h2 class="truncate capitalize my-1 text-center text-xl font-bold leading-8 text-gray-900">
        {name}
      </h2>
      <ul class="mt-3 divide-y rounded bg-gray-100 py-2 px-3 text-gray-600">
        <li class="flex flex-col gap-2 py-3 text-sm">
          <span className="font-semibold">Address Line:</span>
          <span class="capitalize ml-auto">{addressLine}</span>
        </li>
        <li class="flex gap-2 items-center py-3 text-sm">
          <span className="font-semibold">City:</span>
          <span class="capitalize ml-auto">{city}</span>
        </li>
        <li class="flex gap-2 items-center py-3 text-sm">
          <span className="font-semibold">State:</span>
          <span class="capitalize ml-auto">{state}</span>
        </li>
        <li class="flex gap-2 items-center py-3 text-sm">
          <span className="font-semibold">Postal Code:</span>
          <span class="capitalize ml-auto">{postalCode}</span>
        </li>
        <li class="flex gap-2 items-center py-3 text-sm">
          <span className="font-semibold">Added On:</span>
          <span class="ml-auto">
            {createdAt?.toDate()?.toLocaleDateString()}
          </span>
        </li>
        <li class="flex gap-2 justify-between items-center py-3 text-sm">
          <span className="font-semibold">Default:</span>
          {user.dAddressId === id ? (
            <span class="capitalize ml-auto">Yes</span>
          ) : (
            <Button
              size="sm"
              mode="text"
              className="px-0 py-0"
              loading={loading}
              onClick={() => handleTypeChange("dAddressId")}
            >
              Make Default
            </Button>
          )}
        </li>
        <li class="flex gap-2 justify-between items-center py-3 text-sm">
          <span className="font-semibold">Permanent:</span>
          {user.pAddressId === id ? (
            <span class="capitalize ml-auto">Yes</span>
          ) : (
            <Button
              size="sm"
              mode="text"
              className="px-0 py-0"
              loading={loading}
              onClick={() => handleTypeChange("pAddressId")}
            >
              Make Permanent
            </Button>
          )}
        </li>
      </ul>
      <div className="mt-2 border-t pt-2 col-span-3 flex gap-4 justify-center items-center">
        <Button onClick={handleEditClick} className="w-1/2">
          Edit
        </Button>
        <Button
          loading={deleteLoading}
          disabled={deleteLoading}
          className="w-1/2 bg-red-400"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}

export default function Addresses() {
  const { all: locations } = useSelector((s) => s.location);
  const dispatch = useDispatch();

  const handleAddClick = () => {
    dispatch(openAddressFormModal());
  };

  return (
    <div className="bg-gray-50 px-8 h-full">
      <div className="flex justify-between py-2 items-center">
        <h2 className="py-4 text-xl text-gray-400">ALL</h2>
        <Button onClick={handleAddClick}>Add Address</Button>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {locations.map((l, idx) => {
          return <Card key={idx} {...l} />;
        })}
      </div>
    </div>
  );
}
