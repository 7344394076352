import React, { useState, useEffect, useMemo } from "react";
import { Divider, Pagination } from "@mui/material";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { getFilteredItems } from "../../redux/api/listings";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Hero from "./Hero";
import Item from "./Item";
import RightPart from "./RightPart";
import Filters from "./Filters";
import { getCategoryFromPID } from "../../utils/helper";
import { PRODUCTS } from "../../constants/product";
import { useSelector } from "react-redux";
import RadioButton from "../../components/UIElements/RadioButton/RadioButton";

const VIEW_TYPES = ["Listings", "RFQs"];

const Meta = () => {
  return (
    <Helmet>
      <title>Explore - FuelCab India</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com/explore",
            name: "FuelCab",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

const PRODUCTPERPAGE = 10;
const initialFilters = {
  states: [],
  ratings: [],
  categories: [],
  productsId: [],
};

export default function Explore() {
  const [filters, setFilters] = useState(initialFilters);
  const [items, setItems] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 10]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((s) => s.auth);
  const [type, setType] = useState(0); // 0 -> listings
  const routeData = useLocation().state;

  useEffect(() => {
    setLoading(true);
    getFilteredItems(type ? "rfqs" : "listings", filters).then((items) => {
      setItems(items);
      setLoading(false);
    });
  }, [filters, type]);

  useEffect(() => {
    if (!routeData) return;
    let { categories, productsId, states, type } = routeData;
    if (type) setType(type);
    if (!!productsId?.length) {
      categories = [
        ...(categories || []),
        ...productsId.map((pId) => getCategoryFromPID(pId)),
      ];
    }
    setFilters((p) => ({
      ...p,
      categories: categories || [],
      productsId: productsId || [],
      states: states || [],
    }));
  }, [routeData]);

  // pagination & price
  const currentItems = useMemo(() => {
    const indexofLastProduct = currentPage * PRODUCTPERPAGE;
    const indexofFirstProduct = indexofLastProduct - PRODUCTPERPAGE;
    if (type) {
      return [...items].slice(indexofFirstProduct, indexofLastProduct);
    }
    return [...items]
      .filter(
        (i) => i.unitPrice.value >= price[0] && i.unitPrice.value <= price[1]
      )
      .slice(indexofFirstProduct, indexofLastProduct);
  }, [currentPage, items, price, type]);

  // max value for filter
  const maxProductPrice = useMemo(() => {
    if (type) return Infinity;
    let maxPrice = -1;
    items.forEach((i) => {
      if (Number(i.unitPrice.value) > maxPrice)
        maxPrice = Number(i.unitPrice.value);
    });
    setPrice([0, maxPrice]);
    return maxPrice;
  }, [items, type]);

  const handleFilter = (name, value) => {
    let newValues = [];
    if (filters[name]?.includes(value)) {
      newValues = filters[name]?.filter((val) => val !== value);
    } else {
      newValues = [...filters[name], value];
    }
    if (name === "categories" && PRODUCTS[value]) {
      const categoryPIds = PRODUCTS[value].map((p) => p.id);
      const productsId = filters["productsId"]?.filter(
        (val) => !categoryPIds.includes(val)
      );
      setFilters((p) => ({ ...p, [name]: newValues, productsId }));
      return;
    }
    setFilters((p) => ({ ...p, [name]: newValues }));
  };

  return (
    <div className="font-dmsans">
      <Meta />
      <Navbar />
      <div className="mt-16 flex justify-between items-center md:px-14 px-6">
        <h2 className="text-4xl">
          {type ? "RFQs" : "Listings"} ({items.length} Products)
        </h2>
        {(!user || (user?.isBuyer && user?.isSeller)) && (
          <span className="flex items-center gap-2">
            <RadioButton
              size="small"
              values={VIEW_TYPES}
              value={VIEW_TYPES[type]}
              onChange={(e) => setType(VIEW_TYPES.indexOf(e.target.value))}
            />
          </span>
        )}
        {!!items.length && currentItems.length > PRODUCTPERPAGE && (
          <div>
            <Pagination
              color="standard"
              defaultPage={1}
              count={Math.ceil(items.length / PRODUCTPERPAGE)}
              size="large"
              page={currentPage}
              onChange={(value) => setcurrentPage(value)}
            />
          </div>
        )}
      </div>
      <div className="flex justify-between md:px-14 px-6 my-8">
        <Filters
          filters={filters}
          price={price}
          setPrice={setPrice}
          handleFilter={handleFilter}
          maxProductPrice={maxProductPrice}
          type={type}
        />
        <div className="border px-8 py-6 md:w-[50%] rounded-md h-fit">
          {currentItems.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <Item {...item} type={type} />
                {index !== items.length - 1 && <Divider sx={{ my: 4 }} />}
              </React.Fragment>
            );
          })}
          {loading && (
            <p className="text-gray-500 text-[1rem] text-center">Loading...</p>
          )}
          {!currentItems.length && !loading && (
            <p className="text-gray-500 text-[1rem] text-center">
              No Product Found
            </p>
          )}
        </div>
        <RightPart />
      </div>
      <Footer />
    </div>
  );
}
