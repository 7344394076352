import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import {
  closeListingFormModal,
  closeRFQFormModal,
} from "../../../redux/store/modalsSlice";
import Input from "../../UIElements/Input";
import Dropdown from "../../UIElements/Dropdown/Dropdown";
import { PAYMENT_TERMS, FREQUENCIES } from "../../../constants/product";
import RadioButton from "../../UIElements/RadioButton/RadioButton";
import AddressInput from "../../AddressInput";
import Button from "../../UIElements/Button";
import { getCategoryFromPID, getProductByID } from "../../../utils/helper";
import { postRFQ, updateRFQ } from "../../../redux/api/RFQs";

const formInintialData = {
  pId: "",
  categoryId: "",
  quantity: {
    forMonths: "",
    frequencyType: 0,
    value: "",
  },
  tacType: 0,
  qRemark: "",
  destination: {},
};

export default function RFQFormModal() {
  const { isOpen, pId, RFQId } = useSelector((s) => s.modals.RFQFormModal);
  const { loading, userRFQs } = useSelector((s) => s.RFQs);
  const [formData, setFormData] = useState(formInintialData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!RFQId) return;
    const rfq = userRFQs.find((rfq) => rfq.id === RFQId);
    setFormData(rfq);
  }, [RFQId]);

  const product = useMemo(() => {
    if (pId) {
      setFormData((p) => ({ ...p, pId, categoryId: getCategoryFromPID(pId) }));
    }
    return getProductByID(pId);
  }, [pId]);

  function handleClose() {
    setFormData({ ...formInintialData });
    dispatch(closeRFQFormModal());
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (RFQId) dispatch(updateRFQ({ id: RFQId, data: formData }));
      else dispatch(postRFQ(formData));
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 1,
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 md:px-10 px-8 md:h-[85vh] h-[95vh] w-[95vw] md:w-[75vw] rounded-md"
      >
        <div className="flex justify-between">
          <h1 className="text-primary-100 md:text-[2.4rem] text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
            {RFQId ? "Edit " : "Add "} RFQ
          </h1>
          <span className="flex flex-col md:flex-row justify-center items-center gap-4">
            <Button type="submit" loading={loading}>
              {`${RFQId ? "Edit " : "Add "} RFQ`}
            </Button>
            <Button mode="text" onClick={() => dispatch(closeRFQFormModal())}>
              Close
            </Button>
          </span>
        </div>
        <p className="text-sm leading-[1.5rem] text-gray-400">
          List your RFQ under 1 minute.
        </p>
        <div className="mt-4 grid grid-cols-2 gap-4">
          <Input value={product?.title} disabled label="Product" />
          <AddressInput
            label="Destination Address"
            name="destination"
            defaultValue={RFQId ? formData.destination : undefined}
            onChange={(destination) =>
              setFormData((p) => ({ ...p, destination }))
            }
          />
          {formData.quantity.frequencyType === 1 && (
            <Input
              value={formData.quantity.forMonths}
              onChange={(e) => {
                setFormData((p) => ({
                  ...p,
                  quantity: {
                    ...p.quantity,
                    forMonths: e.target.value,
                  },
                }));
              }}
              required
              type="number"
              placeholder="Enter Months"
              label="Enter Months"
            />
          )}
          <Dropdown
            label="Payment Terms"
            units={PAYMENT_TERMS}
            unit={PAYMENT_TERMS[formData.tacType]}
            setUnit={(value) => {
              setFormData((p) => ({
                ...p,
                tacType: PAYMENT_TERMS.indexOf(value),
              }));
            }}
          />
          <Input
            label="Quality Remark"
            name="qRemark"
            value={formData.qRemark}
            placeholder="Enter Quality Remark"
            required
            onChange={(e) =>
              setFormData((p) => ({
                ...p,
                qRemark: e.target.value,
              }))
            }
          />
          <Input
            containerProps={{ className: "col-span-2" }}
            className="!w-[49%]"
            label={
              <>
                Quantity Needed{" "}
                <span className="capitalize">({product.unit})</span>
              </>
            }
            value={formData.quantity.value}
            onChange={(e) => {
              setFormData((p) => ({
                ...p,
                quantity: {
                  ...p.quantity,
                  value: e.target.value,
                },
              }));
            }}
            required
            type="number"
            placeholder="Enter Quantity"
            ext={
              <RadioButton
                name="availableFrequencyType"
                size="small"
                values={FREQUENCIES}
                value={FREQUENCIES[formData.quantity.frequencyType]}
                onChange={(e) => {
                  setFormData((p) => ({
                    ...p,
                    quantity: {
                      ...p.quantity,
                      frequencyType: FREQUENCIES.indexOf(e.target.value),
                    },
                  }));
                }}
              />
            }
          />
        </div>
      </form>
    </Modal>
  );
}
