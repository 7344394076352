import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EvStationIcon from "@mui/icons-material/EvStation";
import AirIcon from "@mui/icons-material/Air";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import SouthAmericaIcon from "@mui/icons-material/SouthAmerica";

import { openProductSelectorModal } from "../../redux/store/modalsSlice";
import Card from "./Card";

export default function Category() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCardClick = (category) => {
    dispatch(
      openProductSelectorModal({
        singleSelect: true,
        type: category,
        onSelect: (pId) => {
          navigate(`/explore`, { state: { productsId: [pId] } });
        },
      })
    );
  };

  return (
    <section className="mt-10 grid md:grid-cols-2 md:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 grid-cols-1 grid-rows-4 md:gap-4 gap-6">
      <Card
        onClick={() => handleCardClick("solid")}
        heading="Solid Fuels"
        border
        subHeading="Used in residential heating and traditional cooking methods."
        Icon={SouthAmericaIcon}
      />
      <Card
        onClick={() => handleCardClick("liquid")}
        heading="Liquid Fuels"
        border
        subHeading="Derived from petroleum, utilized for transportation, heating, etc."
        Icon={OilBarrelIcon}
      />
      <Card
        onClick={() => handleCardClick("gas")}
        border
        heading="Gas Fuels"
        subHeading="It is widely utilized for heating, cooking, and powering various appliances."
        Icon={AirIcon}
      />
      <Card
        border
        heading="EV"
        subHeading="It is the electrical energy stored in a battery used to power an electric vehicle."
        Icon={EvStationIcon}
      />
    </section>
  );
}
