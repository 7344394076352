import { useEffect, useState } from "react";
import { ArrowDownward, ArrowUpward, Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { getProductByID } from "../../../utils/helper";
import Button from "../../../components/UIElements/Button";
import Pagination from "../../../components/UIElements/Pagination";
import { PAYMENT_TERMS } from "../../../constants/product";
import { deleteRFQ } from "../../../redux/api/RFQs";
import {
  openListModal,
  openProductSelectorModal,
  openRFQFormModal,
} from "../../../redux/store/modalsSlice";

function Pair({ title, value, ext }) {
  return (
    <div className="flex flex-col items-start gap-2">
      <h3 className="text-sm text-gray-400">{title}</h3>
      <p className="text-md">
        {value} {ext ? " " + ext : ""}
      </p>
    </div>
  );
}

function Card({
  id,
  pId,
  updatedAt,
  quotesCount,
  createdAt,
  quantity,
  destination,
  qRemark,
  tacType,
  expiry,
}) {
  const { loading } = useSelector((s) => s.RFQs);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const product = getProductByID(pId);

  const handleEdit = () => {
    dispatch(openRFQFormModal({ RFQId: id, pId }));
  };

  const handleDelete = () => {
    dispatch(deleteRFQ(id));
  };

  const handleView = () => {
    dispatch(openListModal({ RFQId: id, type: "quotes" }));
  };

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <button
        type="button"
        onClick={() => setShow(!show)}
        className={`bg-white flex items-center gap-8 w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 ${
          show ? "rounded-t-xl" : "rounded-xl"
        } hover:shadow gap-3`}
        data-accordion-target="#accordion-collapse-body-1"
        aria-expanded={show}
        aria-controls="accordion-collapse-body-1"
      >
        <div className="h-full w-full flex justify-between items-center">
          <Pair title="Product" value={product.title} />
          <Pair
            title="Quantity"
            value={`${quantity.value} ${getProductByID(pId).unit}`}
            ext={quantity.frequencyType ? `(${quantity.forMonths} Months)` : ""}
          />
          <Pair title="Quotes" value={quotesCount} />
          <Pair
            title="Expiry By"
            value={expiry.toDate().toLocaleDateString()}
          />
          <Pair
            title="Created At"
            value={createdAt.toDate().toLocaleDateString()}
          />
          <Pair
            title="Updated By"
            value={updatedAt?.toDate()?.toLocaleDateString() || "Not Updated"}
          />
        </div>
        {show ? (
          <ArrowDownward className="ml-auto" />
        ) : (
          <ArrowUpward className="ml-auto" />
        )}
      </button>
      {show && (
        <div
          id="accordion-collapse-body-1"
          aria-labelledby="accordion-collapse-heading-1"
          className="bg-white border border-t-0 rounded-b-xl p-5 grid grid-cols-3 gap-4"
        >
          <div className="col-span-3 flex gap-4 items-center">
            <Button onClick={handleView}>View Quotes</Button>
            <Button onClick={handleEdit}>Edit</Button>
            <Button
              loading={loading}
              onClick={handleDelete}
              className="bg-red-400"
            >
              Delete
            </Button>
          </div>
          <Pair title="Destination Address" value={destination.addressLine} />
          <Pair title="Quality Remark" value={qRemark} />
          <Pair title="Terms & Conditions" value={PAYMENT_TERMS[tacType]} />
        </div>
      )}
    </div>
  );
}

const PAGE_ITEM_COUNT = 5;
const FILTERS = ["all", "live", "expired"];

export default function Listings() {
  const { userRFQs } = useSelector((s) => s.RFQs);
  const [filter, setFilter] = useState(FILTERS[0]);
  const [filteredRFQs, setFilteredRFQs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const today = new Date().getTime();
    const rfqs = userRFQs.filter((rfq) => {
      const expiry = rfq.expiry?.toDate()?.getTime();
      if (filter === "live") {
        return expiry > today;
      } else if (filter === "expired") {
        return expiry < today;
      }
      return true;
    });
    setFilteredRFQs([...rfqs]);
  }, [filter, userRFQs]);

  const handleCreate = () => {
    dispatch(
      openProductSelectorModal({
        singleSelect: true,
        onSelect: (pId) => {
          dispatch(openRFQFormModal({ pId }));
        },
      })
    );
  };

  return (
    <div className="h-full bg-gray-50">
      <div className="flex flex-wrap items-center gap-4 bg-white py-2 px-3">
        {FILTERS.map((fName) => {
          return (
            <button
              key={fName}
              onClick={() => setFilter(fName)}
              className={`capitalize inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm transition-all duration-200 ease-in-out ${
                filter === fName
                  ? "text-[#1D523B] border-b-[#1D523B] font-semibold"
                  : "text-gray-600 hover:border-b-[#1D523B] hover:text-[#1D523B]"
              }`}
            >
              {fName}
            </button>
          );
        })}
        <Button onClick={handleCreate} size="md" className="ml-auto">
          Create RFQ
        </Button>
      </div>
      <div className="p-8 flex flex-col gap-4">
        {filteredRFQs.length ? (
          filteredRFQs
            .slice(
              (currentPage - 1) * PAGE_ITEM_COUNT,
              currentPage * PAGE_ITEM_COUNT
            )
            .map((rfq) => {
              return <Card key={rfq.id} {...rfq} />;
            })
        ) : (
          <h4 className="text-xl">No RFQ Found.</h4>
        )}
      </div>
      {!!filteredRFQs.length && filteredRFQs.length > PAGE_ITEM_COUNT && (
        <div className="p-8">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={filteredRFQs.length / PAGE_ITEM_COUNT}
          />
        </div>
      )}
    </div>
  );
}
