import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

const center = { lat: 20.5937, lng: 78.9629 };

const locations = [
  { lat: 30.0668, lng: 79.0193 },
  { lat: 15.9129, lng: 79.74 },
  { lat: 28.7041, lng: 77.1025 },
  { lat: 27.5706, lng: 80.0982 },
];

const containerStyle = {
  width: "100%",
  height: "500px",
  borderRadius: 10,
};

export default function Areas() {
  return (
    <section className="font-dmsans px-12 py-12">
      <div className="mx-auto max-w-[510px] text-center">
        <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark sm:text-4xl md:text-[40px]">
          Our Location
        </h2>
        <p className="text-base text-body-color">
          There are multiple areas where we serve our services.
        </p>
      </div>
      <div className="mt-12 w-full h-fit flex justify-center items-center">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={5}>
          {locations.map((location, index) => (
            <Marker position={location} key={index} />
          ))}
        </GoogleMap>
      </div>
    </section>
  );
}
