import { createSlice } from "@reduxjs/toolkit";
import {
  createDispatch,
  createOrder,
  fetchOrderDispatches,
  fetchUserOrders,
  completedOrder,
} from "../api/orders";

const ordersSlice = createSlice({
  name: "orders",
  initialState: { userOrders: [], loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        const order = action.payload;
        state.userOrders = [...state.userOrders, order];
        state.loading = false;
      })
      .addCase(createOrder.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(fetchUserOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserOrders.fulfilled, (state, action) => {
        state.userOrders = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserOrders.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(fetchOrderDispatches.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrderDispatches.fulfilled, (state, action) => {
        const { orderId, dispatches } = action.payload;
        const orderIndex = state.userOrders.findIndex((o) => o.id === orderId);
        if (orderIndex !== -1) {
          state.userOrders[orderIndex] = {
            ...state.userOrders[orderIndex],
            dispatches,
          };
        }
        state.loading = false;
      })
      .addCase(fetchOrderDispatches.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(createDispatch.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDispatch.fulfilled, (state, action) => {
        const { orderId, dispatch } = action.payload;
        const order = state.userOrders.find((o) => o.id === orderId);
        if (order) {
          if (order.dispatches) {
            order.dispatches = [...order.dispatches, dispatch];
          } else {
            order.dispatches = [dispatch];
          }
          order.dispatchedQty =
            Number(order.dispatchedQty) + Number(dispatch.quantity);
        }
        state.loading = false;
      })
      .addCase(createDispatch.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(completedOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(completedOrder.fulfilled, (state, action) => {
        const orderId = action.payload;
        const order = state.userOrders.find((o) => o.id === orderId);
        if (order) {
          order.status = 1;
        }
        state.loading = false;
      })
      .addCase(completedOrder.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = ordersSlice.actions;

export default ordersSlice.reducer;
