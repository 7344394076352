import React from "react";

const RightPart = () => {
  return (
    <div className="hidden md:flex flex-col gap-4">
      <div className="flex border justify-between w-[260px] text-[12px] p-4 rounded-lg">
        <div className="">
          <h3 className="text-[18px] font-semibold">FC Promise</h3>
          <ul className="list-disc pl-4 text-[12px] mt-2">
            <li className="mb-1">Verified Sellers</li>
            <li className="mb-1">Tested Products</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RightPart;
