import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  getListing,
  getListingReviews,
  getRelatedListings,
} from "../../redux/api/listings";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { getProductByID } from "../../utils/helper";
import Review from "./Review";
import Company from "./Company";
import Info from "./Info";
import ItemList from "../../components/ItemList";

const Meta = ({ title, endpoint }) => {
  return (
    <Helmet>
      <title>{title} - FuelCab India</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com/listing/${endpoint}",
            name: "FuelCab",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

export default function Listing() {
  const [reviews, setReviews] = useState([]);
  const [relatedListings, setRelatedListings] = useState([]);
  const [listing, setListing] = useState(null);
  const navigate = useNavigate();
  const { id: listingId } = useParams();

  useEffect(() => {
    if (!listingId) return navigate("/");
    getListing(listingId).then(setListing);
  }, [listingId]);

  useEffect(() => {
    if (!listing) return;
    getListingReviews(listing.id).then(setReviews);
  }, [listing]);

  useEffect(() => {
    if (!listing) return;
    getRelatedListings(listing.categoryId).then(setRelatedListings);
  }, [listing]);

  function handleAddReview(r, avgRating) {
    setReviews((p) => [...p, r]);
    setListing((p) => ({ ...p, avgRating }));
  }

  return (
    <>
      <Meta
        title={`${getProductByID(listing?.pId).title}`}
        endpoint={listingId}
      />
      <Navbar />
      <div className="font-dmsans md:p-12 p-6">
        <Info item={listing} reviews={reviews} />
        <Company ownerId={listing?.sellerId} />
        <Review
          item={listing}
          reviews={reviews}
          onAddReview={handleAddReview}
          ownerId={listing?.sellerId}
        />
        <ItemList title="Realted Listings" items={relatedListings} showPrice />
      </div>
      <Footer />
    </>
  );
}
