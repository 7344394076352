export const USER_TYPES = ["buyer", "seller", "admin"];

export const LSOFS = [
  "Sole Proprietorship",
  "Partnership",
  "Limited Liability Partnership",
  "Private Limited Company",
  "Public Limited Company",
  "Foriegn Corporation",
];

export const NOES = [
  "Upto 10 People",
  "Between 10 to 25 People",
  "Between 25 to 45 People",
  "60 People and above",
];

export const ATURNOVERS = [
  "Rs. 1 - 2 Crore",
  "Rs. 2 - 10 Crore",
  "Rs. 10 - 25 Crore",
  "Rs. 25 - 50 Crore",
  "Above Rs. 50 Crore",
];

export const ACCOUNT_TYPES = ["Saving Account", "Current Account"];
