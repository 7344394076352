import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";

import Input from "../../UIElements/Input";
import Dropdown from "../../UIElements/Dropdown/Dropdown";
import { PAYMENT_TERMS } from "../../../constants/product";
import AddressInput from "../../AddressInput";
import Button from "../../UIElements/Button";
import DatePicker from "../../UIElements/DatePicker";
import { closeOfferFormModal } from "../../../redux/store/modalsSlice";
import { postOffer } from "../../../redux/api/offers";

const formInintialData = {
  listingId: "",
  unitPrice: "",
  quantity: "",
  tacType: 0,
  edd: new Date(),
  destination: {},
};

export default function OfferFormModal() {
  const { isOpen, selectedListing } = useSelector(
    (s) => s.modals.offerFormModal
  );
  const { loading } = useSelector((s) => s.offers);
  const [formData, setFormData] = useState(formInintialData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedListing) return;
    const { unitPrice, quantity, tacType, id } = selectedListing;
    setFormData((p) => ({
      ...p,
      listingId: id,
      unitPrice: unitPrice?.value,
      quantity: quantity?.value,
      tacType,
    }));
  }, [selectedListing, isOpen]);

  function handleClose() {
    setFormData({ ...formInintialData });
    dispatch(closeOfferFormModal());
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      dispatch(postOffer(formData));
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 1,
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 md:px-10 px-8 md:max-h-[85vh] max-h-[95vh] w-[95vw] md:w-[55vw] rounded-md"
      >
        <div className="flex justify-between">
          <h1 className="text-primary-100 md:text-[2.4rem] text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
            Create Order
          </h1>
          <span className="flex flex-col md:flex-row justify-center items-center gap-4">
            <Button type="submit" disabled={loading} loading={loading}>
              Create
            </Button>
            <Button
              mode="text"
              disabled={loading}
              onClick={() => dispatch(closeOfferFormModal())}
            >
              Close
            </Button>
          </span>
        </div>
        <p className="text-sm leading-[1.5rem] text-gray-400">
          Create a order for a listing.
        </p>
        <div className="mt-4 grid grid-cols-2 gap-4">
          <Input
            value={formData.unitPrice}
            onChange={(e) => {
              setFormData((p) => ({
                ...p,
                unitPrice: e.target.value,
              }));
            }}
            required
            type="number"
            placeholder="Enter Price"
            label="Unit Price"
            disabled={!selectedListing?.quantity?.negotiable}
          />
          <Input
            value={formData.quantity}
            onChange={(e) => {
              setFormData((p) => ({
                ...p,
                quantity: e.target.value,
              }));
            }}
            required
            type="number"
            placeholder="Enter Quantity"
            label="Quantity"
            error={
              selectedListing?.quantity?.value < formData.quantity
                ? "Quantity Exceeding."
                : ""
            }
          />
          <DatePicker
            label="Expected Delivery Date"
            date={formData.edd}
            setDate={(edd) => setFormData((p) => ({ ...p, edd }))}
          />
          <AddressInput
            label="Destination Address"
            name="destination"
            defaultValue={formData.destination}
            onChange={(destination) =>
              setFormData((p) => ({ ...p, destination }))
            }
          />
          <Dropdown
            label="Payment Terms"
            units={PAYMENT_TERMS}
            unit={PAYMENT_TERMS[formData.tacType]}
            setUnit={(value) => {
              setFormData((p) => ({
                ...p,
                tacType: PAYMENT_TERMS.indexOf(value),
              }));
            }}
          />
        </div>
      </form>
    </Modal>
  );
}
