import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import {
  getDoc,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { app, db } from "../../firebase";
import { USER_TYPES } from "../../constants/user";
import { openSnackbar } from "../store/modalsSlice";
import { extractError } from "../../utils/helper";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { postPendingBooking } from "./bookings";

const auth = getAuth(app);

export const login = createAsyncThunk(
  "login",
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      const docSnap = await getDoc(doc(db, "users", user.uid));

      if (!docSnap.exists()) {
        return rejectWithValue("User not found!");
      }
      dispatch(
        openSnackbar({ message: "Successfully logged In", type: "success" })
      );
      dispatch(postPendingBooking(user.uid));
      return { ...docSnap.data(), id: user.uid };
    } catch (error) {
      console.log(error);
      return rejectWithValue(extractError(error.message));
    }
  }
);

export const fetchUserMetaDeta = createAsyncThunk(
  "fetchUserMetaDeta",
  async (user, { rejectWithValue }) => {
    try {
      if (!user) {
        return rejectWithValue("");
      }
      const docSnap = await getDoc(doc(db, "users", user.uid));
      if (!docSnap.exists()) {
        return rejectWithValue("User Not Found!");
      }
      return { ...docSnap.data(), id: user.uid };
    } catch (error) {
      return rejectWithValue("Something went wrong!");
    }
  }
);

export const logout = createAsyncThunk("logout", async () => {
  await signOut(auth);
});

export const register = createAsyncThunk(
  "register",
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      if (!userData?.phoneNumber) throw "No Phone Number";
      const { user } = await createUserWithEmailAndPassword(
        auth,
        `${userData.phoneNumber}@fuelcab.com`,
        userData.password
      );
      delete userData.password;
      delete userData.otp;
      const newUserData = {
        ...userData,
        walletAmount: 0,
        profileURL: "",
        membershipId: "FF",
        defaultBillingId: "",
        bProducts: [],
        sProducts: [],
        address: {
          addressLine: "",
          city: "",
          state: "",
          postalCode: "",
          coords: { latitude: "", longitude: "" },
        },
        createdAt: Timestamp.fromDate(new Date()),
      };
      await setDoc(doc(db, "users", user.uid), newUserData);
      dispatch(
        openSnackbar({ message: "Successfully  Registered", type: "success" })
      );
      dispatch(postPendingBooking(user.uid));
      return { id: user.uid, ...newUserData };
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: "error" }));
      return rejectWithValue(error.message);
    }
  }
);

export const refreshUser = createAsyncThunk(
  "refreshUser",
  async (userData, { rejectWithValue, getState }) => {
    try {
      const { id } = getState().auth?.user;
      const userRef = doc(db, "users", id);
      await updateDoc(userRef, {
        ...userData,
      });
      return userData;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

// update user data
export const updateUser = createAsyncThunk(
  "updateUser",
  async (userNewData, { rejectWithValue, getState }) => {
    try {
      const { id } = getState().auth?.user;
      const userRef = doc(db, "users", id);
      await updateDoc(userRef, {
        ...userNewData,
        updatedAt: Timestamp.fromDate(new Date()),
      });
      return userNewData;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

// update user profile
export const updateUserProfilePicture = createAsyncThunk(
  "updateUserProfilePicture",
  async (file, { rejectWithValue, getState }) => {
    try {
      if (!file) {
        rejectWithValue("No File Provided!");
        return;
      }
      const storage = getStorage(app);
      const { id } = getState().auth?.user;
      const storageRef = ref(storage, `users/${id}/profile`);
      const imgRef = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      const userRef = doc(db, "users", id);
      await updateDoc(userRef, {
        profileURL: downloadURL,
        updatedAt: Timestamp.fromDate(new Date()),
      });
      return downloadURL;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

// change password
export const changePassword = createAsyncThunk(
  "changePassword",
  async ({ oldPassword, newPassword }, { rejectWithValue, dispatch }) => {
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user,newPassword);
      dispatch(openSnackbar({ message: "Password Update!", type: "success" }));
      return;
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, type: "error" }));
      rejectWithValue(error.message);
    }
  }
);

export const sendOTP = async (phoneNumber) => {
  if (!phoneNumber) return;
  const res = await fetch(
    `https://civil-sprite-393009.df.r.appspot.com/apiv1/otp/generate`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "FC-API-Key": "FCI1-API-MART",
      },
      body: `phoneNumber=${phoneNumber}`,
    }
  );
  return res;
};

// verify otp
export const verifyOTP = async (phoneNumber, otp) => {
  if (!phoneNumber) return;
  const res = await fetch(
    `https://civil-sprite-393009.df.r.appspot.com/apiv1/otp/verify`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "FC-API-Key": "FCI1-API-MART",
      },
      body: `phoneNumber=${phoneNumber}&otp=${otp}`,
    }
  );
  return res;
};

// users ------------------------
export const getUser = async (userId) => {
  try {
    const docSnap = await getDoc(doc(db, "users", userId));
    if (!docSnap.exists()) {
      return;
    }
    return { ...docSnap.data(), id: docSnap.id };
  } catch (err) {
    console.log(err);
  }
};

export const setUserProfile = async (userId, userType) => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, {
    userType,
  });
};
