import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  or,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { closeTransactionFormModal, openSnackbar } from "../store/modalsSlice";

export const fetchUserPayments = createAsyncThunk(
  "fetchUserPayments",
  async (p, { rejectWithValue, getState }) => {
    try {
      const user = getState().auth.user;
      const q = query(
        collection(db, "payments"),
        or(where("buyerId", "==", user.id), where("sellerId", "==", user.id))
      );
      const querySnapshot = await getDocs(q);
      const payments = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) payments.push({ ...doc.data(), id: doc.id });
      });
      return payments;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const fetchTransactions = createAsyncThunk(
  "fetchTransactions",
  async (paymentId, { rejectWithValue }) => {
    try {
      const q = query(collection(db, "payments", paymentId, "transactions"));
      const querySnapshot = await getDocs(q);
      const transactions = [];
      querySnapshot.forEach((doc) => {
        if (doc.id) transactions.push({ ...doc.data(), id: doc.id });
      });
      return { paymentId, transactions };
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const createPayment = createAsyncThunk(
  "createPayment",
  async (paymentData, { rejectWithValue, getState }) => {
    try {
      const data = {
        ...paymentData,
        paidAmount: 0,
        createdAt: Timestamp.fromDate(new Date()),
      };
      const docRef = await addDoc(collection(db, "payments"), data);
      return { ...data, id: docRef.id };
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const createTransaction = createAsyncThunk(
  "createTransaction",
  async (amount, { rejectWithValue, getState, dispatch }) => {
    try {
      const apiPaymentId = "APIPAYMENTID";
      const modeType = 0;
      const { paymentId } = getState().modals.transactionFormModal;
      if (!amount || !paymentId || !apiPaymentId)
        return rejectWithValue("Error");
      const data = {
        amount,
        apiPaymentId,
        modeType,
        status: 0,
        createdAt: Timestamp.fromDate(new Date()),
      };
      const docRef = await addDoc(
        collection(db, "payments", paymentId, "transactions"),
        data
      );
      const paymentRef = doc(db, "payments", paymentId);
      await updateDoc(paymentRef, {
        paidAmount: increment(amount),
      });
      dispatch(closeTransactionFormModal());
      dispatch(openSnackbar({ message: "Transaction Done!", type: "success" }));
      return { paymentId, transaction: { ...data, id: docRef.id } };
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);
