import { doc, getDoc, getFirestore } from "firebase/firestore";

import { app } from "../../firebase";

const db = getFirestore(app);

export const fetchDeliveryCharges = async () => {
  try {
    const docSnap = await getDoc(doc(db, "configs", "deliveryCharges"));
    if (!docSnap.exists()) {
      return;
    }
    return Object.values(docSnap.data()).map((val) => val);
  } catch (error) {
    return console.log("Something went wrong!");
  }
};
