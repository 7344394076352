import React, { useState, useMemo, useEffect } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import {
  closeListingFormModal,
  openSnackbar,
} from "../../../redux/store/modalsSlice";
import Input from "../../UIElements/Input";
import Dropdown from "../../UIElements/Dropdown/Dropdown";
import {
  PAYMENT_TERMS,
  FREQUENCIES,
  CHARGE_TYPES,
} from "../../../constants/product";
import RadioButton from "../../UIElements/RadioButton/RadioButton";
import AddressInput from "../../AddressInput";
import Button from "../../UIElements/Button";
import CheckBox from "../../UIElements/CheckBox";
import { getCategoryFromPID, getProductByID } from "../../../utils/helper";
import {
  postListing,
  updateListing,
  uploadListingImages,
} from "../../../redux/api/listings";
import { Delete } from "@mui/icons-material";

const formInintialData = {
  pId: "",
  imagesURL: [],
  gstCharges: {
    type: 0,
    value: "",
  },
  unitPrice: {
    negotiable: false,
    value: "",
  },
  tCharges: {
    type: 0,
    value: "",
  },
  categoryId: "",
  quantity: {
    forMonths: "",
    frequencyType: 0,
    value: "",
  },
  tacType: 0,
  qRemark: "",
  origin: {},
};

export default function ListingFormModal() {
  const { isOpen, pId, listingId } = useSelector(
    (s) => s.modals.listingFormModal
  );
  const [loading, setLoading] = useState(false);
  const { userListings } = useSelector((s) => s.listings);
  const [formData, setFormData] = useState(formInintialData);
  const [listingImages, setListingImages] = useState([]);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!listingId) return;
    const listing = userListings.find((l) => l.id === listingId);
    setFormData(listing);
  }, [listingId]);

  const product = useMemo(() => {
    if (pId) {
      setFormData((p) => ({ ...p, pId, categoryId: getCategoryFromPID(pId) }));
    }
    return getProductByID(pId);
  }, [pId]);

  function handleClose() {
    setFormData({ ...formInintialData });
    setListingImages([]);
    setStep(0);
    dispatch(closeListingFormModal());
  }

  const removeImage = (indexToRemove) => {
    setListingImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (step < 1) {
      setStep(step + 1);
      return;
    }
    if (!listingImages.length)
      return dispatch(
        openSnackbar({ message: "At least one Image needed!", type: "error" })
      );
    try {
      const id = uuidv4();
      let data = {
        ...formData,
      };
      setLoading(true);
      if (listingId) {
        await dispatch(updateListing({ id: listingId, data })).unwrap();
      } else {
        data.imagesURL = await uploadListingImages(id, listingImages, 0);
        await dispatch(postListing({ id, data })).unwrap();
      }
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  function uploadImage(e) {
    const file = e.target.files;
    setListingImages((p) => [...p, ...file]);
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        zIndex: 1,
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 md:px-10 px-8 md:h-[85vh] h-[95vh] w-[95vw] md:w-[75vw] rounded-md"
      >
        <div className="flex justify-between">
          <h1 className="text-primary-100 md:text-[2.4rem] text-[2rem] leading-[2.8rem] trailing-[1.5px] font-semibold">
            {listingId ? "Edit " : "Add "} Listing
          </h1>
          <span className="flex flex-col md:flex-row justify-center items-center gap-4">
            <Button type="submit" disabled={loading} loading={loading}>
              {step === 1 ? `${listingId ? "Edit " : "Add "} Listing` : "Next"}
            </Button>
            {step === 1 && (
              <Button mode="text" onClick={() => setStep(step - 1)}>
                Back
              </Button>
            )}
            {step === 0 && (
              <Button mode="text" onClick={handleClose}>
                Close
              </Button>
            )}
          </span>
        </div>
        <p className="text-sm leading-[1.5rem] text-gray-400">
          List your Listing under 1 minute.
        </p>
        <p className="mt-2 text-[0.9rem] leading-[1.5rem] text-gray-600">
          {`STEP - ${step + 1}/2`}
        </p>
        <div className="mt-4 grid grid-cols-2 gap-4">
          {step === 0 ? (
            <>
              <Input value={product?.title} disabled label="Product" />
              <AddressInput
                label="Pickup Address"
                name="origin"
                defaultValue={listingId ? formData.origin : undefined}
                onChange={(origin) => setFormData((p) => ({ ...p, origin }))}
              />
              <Input
                label="Available Quantity"
                value={formData.quantity.value}
                onChange={(e) => {
                  setFormData((p) => ({
                    ...p,
                    quantity: {
                      ...p.quantity,
                      value: e.target.value,
                    },
                  }));
                }}
                required
                type="number"
                placeholder="Enter Quantity"
                ext={
                  <RadioButton
                    name="availableFrequencyType"
                    size="small"
                    values={FREQUENCIES}
                    value={FREQUENCIES[formData.quantity.frequencyType]}
                    onChange={(e) => {
                      setFormData((p) => ({
                        ...p,
                        quantity: {
                          ...p.quantity,
                          frequencyType: FREQUENCIES.indexOf(e.target.value),
                        },
                      }));
                    }}
                  />
                }
              />
              <Input
                label="Unit Price"
                placeholder="Enter Unit Price"
                value={formData.unitPrice.value}
                name="unitPrice"
                containerProps={{ className: "mt-0" }}
                required
                type="number"
                onChange={(e) =>
                  setFormData((p) => ({
                    ...p,
                    unitPrice: {
                      ...p.unitPrice,
                      value: e.target.value,
                    },
                  }))
                }
                ext={
                  <CheckBox
                    checked={formData.unitPrice.negotiable}
                    label="Price is Negotiable"
                    size="small"
                    onChange={() =>
                      setFormData((p) => ({
                        ...p,
                        unitPrice: {
                          ...p.unitPrice,
                          negotiable: !formData.unitPrice.negotiable,
                        },
                      }))
                    }
                  />
                }
              />
              <Input
                label="Transportation Charges"
                placeholder="Enter Transport Charges"
                value={formData.tCharges.value}
                containerProps={{ className: "mt-0" }}
                required
                disabled={formData.tCharges.type === 2}
                type="number"
                onChange={(e) =>
                  setFormData((p) => ({
                    ...p,
                    tCharges: {
                      ...p.tCharges,
                      value: e.target.value,
                    },
                  }))
                }
                ext={
                  <RadioButton
                    size="small"
                    values={CHARGE_TYPES}
                    value={CHARGE_TYPES[formData.tCharges.type]}
                    onChange={(e) => {
                      setFormData((p) => ({
                        ...p,
                        tCharges: {
                          ...p.tCharges,
                          type: CHARGE_TYPES.indexOf(e.target.value),
                        },
                      }));
                    }}
                  />
                }
              />
              <Input
                label="GST Charges"
                placeholder="Enter GST charges"
                value={formData.gstCharges.value}
                containerProps={{ className: "mt-0" }}
                required
                disabled={formData.gstCharges.type === 2}
                type="number"
                onChange={(e) =>
                  setFormData((p) => ({
                    ...p,
                    gstCharges: {
                      ...p.gstCharges,
                      value: e.target.value,
                    },
                  }))
                }
                ext={
                  <RadioButton
                    size="small"
                    values={CHARGE_TYPES}
                    value={CHARGE_TYPES[formData.gstCharges.type]}
                    onChange={(e) => {
                      setFormData((p) => ({
                        ...p,
                        gstCharges: {
                          ...p.gstCharges,
                          type: CHARGE_TYPES.indexOf(e.target.value),
                        },
                      }));
                    }}
                  />
                }
              />
            </>
          ) : (
            <>
              {formData.quantity.frequencyType === 1 && (
                <Input
                  value={formData.quantity.forMonths}
                  onChange={(e) => {
                    setFormData((p) => ({
                      ...p,
                      quantity: {
                        ...p.quantity,
                        forMonths: e.target.value,
                      },
                    }));
                  }}
                  required
                  type="number"
                  placeholder="Enter Months"
                  label="Enter Months"
                />
              )}
              <Dropdown
                label="Payment Terms"
                units={PAYMENT_TERMS}
                unit={PAYMENT_TERMS[formData.paymentTermType]}
                setUnit={(value) => {
                  setFormData((p) => ({
                    ...p,
                    paymentTermType: PAYMENT_TERMS.indexOf(value),
                  }));
                }}
              />
              <Input
                label="Quality Remark"
                name="qRemark"
                value={formData.qRemark}
                placeholder="Enter Quality Remark"
                required
                onChange={(e) =>
                  setFormData((p) => ({
                    ...p,
                    qRemark: e.target.value,
                  }))
                }
              />
              {!listingId && (
                <div>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="file"
                    onChange={uploadImage}
                    name="file"
                    accept="image/*"
                    multiple
                  />
                  <label className="text-sm font-semibold">Images</label>
                  <div className="border p-4 rounded-md flex flex-wrap mt-1 gap-4 items-center">
                    {listingImages.map((img, index) => {
                      return (
                        <div key={index} className="relative">
                          <img
                            src={URL.createObjectURL(img)}
                            height={100}
                            width={100}
                            alt="pic"
                          />
                          <Delete
                            onClick={() => removeImage(index)}
                            className="absolute top-1 right-1 cursor-pointer text-red-500"
                          />
                        </div>
                      );
                    })}

                    {listingImages.length < 4 && (
                      <label
                        className="cursor-pointer bg-gray-50 h-[50px] w-[50px] flex justify-center items-center rounded-full"
                        htmlFor="file"
                      >
                        <AddPhotoAlternateIcon />
                      </label>
                    )}
                    {!listingImages[0] && (
                      <div className="cursor-pointer">
                        <label htmlFor="file" className="text-primary-100">
                          Click here to upload
                        </label>
                        <p className="text-gray-400 text-sm">
                          SVG, PNG or JPG (max. 400x400px)
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </form>
    </Modal>
  );
}
