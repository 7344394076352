import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { closeAllModals } from "../../redux/store/modalsSlice";

const ModalClose = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(closeAllModals());
  }, [location, dispatch]);

  return null;
};

export default ModalClose;
